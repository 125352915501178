// Router
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import PrivateRoute from '../src/components/containers/PrivateRoute';
import ROUTES from "./constants/ROUTES";

// Contexts & Hooks
import { useAppProvider } from '../src/context/AppContext';

// Pages
import BasePage from '../src/pages/BasePage';
import Login from '../src/pages/Login';
import Home from '../src/pages/Home';
import HospitalsList from '../src/pages/HospitalsList';
import HospitalDetail from '../src/pages/HospitalDetail';
import PatientsList from './pages/PatientsList';
import PatientsDetail from '../src/pages/PatientsDetail';
import PatientPage from '../src/pages/PatientPage';
import BookList from './pages/BookList';
import BookDetail from './pages/BookDetail';
import Treatments from './pages/Treatments';
import Proposals from './pages/Proposals';
import ProductionsList from '../src/pages/ProductionsList';
import ProductionCreate from '../src/pages/ProductionCreate';

// Components
import ModalWrapper from './components/UI/ModalWrapper';

function App() {
  const { loggedIn } = useAppProvider();

  return (
    <div className="AppContainer d-flex">
      <Router>
        <Switch>
          <Route path={ROUTES.ROOT.LOGIN}><Login /></Route>
          <BasePage>
            <PrivateRoute exact path={ROUTES.ROOT.INDEX} component={Home} />
            <PrivateRoute exact path={ROUTES.HOSPITALS.INDEX} component={HospitalsList} />
            <PrivateRoute path={ROUTES.HOSPITALS.HOSPITAL} component={HospitalDetail} />
            <PrivateRoute exact path={ROUTES.PATIENTS.INDEX} component={PatientsList} />
            <PrivateRoute path={ROUTES.PATIENTS.PATIENT_PAGE} component={PatientPage} />
            <PrivateRoute exact path={ROUTES.PATIENTS.PATIENT} component={PatientsDetail} />
            <PrivateRoute exact path={ROUTES.THERAPEUTIC_BOOK.INDEX} component={BookList} />
            <PrivateRoute path={ROUTES.THERAPEUTIC_BOOK.MEDICINE} component={BookDetail} />
            <PrivateRoute path={ROUTES.PLANNING.INDEX} component={Treatments} />
            <PrivateRoute path={ROUTES.PROPOSALS.INDEX} component={Proposals} />
            <PrivateRoute exact path={ROUTES.PRODUCTIONS.INDEX} component={ProductionsList} />
            <PrivateRoute path={ROUTES.PRODUCTIONS.NEW} component={ProductionCreate} />
          </BasePage>
        <ModalWrapper />
        </Switch>
      </Router>
    </div>
  );
}

export default App;