import DATE_LOCALE from '../constants/DATE_LOCALE';

export const notEmptyObject = obj => (
    !Object.keys(obj).length === 0
);

/**
 * Says if a Number is odd or even (true/false)
 * @param {Number} num
 * @returns {Boolean}
 */
export function isOdd(num) {
    return num % 2
};

export function shouldHidePosologies(arr) {
    return arr.length === 0 || (arr.length === 1 && arr[0].patterns.length === 0 && arr[0].produced === false);
}

/**
 * Returns default spanish id in case the browser's language is not handled by the app.
 * @param {String} lang
 * @returns String - example: 'es'
 */
export function resetLanguageToDefault(lang) {
    let _lang = '';
    const availableLanguages = Object.values(DATE_LOCALE);
    _lang = availableLanguages.includes(lang) ? lang : 'es';
    return _lang;
}

export function orderMedicinesArray(arr) {
    let result = arr;
    result = result.sort(function(a, b){
        if(a.name < b.name) { return -1; }
        if(a.name > b.name) { return 1; }
        return 0;
    })
    return result;
}

export function orderProsposalsArray(arr) {
    let proposalsArray = [];
    let medicinesArray = [];
    arr.forEach(med => {
        if(med.is_proposal === true) {
            proposalsArray.push(med);
        }  else {
            medicinesArray.push(med);
        }
    });
    proposalsArray = orderMedicinesArray(proposalsArray);
    medicinesArray = orderMedicinesArray(medicinesArray);
    return [proposalsArray,medicinesArray];
}

export function cleanCharacters(str) {
    let result;
    result = str.replace(/[^\w\s]/gi, '');
    return result;
}

export function modalPosologyIsReadyToPost(patterns, posologyTypeSelected) {
    let disabledValue = false;

    if(patterns !== undefined && patterns !== null ) {

        if (posologyTypeSelected === 'weekly' || posologyTypeSelected === 'weekly_6_intakes') {
            const values = patterns ? Object.values(patterns) : null;
            if(values) {
                Object.values(values).forEach(
                    value => Object.values(value).forEach(
                        value => { if(Number(value) > 0) {
                            disabledValue = true;
                        }}
                    )
                );
            }
        } else if (posologyTypeSelected === 'weekly_free') {
            Object.values(patterns).forEach(
                pattern => {
                    let currentValue = false;
                    pattern["posology"] && Object.values(pattern["posology"]).forEach(value => {
                        if(value > 0) {
                            currentValue = true;
                        }
                    })
                    disabledValue = currentValue;
                }
            );
        } else if (posologyTypeSelected === 'interval') {
            patterns.forEach(
                pattern => {
                    if (
                        Number(pattern["quantity"]) > 0 &&
                        pattern["interval_active_period"] > 0 &&
                        pattern["interval_inactive_period"] > 0
                    ) {
                        disabledValue = true;
                    } else {
                        disabledValue = false;
                    }
                }
            );
        } else if (posologyTypeSelected === 'monthly') {
            patterns.forEach(
                pattern => {
                    if (
                        Number(pattern["quantity"]) > 0 &&
                        pattern["interval_active_period"] > 0 &&
                        // pattern["interval_inactive_period"] !== null &&
                        pattern["month_end_day"] > 0 &&
                        pattern["month_start_day"] > 0
                    ) {
                        disabledValue = true;
                    } else {
                        disabledValue = false;
                    }
                }
            );
        };
    }

    return disabledValue

};

export function bytesToUnits(bytes, decimals = 2) {
    if (bytes === 0) return '0 MB';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export function addMedicineFilters(url,filters) {
    const FILTER_KEYS = {
        INACTIVE: 'inactive',
        NOT_BLISTABLE: 'not_blistable',
        IF_NEEDED: 'if_needed'
    }
    let result = url;
    Object.entries(filters).forEach(filter => {
        if(filter[1] === false) {
            const filterKey = FILTER_KEYS[filter[0]];
            result = result.concat(`&exclude_${filterKey}=true`);
        }
    });
    return result;
};