// React & Libs
import { useEffect, useState } from "react";
import { nanoid } from "nanoid";
import { withTranslation } from "react-i18next";

// Contexts & Hooks
import { useAppProvider } from "../../context/AppContext";

// Router
import { Link, useHistory } from "react-router-dom";
import ROUTES from "../../constants/ROUTES";

// Components
import Pagination from "../../components/UI/Pagination";
import SearchBar from "../../components/UI/SearchBar";
import useAPI from "../../hooks/useAPI";
import useToastCustom from "../../hooks/useToastCustom";
import NoResults from "../../components/UI/NoResults";
import ModalDelete from '../../components/CommonModals/ModalDelete';

const PatientsList = ({ t }) => {
  const {
    handleLoading,
    handleNotLoading,
    contentIsLoaded,
  } = useAppProvider();

  const { API, handleReqError } = useAPI();
  const { showErrorMessage, showSuccessMessage } = useToastCustom();

  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [patients, setPatients] = useState([]);
  const [ currentID, setCurrentID ]     = useState(null);
  const [ showModalDelete, setShowModalDelete ] = useState(false);

  const canEdit = true;

  let history = useHistory();

  const getPatients = (searchText) => {
    handleLoading();
    const url = searchText
      ? `patients/?text=${searchText}`
      : `patients/?page=${currentPage}`;
    API.get(url)
      .then((response) => {
        if (response) {
          handleNotLoading();
          setTotalPages(response.data.num_pages);
          setPatients(response.data.results);
        }
      })
      .catch((error) => handleReqError(error));
  };

  useEffect(() => {
    getPatients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const deletePatient = (patient_id) => {
    handleLoading();
    API.delete(`patients/${patient_id}/`)
      .then((response) => {
        handleNotLoading();
        if (response.status === 409) {
          showErrorMessage(t("pages.patients.texts.cannotDeleteBecauseUsed"));
        } else if (response.status === 204) {
          getPatients();
          showSuccessMessage(t("pages.patients.texts.deleteOk"));
        } else {
          showErrorMessage(t("pages.patients.texts.errorDeleting"));
        }
        handleNotLoading();
      })
      .catch((error) => {
        const messageError =
          error.request.status === 409
            ? t("pages.patients.texts.cannotDeleteBecauseUsed")
            : error;
        handleReqError(error, messageError);
      });
  };

  const patientsRows = patients.map((patient) => (
    <tr
      key={nanoid()}
      onClick={() => {
        history.push(`patients/patient/${patient.id}`);
      }}
      className="align-middle cursor-pointer onHover"
    >
      <td title={t('pages.patients.texts.see_patient_page')}>
        {patient.is_active ? (
          <span className="material-icons text-success d-block">check_box</span>
        ) : (
          <span className="material-icons text-danger d-block">close</span>
        )}
      </td>
      <td title={t('pages.patients.texts.see_patient_page')}>{patient.name}</td>
      <td title={t('pages.patients.texts.see_patient_page')}>{patient.printing_label_text}</td>
      <td title={t('pages.patients.texts.see_patient_page')}>{patient.hospital.name}</td>
      <td>
        {canEdit ? (
          <button
            onClick={(e) => { e.stopPropagation(); history.push(`${ROUTES.PATIENTS.INDEX}/${patient.id}`)} }
            className="btn btn-outline-primary btn-sm me-2"
            style={{
              position: 'relative',
            }}
          >
            <span className="material-icons">edit</span>{" "}
            <span>{t("general.edit")}</span>
          </button>
        ) : (
          <button
            onClick={(e) => {
              e.stopPropagation();
              history.push(`${ROUTES.PATIENTS.INDEX}/${patient.id}`)
            }}
            className="btn btn-outline-primary btn-sm me-2"
            style={{
              position: 'relative',
            }}
          >
            <span className="material-icons">visibility</span>
            <span>{t("general.view")}</span>
          </button>
        )}
        <button
          className="btn btn-outline-primary btn-sm me-2"
          onClick={ (e) => {
            e.stopPropagation();
            setCurrentID(patient.id);
            setShowModalDelete(true);
          }}
        >
          <span className="material-icons">delete</span>{" "}
          <span>{t("general.delete")}</span>
        </button>
      </td>
    </tr>
  ));

  return (
    <div className="ContentWrapper container p-4">
      <h2 className="font-header text-primary display-6 fw-bold dots-side-left fwp mb-4">
        {t("pages.patients.titles.list")}
      </h2>
      <div className="card p-2">
        <div className="d-flex py-2 pb-2 pb-3 mb-3 border-bottom">
          <SearchBar
            className="col-md-6"
            onChange={(string) => getPatients(string)}
          />
          <div className="ms-auto">
            <Link to={`${ROUTES.PATIENTS.NEW}`}>
              <button className="btn btn-primary btn-sm px-2 mb-2">
                <span className="material-icons me-2">add_box</span>
                <span>{t("pages.patients.titles.create")}</span>
              </button>
            </Link>
          </div>
        </div>
        { contentIsLoaded ? (
          <>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">{t("pages.patients.fields.status")}</th>
                  <th scope="col">{t("pages.patients.fields.name")}</th>
                  <th scope="col">
                    {t("pages.patients.fields.printingLabelText")}
                  </th>
                  <th scope="col">{t("pages.patients.fields.hospital")}</th>
                  <th scope="col">{t("general.action")}</th>
                </tr>
              </thead>
              <tbody>{patientsRows}</tbody>
            </table>
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={(pageNumber) => setCurrentPage(pageNumber)}
            />
          </>
        ) : (
          <>
            <NoResults />
          </>
        )}
      </div>
      <ModalDelete
        show={showModalDelete}
        handleDelete={() => {
          deletePatient(currentID);
          setCurrentID(null);
          setShowModalDelete(false);
        }}
        handleClose={() => {
          setShowModalDelete(false);
          setCurrentID(null);
        }}
      />
    </div>
  );
};

export default withTranslation()(PatientsList);
