import { useState } from 'react';
import { useTreatmentsProvider } from '../../../../../context/TreatmentsContext';
import { getDateFromXdays } from "../../../../../utils/dateUtils";
import { withTranslation } from 'react-i18next';

const MoveDays = ({ t }) => {
    const { initialDate, setInitialDate, getMedicinesFromPatient, activePatient } = useTreatmentsProvider();
    const [ daysToMove, setDaysToMove ] = useState(7);
    
    const handleMoveDays = isPositive => {
        const _daysToMove = isPositive ? daysToMove : daysToMove * -1;
        const newDate = getDateFromXdays(initialDate,_daysToMove)
        setInitialDate(newDate);
        activePatient && getMedicinesFromPatient({start_date: newDate});
    };

    return (
        <div className="MoveDays mb-1">
            <button className="btn btn-primary rounded p-0 me-3" onClick={ () => handleMoveDays(false) }>
                <span className="material-icons">chevron_left</span>
            </button>
            <span>{t('general.move')}</span>
            <input type="number" className="form-control ms-3 me-2 d-inline-block bg-transparent border-0 text-secondary text-center" style={{ width: '60px'}} 
                value={daysToMove} onChange={e => setDaysToMove(e.target.value)} />
            <span className="me-1">{t('general.days')}</span>
            <button className="btn btn-primary rounded p-0 ms-3" onClick={ () => handleMoveDays(true) }>
                <span className="material-icons">chevron_right</span>
            </button>
        </div>
    );
}
 
export default withTranslation()(MoveDays);