import DayHeader from '../../ui/DayHeader';
import { get14DatesfromToday } from "../../../../../utils/dateUtils";
import { useTreatmentsProvider } from '../../../../../context/TreatmentsContext';
import { nanoid } from 'nanoid';

const DaysHeaderRow = () => {

    const { initialDate } = useTreatmentsProvider();
    const dates = get14DatesfromToday(initialDate);

    return (
        <>
            {   dates !== undefined && dates.map( (date,index) => (
                <DayHeader date={date} index={index} key={nanoid()}/>
            ))}
        </>
    );
}
 
export default DaysHeaderRow;