// react
import { useState, useEffect } from "react";

// Context
import { useTreatmentsProvider } from "../../../../context/TreatmentsContext";

// Components
import TableWeekly from "../Table/TableWeekly";
import TableCustomWeekly from "../Table/TableCustomWeekly";
import TableInterval from "../Table/TableInterval";
import TableMonthly from "../Table/TableMonthly";

import PosologyTypeBtnGroup from "./ModalComponents/PosologyTypeBtnGroup";
import WeeklyTypeBtnGroup from "./ModalComponents/WeeklyTypeBtnGroup";
import DoseSelector from "./ModalComponents/DoseSelector";
import { withTranslation } from "react-i18next";
import DayPickerInput from "react-day-picker/DayPickerInput";
import i18n from '../../../../i18n';
import FormInput from "../../../../components/UI/FormInput";


// Styles
import "./styles.css";
import "react-day-picker/lib/style.css";

// Libs
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import "moment/locale/es";

// Constants
import POSOLOGY_TYPES from "../../../../constants/POSOLOGY_TYPES";
import HOURS_LABELS_POSOLOGY from '../../../../constants/HOURS_LABELS_POSOLOGY';
import { ENGLISH } from '../../../../constants/DATE_LOCALE';
import LUNCHTIMES_LABELS_POSOLOGY from '../../../../constants/LUNCHTIMES_LABELS_POSOLOGY';

// Utils
import { DDMMYYYY, DDMMYYYYFromStringDate } from '../../../../utils/dateUtils';
import Switch from "../../../../components/UI/Switch";
import {nanoid} from 'nanoid';

const EditPosologiesModalContent = ({
    t,
    isDisabled
}) => {
    const {language} = i18n;

    // Require
    const _ = require("lodash");

    // Contexts, States & Const
    const {
        patterns,
        setPatterns,
        activeMedicine,
        currentPosology,
        currentPosologyID,
        medicinePosologies,
        setCurrentPosology,
        posologyTypeSelected,
        setPosologyTypeSelected,
        activePosologyWasProduced,
    } = useTreatmentsProvider();

    let start_date, end_date = null;

    if (currentPosology) {
        start_date = currentPosology.start_date ?  currentPosology.start_date : null;
        end_date = currentPosology.end_date ? currentPosology.end_date : null;
    }

    const [canShow, setCanShow]             = useState(false);
    const [doseSelected, setDoseSelected]   = useState(1);

    // Methods
    const handleIfNeeded = (e) => {
        const value = e.target.checked;
        const data = {if_needed: value};
        const posology = { ...currentPosology, ...data};
        setCurrentPosology(posology);
    };

    const handleSpecialDosage = (e) => {
        const value = e.target.checked;
        const data = {special_dosage: value};
        const posology = { ...currentPosology, ...data};
        setCurrentPosology(posology);
    };

    const handleComment = (e) => {
        e.preventDefault();
        const value = e.target.value;
        const data = {comment: value};
        const posology = {...currentPosology, ...data};
        setCurrentPosology(posology);
    };

    const handleDateChange = (isStartDate, date) => {
        const _date = date ? date : null;
        const data = isStartDate ? { start_date: _date } : { end_date: _date };
        const posology = { ...currentPosology, ...data};
        setCurrentPosology(posology);
    };

    const handleWeekType = type => {
        const data = { type: type };
        const posology = { ...currentPosology, ...data};
        setPatterns(null);
        setCurrentPosology(posology);
    };

    const handlePatternsChange = (e,isPositive,id) => {
        e.preventDefault();
        const split = id.split('.');
        const key = split[0];
        const hour = split[1];
        const _patterns = {...patterns};
        const oldData = _patterns[key] ? _patterns[key] : {};
        let quantity;
        const hourValue = oldData[hour] ? Number(oldData[hour]) : false;
        if(hourValue) {
            quantity = isPositive ? hourValue + doseSelected : hourValue - doseSelected;
        } else {
            quantity = isPositive ? doseSelected : 0;
        }
        quantity = quantity < 0 ? 0 : quantity;
        quantity = quantity === 0 ? 0 : quantity.toFixed(2);
        const value = {[hour]: quantity};
        const newData = {[key]: {...oldData, ...value}};
        setPatterns({...patterns, ...newData});
    };

    const reducePatterns = patterns => {
        let result = {...patterns};
        delete result.fast;
        delete result.snack;
        return result;
    }

    const handleMultipleAddDay = (data) => {
        let {_patterns, id, quantity, is6Doses } = data;
        const split = id.split('.');
        const dayName = split[1];
        let updatedData = {};
        const patternsKeys = Object.keys(_patterns);
        let lunchTimes = Object.keys(LUNCHTIMES_LABELS_POSOLOGY);
        if(is6Doses === false) {
            lunchTimes.splice(0,1)
            lunchTimes.splice(2,1)
        };

        lunchTimes.forEach(time => {
            let data = {};
            if(patternsKeys.includes(time) === false) {
                data = { [time]: {
                    [dayName]: quantity < 0 ? 0 : Number(quantity).toFixed(2)
                }}
            } else {
                const values = _patterns[time];
                if(values[dayName]) {
                    let q = (Number(values[dayName]) + quantity);
                    q = q <= 0 ? 0 : q.toFixed(2);
                    data = {[time]: {[dayName] :  Number(q) }};
                } else {
                    let q;
                    if(quantity <= 0) {
                        q = 0
                    } else {
                        q = quantity.toFixed(2)
                    };
                    data = {[time]: {[dayName] : Number(q)}};
                }
            };
            updatedData = _.merge(_patterns, data);
        });

        updatedData = is6Doses === false ? reducePatterns(updatedData) : updatedData;
        setPatterns({...updatedData});

    };

    const handleMultipleAddLunchTime = (data) => {
        let newData = {};
        const { WEEKDAYS_LONG } = ENGLISH;
        let {_patterns, id, quantity, is6Doses } = data;
        const oldData = _patterns[id] ? _patterns[id] : {};
        const keysOld = Object.keys(oldData);

        WEEKDAYS_LONG.forEach(dayName => {
            let data;
            if(keysOld.includes(dayName)) {
                const oldQuantity = Number(oldData[dayName]);
                data = { [dayName]: (quantity + oldQuantity) < 0 ? 0 : (quantity + oldQuantity).toFixed(2) }
            } else {
                data = { [dayName]: Number(quantity).toFixed(2)};
            }
            newData = {...newData, ...data};
        });

        newData = { [id]: newData };
        newData = is6Doses === false ? reducePatterns(newData) : newData;
        setPatterns({...patterns, ...newData});
    };

    const handleMultipleAdd = (isPositive,id,is6Doses) => {
        let _patterns = patterns;
        _patterns = _patterns !== null ? _patterns : {};
        let quantity = isPositive ? doseSelected : (-1 * doseSelected);
        const data = {_patterns, id, quantity, is6Doses};

        if(id.includes('weekdays')) {
            handleMultipleAddDay(data);
        } else {
            handleMultipleAddLunchTime(data);
        }
    };

    const definePatterns = patterns => {
        let result = {};
        patterns.forEach(pattern => {
            const weekday = pattern.weekday;
            const lunchtime = HOURS_LABELS_POSOLOGY[pattern.intake_time];
            const quantity = pattern.quantity;
            const data = {[lunchtime]: {[weekday] : quantity}};
            result = _.merge(result, data);
        });
        return result;
    };

    const defineCustomWeekPatterns = patterns => {
        let result = [];

        patterns.forEach(pattern => {
            const { intake_time, weekday, quantity } = pattern;
            const index = result.findIndex(item => item.intake_time === intake_time);
            if(index !== -1) {
                result[index]['posology'][weekday] = quantity;
            } else {
                const posology = {
                    [weekday]: quantity
                };
                const item = {
                    intake_time: intake_time,
                    posology: posology,
                };
                result.push(item);
            }

        });
        return result;
    };


    // Conditions
    const canShowContent = ((patterns && currentPosologyID ) || (!currentPosologyID)) && canShow;
    const isNewPosology = currentPosologyID ? false : true;

    // LifeCycle
    useEffect(() => {
        if (currentPosologyID) {
            const index = medicinePosologies.findIndex(
                (pos) => pos.id === currentPosologyID
            );
            if(
                medicinePosologies[index].type === POSOLOGY_TYPES[0]
                ||
                medicinePosologies[index].type ===  POSOLOGY_TYPES[1]
            ) {
                setPatterns(definePatterns(medicinePosologies[index]['patterns']));
            } else if (medicinePosologies[index].type === POSOLOGY_TYPES[2] ) {
                setPatterns(defineCustomWeekPatterns(medicinePosologies[index]['patterns']));
            } else{
                setPatterns((medicinePosologies[index]['patterns']));
            }
            setPosologyTypeSelected(medicinePosologies[index].type);
            setCurrentPosology(medicinePosologies[index]);
        } else {
            setPatterns(null);
            setCurrentPosology({
                treatment_medicine: activeMedicine,
                start_date: new Date(),
                end_date: null,
                type: 'weekly',
                if_needed: false,
                special_dosage: false,
                comment: ''
            });
        }
        setCanShow(true);

        return () => {
            setPatterns(null);
            setCanShow(false);
            setCurrentPosology({
                treatment_medicine: null,
                start_date: null,
                end_date: null,
                type: 'weekly',
                if_needed: false,
                special_dosage: false,
                comment: ''
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Render
    return (
        <form>
        { canShowContent && (
            <>
            { canShowContent && isNewPosology && (
                <div className="row mb-4">
                    <div className="col">
                    <PosologyTypeBtnGroup
                        handlePosologyTypeSelected={(value) => {
                            setPatterns(null);
                            setPosologyTypeSelected(value);
                        }}
                    />
                    </div>
                    <div className="col">
                    {(posologyTypeSelected === POSOLOGY_TYPES[0] ||
                        posologyTypeSelected === POSOLOGY_TYPES[1] ||
                        posologyTypeSelected === POSOLOGY_TYPES[2]) && (
                        <WeeklyTypeBtnGroup
                            weekTypeSelected={posologyTypeSelected}
                            setWeekTypeSelected={(value) =>
                                setPosologyTypeSelected(value)
                            }
                            disabled={currentPosologyID ? true : false }
                            onChange={type => handleWeekType(type)}
                        />
                    )}
                    </div>
                </div>
            )}
                <div className="row mb-4">
                    <div className="col">
                    <div className="d-flex flex-column">
                    { !isDisabled &&
                        <DoseSelector
                            doseSelected={doseSelected}
                            setDoseSelected={setDoseSelected}
                            disabled={(!!activePosologyWasProduced || isDisabled)}
                        />
                    }
                    </div>
                    </div>
                    <div className="col">
                        <div className="d-flex">
                            <div className="col pl-0 me-2">
                                <label htmlFor="date1" className="form-label">
                                    {t('pages.planning.fields.startDate')}
                                </label>

                                { (!activePosologyWasProduced && !isDisabled) ?

                                <DayPickerInput
                                    onDayChange={(date) => handleDateChange(true,date)}
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    format="DD-MM-YYYY"
                                    placeholder={t('general.datePlaceholder')}
                                    inputProps={{
                                        className: "form-control form-control-lg bg-white",
                                        readOnly: false,
                                    }}
                                    dayPickerProps={{
                                        locale: language,
                                        localeUtils: MomentLocaleUtils,
                                        onDayClick: (date) => (!!activePosologyWasProduced && !isDisabled) && handleDateChange(true,date),
                                        disabledDays: [
                                            {
                                            after: end_date
                                                ? new Date(end_date)
                                                : null,
                                            },
                                        ],
                                        todayButton: t('date.today')

                                    }}
                                    value={start_date ? DDMMYYYYFromStringDate(start_date) : null}
                                />
                                :

                                <FormInput disabled={true} classnames="form-control-lg bg-white text-muted" value={DDMMYYYYFromStringDate(new Date(start_date))}/>}
                            </div>
                            <div className="col">
                                <label htmlFor="date2" className="form-label">
                                    {t('pages.planning.fields.endDate')}
                                </label>

                                { (!activePosologyWasProduced && !isDisabled) ?
                                <DayPickerInput
                                    onDayChange={(date) => handleDateChange(false,date)}
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    format="DD-MM-YYYY"
                                    placeholder={t('general.datePlaceholder')}
                                    inputProps={{
                                        className: "form-control form-control-lg bg-white",
                                        readOnly: false,
                                    }}
                                    dayPickerProps={{
                                        locale: language,
                                        localeUtils: MomentLocaleUtils,
                                        onDayClick: (date) => !!activePosologyWasProduced && !isDisabled && handleDateChange(true,date),
                                        disabledDays: [
                                            {
                                            before: start_date
                                                ? new Date(start_date)
                                                : null,
                                            },
                                        ],
                                        todayButton: t('date.today')
                                    }}
                                    value={end_date ? DDMMYYYYFromStringDate(end_date) : null}
                                />
                                :
                                <FormInput disabled={true} classnames="form-control-lg bg-white text-muted" value={end_date ?  DDMMYYYYFromStringDate(end_date) : null  }/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col d-flex align-items-center">
                        <Switch
                            isDisabled={isDisabled}
                            key={nanoid()}
                            onChange={handleIfNeeded}
                            value={currentPosology.if_needed}
                            labelText={t('pages.planning.texts.ifNeeded')}
                            classnames={'me-4'}
                        />
                        <Switch
                            isDisabled={isDisabled}
                            key={nanoid()}
                            onChange={handleSpecialDosage}
                            value={currentPosology.special_dosage}
                            labelText={t('pages.planning.texts.specialDosage')}
                            classnames={'me-4'}
                        />
                    </div>

                    { !isDisabled &&
                        <div className="col">
                            <label htmlFor="exampleFormControlTextarea1" className="form-label d-flex align-items-center">
                                <span className="material-icons-outlined me-2">feedback</span> <span>{t('general.addComment')}</span>
                            </label>
                            <textarea rows="2" className="form-control form-control-sm" id="exampleFormControlTextarea1" onInput={handleComment} value={currentPosology.comment} />
                        </div>
                    }


                </div>
                <div className="row">
                    <div className="col">
                    {posologyTypeSelected === POSOLOGY_TYPES[0] && canShow  && (
                        <TableWeekly
                            is6Doses={false}
                            patterns={patterns}
                            handlePatternsChange={handlePatternsChange}
                            handleMultipleAdd={handleMultipleAdd}
                            disabled={ !!activePosologyWasProduced || isDisabled }
                        />
                    )}
                    {posologyTypeSelected === POSOLOGY_TYPES[1]  && (
                        <TableWeekly
                            is6Doses={true}
                            patterns={patterns}
                            handlePatternsChange={handlePatternsChange}
                            handleMultipleAdd={handleMultipleAdd}
                            disabled={ !!activePosologyWasProduced || isDisabled }
                        />
                    )}
                    {posologyTypeSelected === POSOLOGY_TYPES[2] && (
                        <TableCustomWeekly
                            step={doseSelected}
                            patterns={patterns}
                            disabled={ !!activePosologyWasProduced || isDisabled }
                        />
                    )}
                    {posologyTypeSelected === POSOLOGY_TYPES[3] && (
                        <TableInterval
                            disabled={isDisabled}
                            step={doseSelected}
                            onContextMenu={(e) => {
                                e.preventDefault();
                            }}
                        />
                    )}
                    {posologyTypeSelected === POSOLOGY_TYPES[4] && (
                        <TableMonthly
                            disabled={isDisabled}
                            step={doseSelected}
                            onContextMenu={(e) => {
                                e.preventDefault();
                            }}
                        />
                    )}
                    </div>
                </div>
            </>
        )}
        </form>
    );
};

export default withTranslation()(EditPosologiesModalContent);
