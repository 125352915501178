import classNames from "classnames";
import { withTranslation } from 'react-i18next';

const ProposalStatusBadge = ({
    t,
    isApplied = false,
    proposalStatus,
}) => {

    const PROPOSALS_TEXT = {
        'approved': 'pages.proposals.proposalsStatus.valid',
        'pending':  'pages.proposals.proposalsStatus.pending',
        'rejected': 'pages.proposals.proposalsStatus.rejected',
        'applied_but_pending': 'pages.proposals.proposalsStatus.appliedPending'
    };

    return (
       <div className="ProposalState mt-1">
            <div className={classNames([
                "badge mb-1 rounded-pill d-flex align-items-center px-2 ",
                proposalStatus === 'pending' && isApplied === false && 'bg-orange',
                proposalStatus === 'approved' && 'bg-success',
                proposalStatus === 'rejected' && 'bg-danger',
                proposalStatus === 'pending' && isApplied === true && 'bg-warning'
            ])}>
                {proposalStatus === 'pending' && isApplied === false && (
                    <div
                        className="material-icons-outlined me-1"
                        style={{fontSize: '16px'}}
                    >help</div>
                )}
                {proposalStatus === 'approved' && (
                    <div
                        className="material-icons-outlined me-1"
                        style={{fontSize: '16px'}}
                    >done</div>
                )}
                {proposalStatus === 'rejected' && (
                    <div
                        className="material-icons-outlined me-1"
                        style={{fontSize: '16px'}}
                    >clear</div>
                )}
                {proposalStatus === 'pending' && isApplied === true && (
                    <div className="material-icons-outlined me-1">
                        pending_actions
                    </div>
                )}
                { proposalStatus === 'pending' && isApplied === true
                    ?
                    <div className="me-1 text-start">{t('general.appliedF')}<br />{t(PROPOSALS_TEXT['applied_but_pending'])}</div>
                    :
                    <div className="me-1 text-start">{t(PROPOSALS_TEXT[proposalStatus]) }</div>
                }
            </div>
         </div>
    );
}

export default withTranslation()(ProposalStatusBadge);