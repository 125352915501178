import Avatar from '../Avatar';
import { DDMMYYYY_HHMMSS } from '../../../utils/dateUtils';
import { withTranslation } from 'react-i18next';

const MessageNotificationRow = ({
    t,
    message
}) => {

    const PROPOSALS_TOPICS = {
        "PROPOSAL_CREATED"  : 'proposal-created',
        "PROPOSAL_APPROVED" : 'proposal-approved',
        "PROPOSAL_REJECTED" : 'proposal-rejected',
        "PROPOSAL_DELETED"  : 'proposal-deleted',
        "PROPOSAL_APPLIED"  : 'proposal-applied',
    };

    const PROPOSALS_TOPICS_TRANSLATION_KEYS = {
        'proposal-created'  : 'proposalCreated',
        'proposal-approved' : 'proposalApproved',
        'proposal-rejected' : 'proposalRejected',
        'proposal-deleted'  : 'proposalDeleted',
        'proposal-applied'  : 'proposalApplied',
    };

    const PROPOSALS_COLOR_SCHEME = {
        [ PROPOSALS_TOPICS["PROPOSAL_CREATED"] ]    : 'light',
        [ PROPOSALS_TOPICS["PROPOSAL_APPROVED"] ]   : 'success',
        [ PROPOSALS_TOPICS["PROPOSAL_REJECTED"] ]   : 'danger',
        [ PROPOSALS_TOPICS["PROPOSAL_DELETED"] ]    : 'danger',
        [ PROPOSALS_TOPICS["PROPOSAL_APPLIED"] ]    : 'secondary',
    };

    const PROPOSALS_COLORICON_SCHEME = {
        [ PROPOSALS_TOPICS["PROPOSAL_CREATED"] ]    : 'success',
        [ PROPOSALS_TOPICS["PROPOSAL_APPROVED"] ]   : 'white',
        [ PROPOSALS_TOPICS["PROPOSAL_REJECTED"] ]   : 'white',
        [ PROPOSALS_TOPICS["PROPOSAL_DELETED"] ]    : 'white',
        [ PROPOSALS_TOPICS["PROPOSAL_APPLIED"] ]    : 'dark',
    };

    const PROPOSALS_ICONS = {
        [ PROPOSALS_TOPICS["PROPOSAL_CREATED"] ]    : 'add',
        [ PROPOSALS_TOPICS["PROPOSAL_APPROVED"] ]   : 'check',
        [ PROPOSALS_TOPICS["PROPOSAL_REJECTED"] ]   : 'close',
        [ PROPOSALS_TOPICS["PROPOSAL_DELETED"] ]    : 'delete',
        [ PROPOSALS_TOPICS["PROPOSAL_APPLIED"] ]    : 'assignment_turned_in',
    };

    const bgcolor   = PROPOSALS_COLOR_SCHEME[message.topic] ? PROPOSALS_COLOR_SCHEME[message.topic] : 'light';
    const icon      = PROPOSALS_ICONS[message.topic];
    const color     = PROPOSALS_COLORICON_SCHEME[message.topic];

    return Object.values(PROPOSALS_TOPICS).includes(message.topic) ? (
        <li
            className="list-group-item message"
        >
            <div className="d-flex mb-2 align-items-center">
                <Avatar
                    width={'24px'}
                    height={'24px'}
                    className="me-2"
                    icon={icon}
                    bgClass={`bg-${bgcolor}`}
                    color={color}
                    iconSize={'13px'}
                />
                <div>
                    <h6
                        className="mb-0"
                        style={{ fontSize: '14px', fontWeight: 'bold'}}
                    >
                        { message.user?.name }
                    </h6>
                    <h6
                        className="mb-0"
                        style={{ fontSize: '14px'}}
                    >
                        { t(`pages.proposals.notification_messages.${PROPOSALS_TOPICS_TRANSLATION_KEYS[message.topic]}`)}
                    </h6>
                        <p style={{ fontSize: '11px'}} className="mb-0">
                            {message.data && message.data.medicine &&
                                <strong className="me-2">{message.data.medicine.name}</strong>
                            }
                            <span>{DDMMYYYY_HHMMSS(new Date(message.created_at))}</span>
                        </p>
                </div>
            </div>
        </li>
    ) : null;
};

export default withTranslation()(MessageNotificationRow);