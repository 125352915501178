import DayHeader from '../../ui/DayHeader';
import { get14DatesfromToday } from "../../../../../utils/dateUtils";
import { useTreatmentsProvider } from '../../../../../context/TreatmentsContext';
import { nanoid } from 'nanoid';

const DaysHeaderRow = () => {

    const { initialDate } = useTreatmentsProvider();
    const dates = get14DatesfromToday(initialDate);

    return (
        <div
            className="DaysHeaderRow"
            style={{
                gridColumn: '2/17',
                display: 'grid',
                gridTemplateColumns: 'repeat(15,80px)'
            }}
        >
            {   dates !== undefined && dates.map( (date,index) => (
                <DayHeader date={date} index={index} key={nanoid()}/>
            ))}
        </div>
    );
}
 
export default DaysHeaderRow;