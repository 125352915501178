// React & Libs
import { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { withTranslation } from 'react-i18next';
import { Flags } from "react-feature-flags";

// Contexts & Hooks
import { useAppProvider } from '../../context/AppContext';
import useAPI from '../../hooks/useAPI';

// Router
import { Link } from 'react-router-dom';
import ROUTES from '../../constants/ROUTES';

// Components
import Pagination from '../../components/UI/Pagination';
import SearchBar from '../../components/UI/SearchBar';
import useToastCustom from '../../hooks/useToastCustom';
import NoResults from '../../components/UI/NoResults';
import ModalDelete from '../../components/CommonModals/ModalDelete';

// CONSTANTS
import PERMISSIONS from '../../constants/PERMISSIONS';

const HospitalsList = ({ t }) => {

  const {
    handleLoading,
    contentIsLoaded,
    handleNotLoading,
  } = useAppProvider();

  const { API, handleReqError } = useAPI();
  const { showErrorMessage, showSuccessMessage } = useToastCustom();

  const [ hospitals, setHospitals ]     = useState([]);
  const [ totalPages, setTotalPages ]   = useState(null);
  const [ currentPage, setCurrentPage ] = useState(1);
  const [ currentID, setCurrentID ]     = useState(null);
  const [ showModalDelete, setShowModalDelete ] = useState(false);

  const getHospitals = () => {
    handleLoading();
    API.get(`hospitals/?page=${currentPage}`)
    .then((response) =>{
      handleNotLoading();
      setTotalPages(response.data.num_pages);
      setHospitals(response.data.results);
    })
    .catch((error) => handleReqError(error));  }

  // Get Hospitals on Init Component and when pagination current page changes
  useEffect(() => {
    getHospitals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const getSearchResults = (string) => {
    handleLoading();
    API.get(`hospitals/?text=${string}`)
    .then((response) => {
      if (response) {
        handleNotLoading();
        setTotalPages(response.data.num_pages);
        setHospitals(response.data.results);
      }
    })
    .catch((error) => handleReqError(error));
  };

  const deleteHospital = (hospital_id) => {
    handleLoading();
    API.delete(`hospitals/${hospital_id}/`)
    .then((response) => {
      handleNotLoading();
      if(response.status === 409){
        getHospitals();
        showErrorMessage(t("pages.hospitals.texts.cannotDeleteBecauseUsed"));
      } else if(response.status === 204){
        getHospitals();
        showSuccessMessage(t("pages.hospitals.texts.deleteOk"));
      }else{
        showErrorMessage(t("pages.hospitals.texts.errorDeleting"));
      }
      setShowModalDelete(false);
    })
    .catch((error) => {
      const messageError = error.request.status === 409 ? t("pages.hospitals.texts.cannotDeleteBecauseUsed") : error;
      handleReqError(error, messageError);
      setShowModalDelete(false);
    });
  }

  const hospitalsRows = hospitals.map((hospital) => (
    <tr key={nanoid()} data-cy={"table-row-hospital-" + hospital.id}>
      <td>{hospital.name}</td>
      <td>
        {hospital.is_active ? (
          <span className='material-icons text-success'>check_box</span>
        ) : (
          <span className='material-icons text-danger'>close</span>
        )}
      </td>
      <td>{hospital.contact_person}</td>
      <td>{hospital.country}</td>
      <td>
        <Flags authorizedFlags={[PERMISSIONS['CAN_EDIT_HOSPITALS']]}>
          <Link to={`${ROUTES.HOSPITALS.INDEX}/${hospital.id}`}>
              <button
                data-cy={"button-edit-hospital-" + hospital.id}
                className='btn btn-outline-primary btn-sm me-2'
              >
                <span className='material-icons'>edit</span> <span>{t('general.edit')}</span>
              </button>
            </Link>
        </Flags>

        <Flags authorizedFlags={[PERMISSIONS['CAN_ONLY_VIEW_HOSPITALS']]}>
          <Link to={`${ROUTES.HOSPITALS.INDEX}/${hospital.id}`}>
            <button
              data-cy={"button-view-hospital-" + hospital.id}
              className='btn btn-outline-primary btn-sm me-2'
            >
              <span className='material-icons'>visibility</span>
              <span>{t('general.view')}</span>
            </button>
          </Link>
        </Flags>

        <Flags authorizedFlags={[PERMISSIONS['CAN_DELETE_HOSPITALS']]}>
          <>
            <button
              data-cy={"button-delete-hospital-" + hospital.id}
              className='btn btn-outline-primary btn-sm me-2'
              onClick={ () => {
                setCurrentID(hospital.id);
                setShowModalDelete(true);
              }}
            >
              <span className='material-icons'>delete</span> <span>{t('general.delete')}</span>
            </button>
          </>
        </Flags>

      </td>
    </tr>
  ));

  return (
    <div className="ContentWrapper container p-4">
      <h2 className='font-header text-primary display-6 fw-bold dots-side-left fwp mb-4'>
        {t('pages.hospitals.titles.list')}
      </h2>
      <div className='card p-2'>
        <div className='d-flex py-2 pb-2 pb-3 mb-3 border-bottom'>
          <SearchBar
            className='col-md-6'
            onChange={(string) => getSearchResults(string)}
          />
          <div className='ms-auto'>
            <Flags authorizedFlags={[PERMISSIONS['CAN_EDIT_HOSPITALS']]}>
              <Link to={`${ROUTES.HOSPITALS.NEW}`}>
                <button className='btn btn-primary btn-sm px-2 mb-2' data-cy="button-create-hospital">
                  <span className='material-icons me-2'>add_box</span>
                  <span>{t('pages.hospitals.titles.create')}</span>
                </button>
              </Link>
            </Flags>
          </div>
        </div>
        {contentIsLoaded ? (
          <>
            <table className='table' data-cy="table-hospitals">
              <thead>
                <tr>
                  <th scope='col'>{t('pages.hospitals.fields.name')}</th>
                  <th scope='col'>{t('pages.hospitals.fields.isActive')}</th>
                  <th scope='col'>
                    {t('pages.hospitals.fields.contactPerson')}
                  </th>
                  <th scope='col'>{t('pages.hospitals.fields.country')}</th>
                  <th scope='col'>{t('general.action')}</th>
                </tr>
              </thead>
              <tbody>{hospitalsRows}</tbody>
            </table>
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={(pageNumber) => setCurrentPage(pageNumber)}
            />
          </>
        ) : (
          <>
            <NoResults />
          </>
        )}
      </div>
      <ModalDelete
        show={showModalDelete}
        handleDelete={() => {
          deleteHospital(currentID);
          setCurrentID(null);
        }}
        handleClose={() => {
          setShowModalDelete(false);
          setCurrentID(null);
        }}
      />
    </div>
  );
};

export default withTranslation()(HospitalsList);
