import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { withTranslation } from "react-i18next";
import { useTreatmentsProvider } from "../../../../../context/TreatmentsContext";

const ModalAlertInvalidAction = ({
    t,
    show,
    handleClose,
}) => {

    const { messageIsModify } = useTreatmentsProvider();

    return (
        <Modal show={show} onHide={handleClose} size={'lg'} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h6 className={'d-flex align-items-center text-danger'}><span className="material-icons-outlined me-2">warning</span> <span>{t('general.warning')}</span></h6>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{textAlign: "center"}}>
                { messageIsModify ?
                    <p
                        className="lead py-4"
                        style={{
                            textAlign: "left",
                            width: "auto",
                            display: "inline-block",
                            margin: "0 auto"
                        }}
                    >
                        {t('modals.cannotModifyProduced')}
                        <br /><br />
                        <u>{t('modals.cannotModifyProduced2')}</u>
                    </p>
                :
                    <p
                        className="lead py-4"
                        style={{
                            textAlign: "left",
                            width: "auto",
                            display: "inline-block",
                            margin: "0 auto"
                        }}
                    >
                        {t('modals.cannotEditProduced')}
                        <br /><br />
                        <u>{t('modals.cannotEditProduced2')}</u>
                    </p>
                }

            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={() => handleClose()}>{t('general.close')}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default withTranslation()(ModalAlertInvalidAction);