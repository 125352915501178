import Avatar from '../Avatar';
import { DDMMYYYY_HHMMSS } from '../../../utils/dateUtils';
import ClassNames from 'classnames';

const MessageRow = ({message}) => {
    const name = message.user && message.user.name
    ? message.user.name
    : "-";

    return message.data && message.data.text ? (
        <li
            className={ClassNames([
                "list-group-item message",
                message.is_from_requester && 'message-right'
            ])
        }>
            <div
                className="d-flex align-items-center"

            >
                <Avatar
                    initial={name.charAt(0)}
                    width={'28px'}
                    height={'28px'}
                    className="me-2"
                />
                <div>
                    
                    <h6
                        className="mb-0"
                        style={{ fontSize: '14px', fontWeight: 'bold'}}
                    >
                        { name }
                    </h6>

                    <p style={{ fontSize: '11px'}} className="mb-0">
                        {message.data && message.data.medicine && 
                            <strong className="me-2">{message.data.medicine.name}</strong>
                        }
                        <span>{DDMMYYYY_HHMMSS(new Date(message.created_at))}</span>
                    </p>

                </div>
            </div>
            { message.data && message.data.text && 
                <p style={{ fontSize: '13px'}} className="mb-1">{message.data.text}</p>
            }
        </li>
    ) : null;
};

 
export default MessageRow;