import { toast } from "react-toastify";

function useToastCustom() {
    const showErrorMessage = (message) => {
        toast(message, {
          position: toast.POSITION.TOP_CENTER,
          type: toast.TYPE.ERROR,
          autoClose: 4500
        });
    }
    
    const showSuccessMessage = (message) => {
      toast(message, {
        position: toast.POSITION.TOP_CENTER,
        type: toast.TYPE.SUCCESS,
        autoClose: 4500,
      });
    }

    return { showSuccessMessage, showErrorMessage }
}

export default useToastCustom;