// Libs & React
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Classnames from 'classnames';

// Router
import { useParams } from "react-router-dom";

// Contexts & Hooks
import useAPI from "../../hooks/useAPI";

// Components
import { useAppProvider } from "../../context/AppContext";
import UserInfoTabContent from './UserInfoTabContent';
import FilesTabContent from './FilesTabContent';
import MessagesTabContent from './MessagesTabContent';


const PatientPage = ({
  t,
  isModal,
  patientID
}) => {

  const { patient_id } = useParams();
  const _patient_id = patient_id ? patient_id : patientID;
  const { handleLoading, handleNotLoading } = useAppProvider();

  const { API, handleReqError } = useAPI();
  const [ patient, setPatient ] = useState({});
  const [ sectionActive, setSectionActive ] = useState(0);

  const defineHospitals = arr => {
    let result = [];
    arr.forEach(el => {
      const data = {
        value: el.id,
        name: el.name 
      };
      result.push(data);
    });
    // setHospitals(result);
  }

  useEffect(() => {
    handleLoading();
    API.get(`patients/${_patient_id}/`)
    .then((response) => {
        if (response) {
            handleNotLoading();
            let _patient = {...response.data };
            if(_patient.date_of_birth){
            _patient.date_of_birth = _patient.date_of_birth.split('T')[0];
            }
            setPatient({..._patient});
        }
    })
    .catch((error) => handleReqError(error));

    // Hospitals
    API.get("hospitals/?page_all=1")
    .then((response) => {
      if (response) {
        handleNotLoading();
        defineHospitals(response.data);
      }
    })
    .catch((error) => handleReqError(error));


    // eslint-disable-next-line react-hooks/exhaustive-deps
    },  [patient_id]);

  return (
    <div className="ContentWrapper container p-4">
      <h2 className="font-header text-primary display-6 fw-bold dots-side-left fwp mb-4">
        {patient.name}
      </h2>
      <div className="card p-2 ">

        <ul className="nav nav-tabs">
            <li className="nav-item">
                <button
                    className={Classnames([
                        "nav-link",
                        sectionActive === 0 && "active"
                    ])}
                    aria-current="page"
                    onClick={() => setSectionActive(0)}
                >
                    <span className="material-icons-outlined align-middle me-2">assignment_ind</span>
                    <span>{t('general.patient_info')}</span>
                </button>
            </li>
            <li className="nav-item">
                <button
                    className={Classnames([
                        "nav-link",
                        sectionActive === 1 && "active"
                    ])}
                    aria-current="page"
                    onClick={() => setSectionActive(1)}
                >
                    <span className="material-icons-outlined align-middle me-2">description</span>
                    <span>{t('general.files')}</span>
                </button>
            </li>
            <li className="nav-item">
                <button 
                    className={Classnames([
                        "nav-link",
                        sectionActive === 2 && "active"
                    ])}
                    aria-current="page"
                    onClick={() => setSectionActive(2)}
                >
                    <span className="material-icons-outlined align-middle me-2">question_answer</span>
                    <span>{t('general.messages')}</span>
                    </button>
            </li>
        </ul>
        
        <div className="row g-3 p-3">
            { sectionActive === 0 && 
              <UserInfoTabContent patient={patient} isModal={isModal} />
            }
            { sectionActive === 1 && 
              <FilesTabContent patientId={patient.id}/>
            }
            { sectionActive === 2 && 
              <MessagesTabContent patientId={patient.id}/>
            }
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(PatientPage);
