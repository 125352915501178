
import { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import FormInput from "../../../../components/UI/FormInput";
import { withTranslation } from "react-i18next";

const ModalEditFloor = ({
    t,
    show,
    floor,
    editFloor,
    handleClose,
    handlePrepareDelete
}) => {

    const [ _floor, setFloor ] = useState({
        name: '',
        id: ''
    });

    const handleKeyPress = e =>{
        if(e.code === 'Enter' && e.target.value.trim() !== ''){
            setFloor({..._floor, name: e.target.value});
            editFloor(_floor.id, _floor.name);
        };
    };

    useEffect(() => {
        floor && setFloor(floor);
    },[floor]);

    return (
        <Modal
            show={show}
            animation={false}
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {t('general.edit')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormInput
                    labelText={t('pages.hospitals.floors.floorName')}
                    placeholder={t('pages.hospitals.floors.addPlaceholder')}
                    value={_floor.name}
                    onChange={ e => setFloor({..._floor, name: e.target.value})}
                    onKeyPress={e => handleKeyPress(e)}
                />
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex justify-content-between w-100">
                    <div>
                        <button
                            className="btn btn-outline-danger"
                            onClick={() => handlePrepareDelete(_floor.id)}
                        >
                            <span
                                className="material-icons me-2"
                                style={{
                                    fontSize:"16px"
                                }}
                            >delete</span>{t('pages.hospitals.floors.deleteFloor')}
                        </button>
                    </div>
                    <div>
                        <button
                            className="btn btn-outline-primary me-2"
                            onClick={() => handleClose()}
                        >{t('general.cancel')}</button>
                        <button
                            disabled={_floor.name.trim() === ''}
                            className="btn btn-primary"
                            onClick={() => editFloor(_floor.id, _floor.name)}
                        >{t('general.save')}</button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default withTranslation()(ModalEditFloor);
