import Switch from '../../../../../../components/UI/Switch'
import { withTranslation } from 'react-i18next';

const ProposalFilterItem = ({
    id,
    hide,
    value,
    onChange,
    labelText,
}) => {

    return !hide && (
        <Switch
            id={id}
            value={value}
            labelText={
                <div>{labelText}</div>
            }
            classnames={'my-2'}
            onChange={onChange}
        />
    );

}

export default withTranslation()(ProposalFilterItem);