import { useEffect, useState } from 'react';
// import LoaderButtonComp from '../LoaderButtonComp';
import Classnames from 'classnames';
import ButtonWrapper from 'react-bootstrap/Button';

function Button({
  type,
  badge,
  dataCy,
  children,
  isLoading,
  iconLabel,
  stylingClass,
  ...props
}) {
    const { ref, width, height, disabled } = props;
    const [ showLoader, setShowLoader ] = useState(false);

    // Inside the NiceButton function
    useEffect(() => {
      if (isLoading) {
        setShowLoader(true);
      }

      // Show loader a bits longer to avoid loading flash
      if (!isLoading && showLoader) {
        const timeout = setTimeout(() => {
          setShowLoader(false);
      }, 400);

      // Don’t forget to clear the timeout
      return () => {
          clearTimeout(timeout);
      };
      }
  }, [isLoading, showLoader]);

  return (
    <ButtonWrapper
        disabled={disabled}
        type={type ? type : 'button'}
        data-cy={dataCy}
        className={Classnames([
            "btn",
            stylingClass
        ])}
        ref={ref}
        style={
        width && height
          ? {
              width: `${width}px`,
              height: `${height}px`,
            }
          : {}
      }
      {...props}
    >
      {iconLabel && 
        <span className="material-icons-outlined">
          {iconLabel}
        </span>
      }
      {showLoader ? (
        <div>
          <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
          <span className="sr-only"></span>
        </div>
      ) : (
        <>
          {children}
          { badge && badge }
        </>
      )}
    </ButtonWrapper>
  );
}

export default Button;