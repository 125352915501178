// import FormDate from '../../../../../components/UI/FormDate';
import { useTreatmentsProvider } from '../../../../../context/TreatmentsContext';

// Libs
import { withTranslation } from 'react-i18next';
import DayPickerInput from "react-day-picker/DayPickerInput";
import Classnames from 'classnames';
import i18n from "i18next";
import DATE_LOCALE from '../../../../../constants/DATE_LOCALE';
import {YYYYMMDD} from "../../../../../utils/dateUtils";

// Components
import FormDate from "../../../../../components/UI/FormDate";

const InitialDateInput = ({ t }) => {
    
    const { initialDate, setInitialDate, getMedicinesFromPatient, activePatient } = useTreatmentsProvider();
    
    const handleDateChange = formattedDate => {
        setInitialDate(new Date(formattedDate));
        const date = YYYYMMDD(new Date(formattedDate));
        activePatient && getMedicinesFromPatient({start_date: date});
    }

    return (
        <div className="InitialDateInput me-5">
            <FormDate
                value={initialDate ? initialDate : null}
                onChange={formattedDate => handleDateChange(formattedDate)}
                labelText={t('pages.planning.texts.initialDate')}
            />
        </div>
    );
}
 
export default withTranslation()(InitialDateInput);