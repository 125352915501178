// Router
import {
  NavLink
} from "react-router-dom";
import ROUTES from '../../../constants/ROUTES';
import MODULES from '../../../constants/MODULES';
import { withTranslation } from 'react-i18next';
// css
import './styles.scss';
import classNames from "classnames";
import { useAppProvider } from "../../../context/AppContext";

import { Flags } from "react-feature-flags";
import PERMISSIONS from "../../../constants/PERMISSIONS";

const MenuSideBar = ({ t }) => {

    const { sidebarIsExpanded, setSidebarIsExpanded, userRole } = useAppProvider();
    
    return (
        <div className={classNames([
            "SidebarMenu bg-gradient-secondary d-flex flex-column justify-content-between",
            !sidebarIsExpanded && "SidebarMenu--isCollapsed"
        ])}>
            { userRole !== null && 
            <>
            <ul className="SidebarMenu__Container navbar-nav accordion" id="accordionSidebar">
                {/* Sidebar - Brand */}
                <div className="sidebar-brand d-flex align-items-center justify-content-start" href="index.html">
                    { sidebarIsExpanded && <img width="80" alt="ti-dose" src={process.env.PUBLIC_URL + '/tidose@2x.png'} />  }
                </div>

                {/* Divider */}
                <hr className="sidebar-divider my-0" />

                <li className="nav-item">
                    <NavLink exact className="nav-link py-3 d-flex align-items-center" to={ROUTES.ROOT.INDEX}>
                        <span className="material-icons-outlined align-middle me-3">home</span> { sidebarIsExpanded && <span className="align-bottom">
                            {t('navigation.home')}
                        </span> }
                    </NavLink>
                </li>

                <Flags authorizedFlags={[MODULES["HOSPITALS"]]}>
                    <li className="nav-item" data-cy="sidenav-hospitals">
                        <NavLink className="nav-link py-3 d-flex align-items-center" to={ROUTES.HOSPITALS.INDEX}>
                            <span className="material-icons-outlined align-middle me-3">business</span> { sidebarIsExpanded && <span className="align-bottom">
                                {t('navigation.hospitals')}
                            </span> }
                        </NavLink>
                    </li>
                </Flags>

                <Flags authorizedFlags={[MODULES["PATIENTS"]]}>
                    <li className="nav-item">
                        <NavLink className="nav-link py-3 d-flex align-items-center" to={ROUTES.PATIENTS.INDEX}>
                            <span className="material-icons-outlined align-middle me-3">hotel</span> { sidebarIsExpanded && <span className="align-bottom">
                                {t('navigation.patients')}
                            </span> }
                        </NavLink>
                    </li>
                </Flags>

                <Flags authorizedFlags={[MODULES["BOOK"]]}>
                    <li className="nav-item">
                        <NavLink className="nav-link py-3 d-flex align-items-center" to={ROUTES.THERAPEUTIC_BOOK.INDEX}>
                            <span className="material-icons-outlined align-middle me-3">book</span> { sidebarIsExpanded && <span className="align-bottom">
                                {t('navigation.therapeuticBook')}
                            </span> }
                        </NavLink>
                    </li>
                </Flags>

                <Flags authorizedFlags={[MODULES["TREATMENTS"]]}>
                    <li className="nav-item">
                        <NavLink className="nav-link py-3" to={ROUTES.PLANNING.INDEX}>
                            <span className="material-icons-outlined align-middle me-3">today</span> { sidebarIsExpanded && <span className="align-bottom">
                                {t('navigation.planning')}
                            </span> }
                        </NavLink>
                    </li>
                </Flags>

                <Flags authorizedFlags={[ MODULES["PROPOSALS"] ]}>
                    <li className="nav-item">
                        <NavLink className="nav-link py-3 position-relative d-flex align-items-center" to={ROUTES.PROPOSALS.INDEX}>
                            <span className="material-icons-outlined align-middle me-3">today</span>
                            <span 
                                className="material-icons-outlined align-middle me-3"
                                style={{
                                    position: 'absolute',
                                    left: '35px',
                                    fontSize: '20px',
                                    top: '23px'
                                }}
                            >change_circle</span> { sidebarIsExpanded && <span className="align-bottom">
                                {t('navigation.proposals')}
                            </span> }
                        </NavLink>
                    </li>
                </Flags>

                <Flags authorizedFlags={[MODULES["PROPOSALS_VALIDATION"] ]}>
                    <li className="nav-item">
                        <NavLink className="nav-link py-3 position-relative d-flex align-items-center" to={ROUTES.PROPOSALS.INDEX}>
                            <span className="material-icons-outlined align-middle me-3">fact_check</span>
                            { sidebarIsExpanded && <span className="align-bottom">
                                {t('navigation.validation')}
                            </span> }
                        </NavLink>
                    </li>
                </Flags>

                <Flags authorizedFlags={[PERMISSIONS["CAN_EDIT_PRODUCTIONS"]]}>
                    <li className="nav-item">
                        <NavLink className="nav-link py-3 d-flex align-items-center" to={ROUTES.PRODUCTIONS.INDEX}>
                            <span className="material-icons-outlined align-middle me-3">inventory_2</span> { sidebarIsExpanded && <span className="align-bottom">
                                {t('navigation.productions')}
                            </span> }
                        </NavLink>
                    </li>
                </Flags>

            </ul>
            <button
                className="bg-transparent border-0 SidebarMenu__Toggle"
                onClick={() => setSidebarIsExpanded(!sidebarIsExpanded)}
            ><div className="d-flex align-items-center justify-content-center">
            { sidebarIsExpanded
            ? 
                <><span className="material-icons-outlined">chevron_left</span> <span>{t('navigation.collapse')}</span></>
            : 
                <span className="material-icons-outlined">chevron_right</span>
            }
            </div></button>
            </>
            }
       </div>
    );
}

export default withTranslation()(MenuSideBar);