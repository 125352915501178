import { useState, useEffect, useRef } from "react";
import Classnames from "classnames";

import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { DDMMYYYY, YYYYMMDD } from "../../../utils/dateUtils";
import i18n from "i18next";
import DATE_LOCALE from "../../../constants/DATE_LOCALE";
import { withTranslation } from "react-i18next";

import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";

const FormDate = ({
  t,
  id,
  value,
  isSmall,
  onChange,
  required,
  labelText,
  modifiers,
  classnames,
  errorMessage,
  disabledDays,
  needsValidation,
  containerClasses,
}) => {
  const language = i18n.language;
  const today = new Date();
  const [_value, setValue] = useState(null);
  const [_selectedDay, setSelectedDay] = useState(null);
  const [month, setMonth] = useState(today);
  const [wasValidated, setWasValidated] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const pickerRef = useRef(null);

  const handleFocus = () => {
    setWasValidated(true);
    if (pickerRef.current !== null) {
      pickerRef.current.value.length <= 0
        ? setIsValid(false)
        : setIsValid(pickerRef.current.checkValidity());
    } else {
      setIsValid(false);
    }
  };

  const handleChange = (data) => {
    if (data !== undefined) {
      const date = YYYYMMDD(data);
      setValue(date);
      handleFocus();
      onChange(date);
    } else {
      setValue(null);
      onChange(null);
    }
  };

  const handleYearMonthChange = (month) => {
    setMonth(month);
  };

  useEffect(() => {
    if (value !== undefined && value !== null) {
      setValue(DDMMYYYY(value));
      setSelectedDay(new Date(value));
      setMonth();
    }
  }, [value]);

  function YearMonthForm({ date, months, onChange }) {
    const years = [];
    let year = date.getFullYear();
    let month = date.getMonth();
    for (let i = 1850; i <= 2030; i += 1) {
      years.push(i);
    }

    const handleChange = function handleChange(e) {
      if (e.target.name === "month") {
        month = e.target.value;
      } else {
        year = e.target.value;
      }
      onChange(new Date(year, month));
    };

    return (
      <div className="DayPicker-Caption">
        <select name="month" onChange={handleChange} value={date.getMonth()}>
          {months &&
            months.map((month, i) => (
              <option key={month} value={i}>
                {month}
              </option>
            ))}
        </select>
        <select name="year" onChange={handleChange} value={date.getFullYear()}>
          {years &&
            years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
        </select>
      </div>
    );
  }

  return (
    <div
      className={Classnames([
        (required || needsValidation) && "needs-validation",
        (required || needsValidation) && wasValidated && "was-validated",
        containerClasses,
      ])}
    >
      {labelText && (
        <label
          htmlFor={id}
          className={Classnames(["form-label", isSmall && "form-label-sm"])}
        >
          {labelText} {required && <span>(*)</span>}
        </label>
      )}
      <div className="input-group has-validation">
        <DayPickerInput
          keepFocus={false}
          value={_value}
          format="DD-MM-YYYY"
          formatDate={formatDate}
          parseDate={parseDate}
          placeholder="DD-MM-YYYY"
          onDayChange={(data) => handleChange(data)}
          inputProps={{
            ref: pickerRef,
            className: Classnames([
              "form-control",
              classnames,
              !isValid && "is-invalid",
              isSmall && "form-control-sm",
            ]),
            onFocus: () => handleFocus(),
            pattern: ".{8,10}",
          }}
          dayPickerProps={{
            // selectedDays: _selectedDay,
            month: month,
            toMonth: month,
            onDayClick: (data) => handleChange(data),
            captionElement: ({ date, localeUtils }) => (
              <YearMonthForm
                date={date}
                localeUtils={localeUtils}
                onChange={handleYearMonthChange}
                months={
                  DATE_LOCALE && DATE_LOCALE[language]
                    ? DATE_LOCALE[language].MONTHS
                    : null
                }
              />
            ),
            disabledDays: disabledDays,
            modifiers: modifiers, // disabledDays
            locale: language,
            months:
              DATE_LOCALE && DATE_LOCALE[language]
                ? DATE_LOCALE[language].MONTHS
                : null,
            weekdaysLong:
              DATE_LOCALE && DATE_LOCALE[language]
                ? DATE_LOCALE[language].WEEKDAYS_LONG
                : null,
            weekdaysShort:
              DATE_LOCALE && DATE_LOCALE[language]
                ? DATE_LOCALE[language].WEEKDAYS_SHORT
                : null,
            todayButton: t("date.today"),
            firstDayOfWeek: 1,
          }}
        />
        <div className="invalid-feedback">{errorMessage}</div>
      </div>
    </div>
  );
};

export default withTranslation()(FormDate);

FormDate.defaultProps = {
  needsValidation: false,
};
