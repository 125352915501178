// React & Libs
import { createContext, useState, useContext } from "react";
import useAPI from '../hooks/useAPI';
import { useAppProvider } from "./AppContext";
import { YYYYMMDD, getDateFromXdays } from '../utils/dateUtils';
import useToastCustom from "../hooks/useToastCustom";
import { useTranslation } from "react-i18next";
import "../i18n";
import { orderMedicinesArray, addMedicineFilters } from '../utils/generalUtils';
import { getArraysForProposalsPage } from '../utils/proposalsUtils';
import POSOLOGY_TYPES from "../constants/POSOLOGY_TYPES";
import MEDICINE_FILTERS from "../constants/MEDICINE_FILTERS";

const TreatmentsContext = createContext();

const TreatmentsProvider = props => {
    // Imports
    const { t } = useTranslation();
    const { showSuccessMessage } = useToastCustom();
    const { API, handleReqError } = useAPI();
    const { handleLoading, handleNotLoading } = useAppProvider();

    // Date
    const [ initialDate, setInitialDate ] = useState(new Date());

    // Patient Items
    const [ activePatient, setActivePatient ] = useState(null);
    const [ activePatientMedicines, setActivePatientMedicines ]     = useState(null);
    const [ proposalsPatientMedicines, setProposalsPatientMedicines ] = useState(null);
    const [ filteredActPatMeds, setFilteredActPatMeds ]             = useState(null);
    const [ messages, setMessages ] = useState([]);
    const [ messagesPage, setMessagesPage ] = useState(1);
    const [ displayedPatients, setDisplayedPatients ] = useState(null);
    const [ hospitalSelected, setHospitalSelected ] = useState(null)

    // Posologies
    const [ intakes, setIntakes ]                           = useState(null);
    const [ patterns, setPatterns ]                         = useState(null);
    const [ activeMedicine, setActiveMedicine ]             = useState(null);
    const [ activeBookMedicine, setActiveBookMedicine ]     = useState(null);
    const [ posologyTypeSelected, setPosologyTypeSelected ] = useState(POSOLOGY_TYPES[0]);

    const [ activeMedicineIsProposal, setActiveMedicineIsProposal ] = useState(false);

    const [ currentPosology, setCurrentPosology ]           = useState(null);
    const [ medicinePosologies, setMedicinePosologies ]     = useState(null);
    const [ displayedPosologies, setDispPosologies ]        = useState(null);
    const [ currentPosologyID, setActivePosologyID ]        = useState(null);
    const [ activePosologyWasProduced, setActivePosologyWasProduced ] = useState(false);

    const [ isStoppedMedicine, setIsStoppedMedicine ]       = useState(false);

    const [ medicinesFilters, setMedicinesFilters ]         = useState(MEDICINE_FILTERS);
    const [ editPosologiesIsDisabled, setEditPosologiesIsDisabled ] = useState(false);

    // Modals
    const [ showPosologyModal, setShowPosologyModal ]       = useState(false);
    const [ showMedicinesModal, setShowMedicinesModal ]     = useState(false);
    const [ showPosologiesModal, setShowPosologiesModal ]   = useState(false);
    const [ showStopMedicineModal, setShowStopMedicineModal ]       = useState(false);
    const [ showAlertInvalidation, setShowAlertInvalidation ]       = useState(false);


    // Methods
    const getMedicinesFromPatient = (data) => {
        handleLoading();
        const patient_id = data && data.patient_id ? data.patient_id : activePatient;
        const start_date = data && data.start_date ? data.start_date : initialDate;
        if(data && data.patient_id) { setActivePatient(data.patient_id) };

        const _initialDate = start_date ? start_date : initialDate;
        const _start_date = YYYYMMDD(_initialDate);
        const _end_date = YYYYMMDD(getDateFromXdays(_initialDate, 14));
        if(patient_id === (undefined || null)) { return };
        const _url = `treatments/?patient=${patient_id}&start_date=${_start_date}&end_date=${_end_date}`;
        const url = addMedicineFilters(_url, medicinesFilters);

        API.get(url)
            .then((response) => {
                handleNotLoading();
                if (response) {
                    setActivePatientMedicines(orderMedicinesArray(response.data.medicines));
                }
            })
            .catch((error) => {
                handleNotLoading();
                setActivePatientMedicines([]);
            })
        ;
    };

    const getProposalsAndMedicinesFromPatient = data => {

        handleLoading();

        setActivePatientMedicines(null);
        setProposalsPatientMedicines(null);

        const patient_id = data && data.patient_id ? data.patient_id : activePatient;
        const start_date = data && data.start_date ? data.start_date : initialDate;
        const _initialDate = start_date ? start_date : initialDate;
        const _start_date = YYYYMMDD(_initialDate);
        const _end_date = YYYYMMDD(getDateFromXdays(_initialDate, 14));

        if(patient_id) { setActivePatient(patient_id); getMessages(patient_id) };
        if(patient_id === (undefined || null)) { return };

        const _url = `treatments/?patient=${patient_id}&start_date=${_start_date}&end_date=${_end_date}&include_proposals=true`;
        const url = addMedicineFilters(_url, medicinesFilters);

        API.get(url)
            .then((response) => {
                handleNotLoading();
                if (response) {
                    const result = getArraysForProposalsPage(response.data.medicines);
                    setProposalsPatientMedicines([...result[0]]);
                    setActivePatientMedicines([...result[1]]);
                }
            })
            .catch((error) => {
                handleNotLoading();
                setProposalsPatientMedicines(null);
                setActivePatientMedicines(null);
            })
        ;
    };

    const handleAddMedicine = id => {
        handleLoading();
        const data = {
            book_medicine: id,
            is_proposal: false,
            patient: activePatient,
        };
        API.post("/treatment-medicines/",data)
            .then((response) => {
                if (response) {
                    showSuccessMessage(t('general.changesSavedProperly'));
                    getMedicinesFromPatient();
                }
            })
            .catch((error) => handleReqError(error))
        ;
        handleNotLoading();
        setShowMedicinesModal(false);
    };

    const _getProposalsAndMedicinesFromPatient = data => {
        handleLoading();
        setActivePatientMedicines([]);
        setProposalsPatientMedicines([]);

        const patient_id = data && data.patient_id ? data.patient_id : activePatient;
        const start_date = data && data.start_date ? data.start_date : initialDate;
        const _initialDate = start_date ? start_date : initialDate;
        const _start_date = YYYYMMDD(_initialDate);
        const _end_date = YYYYMMDD(getDateFromXdays(_initialDate, 14));

        if(patient_id) { setActivePatient(patient_id) };
        if(patient_id === (undefined || null)) { return };

        const _url = `treatments/?patient=${patient_id}&start_date=${_start_date}&end_date=${_end_date}&include_proposals=true&exclude_inactive=true&exclude_not_blistable=true`;
        const url = addMedicineFilters(_url, medicinesFilters);

        API.get(url)
            .then((response) => {
                handleNotLoading();
                if (response) {
                    const result = getArraysForProposalsPage(response.data.medicines);
                    setProposalsPatientMedicines([...result[0]]);
                    setActivePatientMedicines([...result[1]]);

                }
            })
            .catch((error) => {
                handleNotLoading();
                setProposalsPatientMedicines(null);
                setActivePatientMedicines(null);
            })
        ;
    };

    const handleAddProposalMedicine = (medicineID, start_date) => {
        handleLoading();
        const data = {
            book_medicine: medicineID ? medicineID : activeMedicine,
            is_proposal: true,
            patient: activePatient,
            start_date: start_date ? start_date : null
        };
        API.post("/treatment-medicines/",data)
            .then( () => {
                showSuccessMessage(t('general.changesSavedProperly'));
                // getMedicinesFromPatient();
                _getProposalsAndMedicinesFromPatient();
                handleNotLoading();
                setShowMedicinesModal(false);
            }
            )
            .catch((error) => {handleReqError(error);setShowMedicinesModal(false);})
        ;
    };

    const callBackOpeningProposalPosology = () => {
        setActivePosologyID(null);
        setActivePosologyWasProduced(false);
        setShowPosologyModal(true);
        setShowPosologiesModal(false);
    }

    const handleOpeningProposalPosology = () => {
        handleAddProposalMedicine(null,callBackOpeningProposalPosology)
    };

    const getMessages = (patient, page=1) => {
        const _patient = patient ? patient : activePatient;
        if(page === 1) { setMessagesPage(1); }
        API.get(`patient-messages/?patient=${_patient}&page=${page}`)
        .then(response => {
            if(response.data.results && response.data.results.length > 0) {
                if(page > 1){
                    setMessages([...messages, ...response.data.results]);
                }else{
                    setMessages([...response.data.results]);
                }

                if(response.data.next){
                    setMessagesPage(page + 1);
                }else{
                    setMessagesPage(0);
                }
            } else {
                setMessagesPage(1);
                setMessages([]);
            }
        })
        .catch(error => setMessagesPage(1));
    };

    const shouldResetProposalsScreen = patients => {
        const index = patients ? patients.findIndex(pat => pat.id === activePatient) : null;
        if(index === -1) {
            setProposalsPatientMedicines(null);
            setActivePatientMedicines(null);
        }
    };

    const handleCleanPosologies =  (_patients) => {
        if( _patients.find(pat => pat.id === activePatient) === undefined) {
            setActivePatient(null);
            setActivePatientMedicines(null);
            setIntakes(null);
        }
    };

    const getPatients = (query,searchText) => {
        setDisplayedPatients([]);
        handleLoading();
        let url = 'patients/';
        const _searchText = searchText === "" ? null : searchText ? searchText : patientsColSearchText ? patientsColSearchText : null;

        if(query !== undefined) {
            url = url.concat(query)
        }
        if(_searchText) {
            url = url.includes('?') ? url.concat(`&text=${_searchText}`) : url.concat(`?text=${_searchText}`);
        }

        API.get(url)
          .then((response) => {
            handleNotLoading();
            if (response) {
                let _patients = response.data;

                handleCleanPosologies([..._patients]);
                setDisplayedPatients([..._patients]);
                shouldResetProposalsScreen([..._patients]);
            }
          })
          .catch((error) => handleReqError(error));
    };

    const updatePatients = (data,searchText) => {
        let query = '';
        if(data['only_with_treatment_proposals'] === true) {
            query = '?only_with_treatment_proposals=true&exclude_inactive=true&page_all=true';
            delete data['only_with_treatment_proposals'];
            const keys = Object.keys(data);
            keys.forEach((key, index) => {
                if(data[key] === false) {
                    query = query.concat(`&exclude_${key}_proposals=true`)
                }
            });
        } else {
            query = '?only_with_treatment_proposals=false&exclude_inactive=true&page_all=true';
        }
        getPatients(query,searchText);
    };

    const proposalsFiltersInitialState = {
        'only_with_treatment_proposals': true,
        'unapplied_pending': true,
        'approved': true,
        'rejected': true,
        'applied_pending': true
    };

    const [ proposalsFiltersValues, setProposalsFiltersValues ] = useState({...proposalsFiltersInitialState});

    const handleProposalsFilter = (e, searchText) => {
        const _proposalsFiltersValues = { ...proposalsFiltersValues };
        let data = {};
        let result = { ..._proposalsFiltersValues};
        if(e) {
            const id = e.target.id;
            const value = e.target.checked;
            data = { [id]: value };
            result = { ..._proposalsFiltersValues,...data };
            const condition =
                result['pending'] === false && result['approved'] === false && result['rejected'] === false; //  && result['applied_but_pending'] === false;
            if(condition && id !== 'only_with_treatment_proposals' ) {
                result['only_with_treatment_proposals'] = false;
            } else if(id === 'only_with_treatment_proposals' && value === true ) {
               result = { ...proposalsFiltersInitialState }
            }
        }
        setProposalsFiltersValues({ ...result });
        updatePatients({...result},searchText);
    };

    const [ shouldShowOnlyProposals, setShouldShowOnlyProposals ] = useState(false);

    const [ patientsColSearchText, setPatientsColSearchText] = useState(null);

    const handleGetPatientsWithSearch = searchText => {
        setPatientsColSearchText(searchText);
        handleProposalsFilter(null,searchText);
    };

    const evaluateError = err => {
        const { request } = err;
        if(request) {
            if(request.response.includes('produced posology conflicts') === true) {
                handleNotLoading();
                setShowPosologyModal(false);
                setProducedModalMessage(true);
                setShowAlertInvalidation(true);
            } else if (request.response.includes('it is already applied') === true) {
                handleNotLoading();
                setShowPosologyModal(false);
                setProducedModalMessage(false);
                setShowAlertInvalidation(true);
            } else {
                handleReqError(err)
            };
        } else {
            handleReqError(err)
        };
    };

    const [ messageIsModify, setProducedModalMessage ] = useState(true);


    const TreatmentsContextValue = {
        intakes,
        setIntakes,
        initialDate,
        activePatient,
        medicinePosologies,
        setInitialDate,
        setActivePatient,
        setMedicinePosologies,
        handleAddMedicine,
        showMedicinesModal,
        showPosologyModal,
        setShowMedicinesModal,
        activePatientMedicines,
        setShowPosologyModal,
        setActivePatientMedicines,
        showPosologiesModal,
        setShowPosologiesModal,
        currentPosologyID,
        setActivePosologyID,
        getMedicinesFromPatient,
        currentPosology,
        setCurrentPosology,
        patterns,
        setPatterns,
        activeMedicine,
        setActiveMedicine,
        activePosologyWasProduced,
        setActivePosologyWasProduced,
        displayedPosologies,
        setDispPosologies,
        handleAddProposalMedicine,
        handleOpeningProposalPosology,
        activeMedicineIsProposal,
        setActiveMedicineIsProposal,
        proposalsPatientMedicines,
        setProposalsPatientMedicines,
        getProposalsAndMedicinesFromPatient,
        posologyTypeSelected,
        setPosologyTypeSelected,
        activeBookMedicine,
        setActiveBookMedicine,
        showStopMedicineModal,
        setShowStopMedicineModal,
        _getProposalsAndMedicinesFromPatient,
        isStoppedMedicine,
        setIsStoppedMedicine,
        messages,
        setMessages,
        messagesPage,
        setMessagesPage,
        getMessages,
        medicinesFilters,
        setMedicinesFilters,
        filteredActPatMeds,
        setFilteredActPatMeds,
        editPosologiesIsDisabled,
        setEditPosologiesIsDisabled,
        displayedPatients,
        setDisplayedPatients,
        hospitalSelected,
        setHospitalSelected,
        handleProposalsFilter,
        proposalsFiltersValues,
        setProposalsFiltersValues,
        handleGetPatientsWithSearch,
        shouldShowOnlyProposals,
        setShouldShowOnlyProposals,
        patientsColSearchText,
        setPatientsColSearchText,
        showAlertInvalidation,
        setShowAlertInvalidation,
        evaluateError,
        messageIsModify,
        setProducedModalMessage
    };

    return (
        <TreatmentsContext.Provider
            value={TreatmentsContextValue}
            {...props}
        />
    );
};

const useTreatmentsProvider = () => useContext(TreatmentsContext);

export { TreatmentsProvider, useTreatmentsProvider}