// React
import { useEffect, useState } from 'react'; // useEffect,

// Libs
import { withTranslation } from "react-i18next";
import { nanoid } from 'nanoid'
import useAPI from '../../../hooks/useAPI';

// Components
import Pagination from '../../UI/Pagination';
import SearchBar from "../../UI/SearchBar";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import LoadingSpinner from '../../UI/LoadingSpinner';

const ModalBookMedicines = ({ 
    t,
    show,
    onChange,
    handleClose,
}) => {

    const { API, handleReqError } = useAPI();

    const [ medicines, setMedicines ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ searchString, setSearchString ] = useState(null);

    // Pagination
    const [ totalPages, setTotalPages ] = useState(0);
    const [ currentPage, setCurrentPage ] = useState(1);

    const handleOnSearch = (string) => {
        const _string = string ? string : '';
        if(string && string !== '') {
            API.get(`medicines/?text=${_string}&page=${currentPage}`)
            .then((response) => {
                if (response) {
                    setIsLoading(false);
                    setTotalPages(response.num_pages);
                    setMedicines([...response.data.results]);
                }
            })
            .catch((error) => handleReqError(error));
        } else {
            setIsLoading(false);
            setMedicines(null);
        }
    };

    useEffect(() => {
        handleOnSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    useEffect(() => {
        setIsLoading(true);

        const delayDebounceFn = setTimeout(() => {
            handleOnSearch(searchString);
        }, 300);

        return () => {
            clearTimeout(delayDebounceFn);
            setMedicines(null);
            setIsLoading(false);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ searchString ]);

    return (
        <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {t('pages.bookMedicines.texts.addMedicine')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <>
                    <SearchBar
                        className="mb-2"
                        onChange={str => setSearchString(str)}
                    />
                    { isLoading 
                    ? 
                    <LoadingSpinner />
                    :
                     medicines && medicines.length > 0 ?
                    
                    <>
                    <ul className="list-group mt-2 rounded-0">
                        { (medicines.map(medicine => (
                        <li className="list-group-item" key={nanoid()}>
                            <div
                                className="p-0 text-start cursor-pointer"
                                onClick={() => onChange(medicine.id) }
                            >
                                <p className="mb-0">{medicine.name}</p>
                                <p className="mb-0"><small><small><b>{t('pages.bookMedicines.fields.nationalCode')}: </b> <span>{medicine.national_code}</span></small></small></p>
                            </div>
                        </li>
                        )))}
                    </ul>

                    { totalPages > 1 && 
                        <Pagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            setCurrentPage={(pageNumber) => setCurrentPage(pageNumber)}
                        />
                    }

                    </>

                    : medicines ?

                        <div className="mt-2">
                            <p>{t('general.noResults')}</p>
                        </div>

                    : null
                }
                </>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={() => handleClose()}>
                {t('general.close')}
            </Button>
            </Modal.Footer>
        </Modal>
    );
}
 
export default withTranslation()(ModalBookMedicines);