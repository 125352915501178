// Components
import Modal from "react-bootstrap/Modal";

// Libs
import { withTranslation } from "react-i18next";


const ModalProductionDetail = ({
    t,
    show,
    handleClose,
    production,
}) => {

    return (
        <Modal show={show} onHide={handleClose} size={'l'} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{t('pages.productions.texts.productionDetails')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">{t("pages.productions.fields.patientName")}</th>
                  </tr>
                </thead>
                <tbody>
                  {production?.patients?.map((patient) => (
                    <tr key={patient.id}>
                        <td>{patient.name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Modal.Body>
        </Modal>
    );
}

export default withTranslation()(ModalProductionDetail);
