import Button from '../../../../../components/UI/Button';
import { useAppProvider } from '../../../../../context/AppContext';

const MessagesLogsCTA = () => {
    const { messagesVisible, setMessagesVisible } = useAppProvider();

    return (
        <Button
        //     badge={
        //         <span
        //             className="badge bg-secondary"
        //             style={{
        //                 position: 'absolute',
        //                 top: '-12px'
        //             }}
        //         >4</span>
        //     }
            iconLabel={'question_answer'}
            stylingClass={'btn-warning ms-auto position-relative'}
            onClick={() => !messagesVisible ? setMessagesVisible(true) : setMessagesVisible(false) }
        />
    );
}
 
export default MessagesLogsCTA;