import Classnames from 'classnames';
import { isOdd } from '../../../../../utils/generalUtils';

// Translation Constants
import { useTranslation } from 'react-i18next';
import DATE_LOCALE, { LOCALE } from '../../../../../constants/DATE_LOCALE';

const DayHeader = ({date,index}) => {
    
    const { i18n } = useTranslation();
    const { language } = i18n;

    let currentLanguage = language;

    const locale = LOCALE[currentLanguage];

    const DAY_NAMES = DATE_LOCALE[currentLanguage]["WEEKDAYS_LONG"];

    const dayNumber = date.getDate();
    const month = date.toLocaleString(locale, { month: 'long' });
    const dayName = DAY_NAMES[date.getDay()];

    const isToday = new Date().setHours(0,0,0,0) === new Date(date).setHours(0,0,0,0);

    return (
        <div
            className={Classnames([
                "DayHeader border-bottom border-end flex-fill text-center",
                isToday && 'bg-primary text-white',
                isOdd(index) && !isToday && 'bg-light',
                !isOdd(index) && !isToday && 'bg-white',
            ])}
        >
            <h5 className="mb-0">{dayNumber}</h5>
            <h6 className="mb-0">
                <p className="mb-0 text-capitalize"><small>{dayName}</small></p>
                <p className="mb-0 text-capitalize fst-italic"><small><small>{month}</small></small></p>
            </h6>

        </div>
    );
}
 
export default DayHeader;