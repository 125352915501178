import PatientsColTreatments from '../../layout/PatientsColTreatments';
import TreamentsHeader from '../../layout/TreatmentsHeader';
import TreatmentsGrid from '../../layout/TreatmentsGrid';

// Context & Provider
import { useTreatmentsProvider } from '../../../../../context/TreatmentsContext';

// Modals
import ModalMedicines from '../../Modals/ModalMedicines';
import ModalPosologies from '../../Modals/ModalPosologies';
import ModalPosologiesSummary from '../../Modals/ModalPosologiesSummary';
import ModalStopMedicine from '../../Modals/ModalStopMedicine';
import ModalAlertInvalidAction from '../../Modals/ModalAlertInvalidAction';

// Styles
import './styles.scss';

const TreatmentsPageContent = () => {
    const {
        showPosologyModal,
        showMedicinesModal,
        showPosologiesModal,
        setShowPosologyModal,
        showAlertInvalidation,
        showStopMedicineModal,
        setShowMedicinesModal,
        setShowPosologiesModal,
        setShowStopMedicineModal,
        setShowAlertInvalidation,
        handleAddProposalMedicine
    } = useTreatmentsProvider();

    return (
        <div className="ContentWrapper container-fluid">
            <div className="Treatments__PatientsContainer d-inline-block align-top">
                <PatientsColTreatments />
            </div>
            <div className="Treatments__PosologiesContainer d-inline-block align-top">
                <div
                    className="scrollerHor"
                    style={{
                        overflow: 'scroll',
                        minHeight: '480px'
                    }}
                >
                    <TreamentsHeader />
                    <TreatmentsGrid />
                </div>
            </div>

            {/* Modals */}
            <ModalMedicines
                show={showMedicinesModal}
                handleClose={() => setShowMedicinesModal(false)}
                onChange={medicine_id => handleAddProposalMedicine(medicine_id)}
            />

            <ModalPosologies
                show={showPosologyModal}
                handleClose={() => setShowPosologyModal(false)}
            />

            <ModalPosologiesSummary
                show={showPosologiesModal}
                handleClose={() => setShowPosologiesModal(false)}
            />

            <ModalStopMedicine
                show={showStopMedicineModal}
                handleClose={() => setShowStopMedicineModal(false)}
            />

            <ModalAlertInvalidAction
                show={showAlertInvalidation}
                handleClose={() => setShowAlertInvalidation(false)}
            />
        </div>
    );
}

export default TreatmentsPageContent;