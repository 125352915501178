// React
import { useState, useEffect } from 'react';

//Components
import ButtonAdder from './ButtonAdder';
import ButtonMultipleAdder from './ButtonMultipleAdder';

// Libs
import { withTranslation } from 'react-i18next';


const TableWeekly = ({
    t,
    patterns,
    is6Doses,
    handleMultipleAdd,
    handlePatternsChange,
    disabled
}) => {

    const [ isLoaded, setIsLoaded ] = useState(false);

    const handleKeyPress = e => {
        if(e.code === 'Enter') {
            e.preventDefault();
        }
    };

    useEffect(() => {
        setIsLoaded(true);
        return function cleanUp() {
            setIsLoaded(false);
        }
    }, [patterns]);

    return (
        <>
        {
            isLoaded ? (
                <>
                    <table className="table table-layout-fixed align-middle" onContextMenu={e => e.preventDefault()}>
                        <thead>
                            <tr>
                                <th scope="col">{t('general.hours')}</th>
                                <th scope="col">
                                    <ButtonMultipleAdder disabled={disabled} handleMultipleAdd={handleMultipleAdd} type={'weekdays.monday'} is6Doses={is6Doses}>{t('date.weekdays.monday')}</ButtonMultipleAdder>
                                </th>
                                <th scope="col">
                                    <ButtonMultipleAdder disabled={disabled} handleMultipleAdd={handleMultipleAdd} type={'weekdays.tuesday'} is6Doses={is6Doses}>{t('date.weekdays.tuesday')}</ButtonMultipleAdder>
                                </th>
                                <th scope="col">
                                    <ButtonMultipleAdder disabled={disabled} handleMultipleAdd={handleMultipleAdd} type={'weekdays.wednesday'} is6Doses={is6Doses}>{t('date.weekdays.wednesday')}</ButtonMultipleAdder>
                                </th>
                                <th scope="col">
                                    <ButtonMultipleAdder disabled={disabled} handleMultipleAdd={handleMultipleAdd} type={'weekdays.thursday'} is6Doses={is6Doses}>{t('date.weekdays.thursday')}</ButtonMultipleAdder>
                                </th>
                                <th scope="col">
                                    <ButtonMultipleAdder disabled={disabled} handleMultipleAdd={handleMultipleAdd} type={'weekdays.friday'} is6Doses={is6Doses}>{t('date.weekdays.friday')}</ButtonMultipleAdder>
                                </th>
                                <th scope="col">
                                    <ButtonMultipleAdder disabled={disabled} handleMultipleAdd={handleMultipleAdd} type={'weekdays.saturday'} is6Doses={is6Doses}>{t('date.weekdays.saturday')}</ButtonMultipleAdder>
                                </th>
                                <th scope="col">
                                    <ButtonMultipleAdder disabled={disabled} handleMultipleAdd={handleMultipleAdd} type={'weekdays.sunday'} is6Doses={is6Doses}>{t('date.weekdays.sunday')}</ButtonMultipleAdder>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            is6Doses && (
                                <tr>
                                    <td><ButtonMultipleAdder disabled={disabled} handleMultipleAdd={handleMultipleAdd} type={'fast'} onKeyPress={e => handleKeyPress(e)}>{t('pages.planning.texts.fast')}</ButtonMultipleAdder></td>
                                    <td><ButtonAdder disabled={disabled} id={'fast.monday'}    value={ patterns && patterns['fast'] && patterns['fast']['monday']     } addStep={ e => handlePatternsChange(e,true,'fast.monday') }       removeStep={ e => handlePatternsChange(e,false,'fast.monday') }       onKeyPress={e => handleKeyPress(e)}/></td>
                                    <td><ButtonAdder disabled={disabled} id={'fast.tuesday'}   value={ patterns && patterns['fast'] && patterns['fast']['tuesday']     } addStep={ e => handlePatternsChange(e,true,'fast.tuesday') }      removeStep={ e => handlePatternsChange(e,false,'fast.tuesday') }     onKeyPress={e => handleKeyPress(e)}/></td>
                                    <td><ButtonAdder disabled={disabled} id={'fast.wednesday'} value={ patterns && patterns['fast'] && patterns['fast']['wednesday']     } addStep={ e => handlePatternsChange(e,true,'fast.wednesday') }    removeStep={ e => handlePatternsChange(e,false,'fast.wednesday') } onKeyPress={e => handleKeyPress(e)}/></td>
                                    <td><ButtonAdder disabled={disabled} id={'fast.thursday'}  value={ patterns && patterns['fast'] && patterns['fast']['thursday']     } addStep={ e => handlePatternsChange(e,true,'fast.thursday') }     removeStep={ e => handlePatternsChange(e,false,'fast.thursday') }   onKeyPress={e => handleKeyPress(e)}/></td>
                                    <td><ButtonAdder disabled={disabled} id={'fast.friday'}    value={ patterns && patterns['fast'] && patterns['fast']['friday']     } addStep={ e => handlePatternsChange(e,true,'fast.friday') }       removeStep={ e => handlePatternsChange(e,false,'fast.friday') }       onKeyPress={e => handleKeyPress(e)}/></td>
                                    <td><ButtonAdder disabled={disabled} id={'fast.saturday'}  value={ patterns && patterns['fast'] && patterns['fast']['saturday']     } addStep={ e => handlePatternsChange(e,true,'fast.saturday') }     removeStep={ e => handlePatternsChange(e,false,'fast.saturday') }   onKeyPress={e => handleKeyPress(e)}/></td>
                                    <td><ButtonAdder disabled={disabled} id={'fast.sunday'}    value={ patterns && patterns['fast'] && patterns['fast']['sunday']    } addStep={ e => handlePatternsChange(e,true,'fast.sunday') }       removeStep={ e => handlePatternsChange(e,false,'fast.sunday') }        onKeyPress={e => handleKeyPress(e)}/></td>
                                </tr>
                            )
                        }

                        <tr>
                            <td><ButtonMultipleAdder disabled={disabled} handleMultipleAdd={handleMultipleAdd} type={'breakfast'} onKeyPress={e => handleKeyPress(e)}>{t('pages.planning.texts.breakfast')}</ButtonMultipleAdder></td>
                            <td><ButtonAdder disabled={disabled} id={'breakfast.monday'}    value={ patterns && patterns['breakfast'] && patterns['breakfast']['monday']     }      addStep={ e => handlePatternsChange(e,true,'breakfast.monday') }       removeStep={ e => handlePatternsChange(e,false,'breakfast.monday') }     onKeyPress={e => handleKeyPress(e)}/></td>
                            <td><ButtonAdder disabled={disabled} id={'breakfast.tuesday'}   value={ patterns && patterns['breakfast'] && patterns['breakfast']['tuesday']     }     addStep={ e => handlePatternsChange(e,true,'breakfast.tuesday') }      removeStep={ e => handlePatternsChange(e,false,'breakfast.tuesday') }    onKeyPress={e => handleKeyPress(e)}/></td>
                            <td><ButtonAdder disabled={disabled} id={'breakfast.wednesday'} value={ patterns && patterns['breakfast'] && patterns['breakfast']['wednesday']     }   addStep={ e => handlePatternsChange(e,true,'breakfast.wednesday') }    removeStep={ e => handlePatternsChange(e,false,'breakfast.wednesday') }  onKeyPress={e => handleKeyPress(e)}/></td>
                            <td><ButtonAdder disabled={disabled} id={'breakfast.thursday'}  value={ patterns && patterns['breakfast'] && patterns['breakfast']['thursday']     }    addStep={ e => handlePatternsChange(e,true,'breakfast.thursday') }     removeStep={ e => handlePatternsChange(e,false,'breakfast.thursday') }   onKeyPress={e => handleKeyPress(e)}/></td>
                            <td><ButtonAdder disabled={disabled} id={'breakfast.friday'}    value={ patterns && patterns['breakfast'] && patterns['breakfast']['friday']     }      addStep={ e => handlePatternsChange(e,true,'breakfast.friday') }       removeStep={ e => handlePatternsChange(e,false,'breakfast.friday') }     onKeyPress={e => handleKeyPress(e)}/></td>
                            <td><ButtonAdder disabled={disabled} id={'breakfast.saturday'}  value={ patterns && patterns['breakfast'] && patterns['breakfast']['saturday']     }    addStep={ e => handlePatternsChange(e,true,'breakfast.saturday') }     removeStep={ e => handlePatternsChange(e,false,'breakfast.saturday') }   onKeyPress={e => handleKeyPress(e)}/></td>
                            <td><ButtonAdder disabled={disabled} id={'breakfast.sunday'}    value={ patterns && patterns['breakfast'] && patterns['breakfast']['sunday']     }      addStep={ e => handlePatternsChange(e,true,'breakfast.sunday') }       removeStep={ e => handlePatternsChange(e,false,'breakfast.sunday') }     onKeyPress={e => handleKeyPress(e)}/></td>
                        </tr>
                        <tr>
                            <td><ButtonMultipleAdder disabled={disabled} handleMultipleAdd={handleMultipleAdd} type={'lunch'} onKeyPress={e => handleKeyPress(e)}>{t('pages.planning.texts.lunch')}</ButtonMultipleAdder></td>
                            <td><ButtonAdder disabled={disabled} id={'lunch.monday'}    value={ patterns && patterns['lunch'] && patterns['lunch']['monday']     } addStep={ e => handlePatternsChange(e,true,'lunch.monday') }       removeStep={ e => handlePatternsChange(e,false,'lunch.monday') }          onKeyPress={e => handleKeyPress(e)}/></td>
                            <td><ButtonAdder disabled={disabled} id={'lunch.tuesday'}   value={ patterns && patterns['lunch'] && patterns['lunch']['tuesday']     } addStep={ e => handlePatternsChange(e,true,'lunch.tuesday') }      removeStep={ e => handlePatternsChange(e,false,'lunch.tuesday') }        onKeyPress={e => handleKeyPress(e)}/></td>
                            <td><ButtonAdder disabled={disabled} id={'lunch.wednesday'} value={ patterns && patterns['lunch'] && patterns['lunch']['wednesday']     } addStep={ e => handlePatternsChange(e,true,'lunch.wednesday') }    removeStep={ e => handlePatternsChange(e,false,'lunch.wednesday') }    onKeyPress={e => handleKeyPress(e)}/></td>
                            <td><ButtonAdder disabled={disabled} id={'lunch.thursday'}  value={ patterns && patterns['lunch'] && patterns['lunch']['thursday']     } addStep={ e => handlePatternsChange(e,true,'lunch.thursday') }     removeStep={ e => handlePatternsChange(e,false,'lunch.thursday') }      onKeyPress={e => handleKeyPress(e)}/></td>
                            <td><ButtonAdder disabled={disabled} id={'lunch.friday'}    value={ patterns && patterns['lunch'] && patterns['lunch']['friday']     } addStep={ e => handlePatternsChange(e,true,'lunch.friday') }       removeStep={ e => handlePatternsChange(e,false,'lunch.friday') }          onKeyPress={e => handleKeyPress(e)}/></td>
                            <td><ButtonAdder disabled={disabled} id={'lunch.saturday'}  value={ patterns && patterns['lunch'] && patterns['lunch']['saturday']     } addStep={ e => handlePatternsChange(e,true,'lunch.saturday') }     removeStep={ e => handlePatternsChange(e,false,'lunch.saturday') }      onKeyPress={e => handleKeyPress(e)}/></td>
                            <td><ButtonAdder disabled={disabled} id={'lunch.sunday'}    value={ patterns && patterns['lunch'] && patterns['lunch']['sunday']     } addStep={ e => handlePatternsChange(e,true,'lunch.sunday') }       removeStep={ e => handlePatternsChange(e,false,'lunch.sunday') }          onKeyPress={e => handleKeyPress(e)}/></td>
                        </tr>
                        {
                            is6Doses && (
                                <tr>
                                    <td><ButtonMultipleAdder disabled={disabled} handleMultipleAdd={handleMultipleAdd} type={'snack'} onKeyPress={e => handleKeyPress(e)}>{t('pages.planning.texts.snack')}</ButtonMultipleAdder></td>
                                    <td><ButtonAdder disabled={disabled} id={'snack.monday'}    value={ patterns && patterns['snack'] && patterns['snack']['monday']     } addStep={ e => handlePatternsChange(e,true,'snack.monday') }       removeStep={ e => handlePatternsChange(e,false,'snack.monday') }          onKeyPress={e => handleKeyPress(e)}/></td>
                                    <td><ButtonAdder disabled={disabled} id={'snack.tuesday'}   value={ patterns && patterns['snack'] && patterns['snack']['tuesday']     } addStep={ e => handlePatternsChange(e,true,'snack.tuesday') }      removeStep={ e => handlePatternsChange(e,false,'snack.tuesday') }        onKeyPress={e => handleKeyPress(e)}/></td>
                                    <td><ButtonAdder disabled={disabled} id={'snack.wednesday'} value={ patterns && patterns['snack'] && patterns['snack']['wednesday']     } addStep={ e => handlePatternsChange(e,true,'snack.wednesday') }    removeStep={ e => handlePatternsChange(e,false,'snack.wednesday') }    onKeyPress={e => handleKeyPress(e)}/></td>
                                    <td><ButtonAdder disabled={disabled} id={'snack.thursday'}  value={ patterns && patterns['snack'] && patterns['snack']['thursday']     } addStep={ e => handlePatternsChange(e,true,'snack.thursday') }     removeStep={ e => handlePatternsChange(e,false,'snack.thursday') }      onKeyPress={e => handleKeyPress(e)}/></td>
                                    <td><ButtonAdder disabled={disabled} id={'snack.friday'}    value={ patterns && patterns['snack'] && patterns['snack']['friday']     } addStep={ e => handlePatternsChange(e,true,'snack.friday') }       removeStep={ e => handlePatternsChange(e,false,'snack.friday') }          onKeyPress={e => handleKeyPress(e)}/></td>
                                    <td><ButtonAdder disabled={disabled} id={'snack.saturday'}  value={ patterns && patterns['snack'] && patterns['snack']['saturday']     } addStep={ e => handlePatternsChange(e,true,'snack.saturday') }     removeStep={ e => handlePatternsChange(e,false,'snack.saturday') }      onKeyPress={e => handleKeyPress(e)}/></td>
                                    <td><ButtonAdder disabled={disabled} id={'snack.sunday'}    value={ patterns && patterns['snack'] && patterns['snack']['sunday']    } addStep={ e => handlePatternsChange(e,true,'snack.sunday') }       removeStep={ e => handlePatternsChange(e,false,'snack.sunday') }           onKeyPress={e => handleKeyPress(e)}/></td>
                                </tr>
                            )
                        }
                        <tr>
                            <td><ButtonMultipleAdder disabled={disabled} handleMultipleAdd={handleMultipleAdd} type={'dinner'} onKeyPress={e => handleKeyPress(e)}>{t('pages.planning.texts.dinner')}</ButtonMultipleAdder></td>
                            <td><ButtonAdder disabled={disabled} id={'dinner.monday'}       value={ patterns && patterns['dinner'] && patterns['dinner']['monday']    } addStep={ e => handlePatternsChange(e,true,'dinner.monday') }     removeStep={ e => handlePatternsChange(e,false,'dinner.monday') }     onKeyPress={e => handleKeyPress(e)}/></td>
                            <td><ButtonAdder disabled={disabled} id={'dinner.tuesday'}      value={ patterns && patterns['dinner'] && patterns['dinner']['tuesday']   } addStep={ e => handlePatternsChange(e,true,'dinner.tuesday') }    removeStep={ e => handlePatternsChange(e,false,'dinner.tuesday') }    onKeyPress={e => handleKeyPress(e)}/></td>
                            <td><ButtonAdder disabled={disabled} id={'dinner.wednesday'}    value={ patterns && patterns['dinner'] && patterns['dinner']['wednesday'] } addStep={ e => handlePatternsChange(e,true,'dinner.wednesday') }  removeStep={ e => handlePatternsChange(e,false,'dinner.wednesday') }  onKeyPress={e => handleKeyPress(e)}/></td>
                            <td><ButtonAdder disabled={disabled} id={'dinner.thursday'}     value={ patterns && patterns['dinner'] && patterns['dinner']['thursday']  } addStep={ e => handlePatternsChange(e,true,'dinner.thursday') }   removeStep={ e => handlePatternsChange(e,false,'dinner.thursday') }   onKeyPress={e => handleKeyPress(e)}/></td>
                            <td><ButtonAdder disabled={disabled} id={'dinner.friday'}       value={ patterns && patterns['dinner'] && patterns['dinner']['friday']    } addStep={ e => handlePatternsChange(e,true,'dinner.friday') }     removeStep={ e => handlePatternsChange(e,false,'dinner.friday') }     onKeyPress={e => handleKeyPress(e)}/></td>
                            <td><ButtonAdder disabled={disabled} id={'dinner.saturday'}     value={ patterns && patterns['dinner'] && patterns['dinner']['saturday']  } addStep={ e => handlePatternsChange(e,true,'dinner.saturday') }   removeStep={ e => handlePatternsChange(e,false,'dinner.saturday') }   onKeyPress={e => handleKeyPress(e)}/></td>
                            <td><ButtonAdder disabled={disabled} id={'dinner.sunday'}       value={ patterns && patterns['dinner'] && patterns['dinner']['sunday']    } addStep={ e => handlePatternsChange(e,true,'dinner.sunday') }     removeStep={ e => handlePatternsChange(e,false,'dinner.sunday') }     onKeyPress={e => handleKeyPress(e)}/></td>
                        </tr>
                        <tr>
                            <td><ButtonMultipleAdder disabled={disabled} handleMultipleAdd={handleMultipleAdd} type={'night'} onKeyPress={e => handleKeyPress(e)}>{t('pages.planning.texts.night')}</ButtonMultipleAdder></td>
                            <td><ButtonAdder disabled={disabled} id={'night.monday'}    value={ patterns && patterns['night'] && patterns['night']['monday']    } addStep={ e => handlePatternsChange(e,true,'night.monday') } removeStep={ e => handlePatternsChange(e,false,'night.monday') }         onKeyPress={e => handleKeyPress(e)}/></td>
                            <td><ButtonAdder disabled={disabled} id={'night.tuesday'}   value={ patterns && patterns['night'] && patterns['night']['tuesday']   } addStep={ e => handlePatternsChange(e,true,'night.tuesday') } removeStep={ e => handlePatternsChange(e,false,'night.tuesday') }       onKeyPress={e => handleKeyPress(e)}/></td>
                            <td><ButtonAdder disabled={disabled} id={'night.wednesday'} value={ patterns && patterns['night'] && patterns['night']['wednesday'] } addStep={ e => handlePatternsChange(e,true,'night.wednesday') } removeStep={ e => handlePatternsChange(e,false,'night.wednesday') }   onKeyPress={e => handleKeyPress(e)}/></td>
                            <td><ButtonAdder disabled={disabled} id={'night.thursday'}  value={ patterns && patterns['night'] && patterns['night']['thursday']  } addStep={ e => handlePatternsChange(e,true,'night.thursday') } removeStep={ e => handlePatternsChange(e,false,'night.thursday') }     onKeyPress={e => handleKeyPress(e)}/></td>
                            <td><ButtonAdder disabled={disabled} id={'night.friday'}    value={ patterns && patterns['night'] && patterns['night']['friday']    } addStep={ e => handlePatternsChange(e,true,'night.friday') } removeStep={ e => handlePatternsChange(e,false,'night.friday') }         onKeyPress={e => handleKeyPress(e)}/></td>
                            <td><ButtonAdder disabled={disabled} id={'night.saturday'}  value={ patterns && patterns['night'] && patterns['night']['saturday']  } addStep={ e => handlePatternsChange(e,true,'night.saturday') } removeStep={ e => handlePatternsChange(e,false,'night.saturday') }     onKeyPress={e => handleKeyPress(e)}/></td>
                            <td><ButtonAdder disabled={disabled} id={'night.sunday'}    value={ patterns && patterns['night'] && patterns['night']['sunday']    } addStep={ e => handlePatternsChange(e,true,'night.sunday') } removeStep={ e => handlePatternsChange(e,false,'night.sunday') }         onKeyPress={e => handleKeyPress(e)}/></td>
                        </tr>
                    </tbody>
                    </table>
                    { !disabled && <p><small>(*) {t('pages.planning.texts.clicks')}</small></p> }
                </>
            ) : (
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only"></span>
                </div>
            )
        }
        </>
    );
}

export default withTranslation()(TableWeekly);