import { useEffect, useState, useRef } from 'react';

import Select from '../../../../../components/UI/Select';
import List from '../../../../../components/UI/List';
import useAPI from '../../../../../hooks/useAPI';
import { useTreatmentsProvider } from '../../../../../context/TreatmentsContext';
import { withTranslation } from 'react-i18next';
import { useAppProvider } from '../../../../../context/AppContext';

const PatientsColTreatments = ({ t }) => {
    const [ displayedPatients, setDisplayedPatients ]   = useState(null);

    const [ hospitals, setHospitals ]               = useState([]);
    const [ hospitalSelected, setHospitalSelected ] = useState('all');

    const {
        setIntakes,
        activePatient,
        setActivePatient,
        getMedicinesFromPatient,
        setActivePatientMedicines
    }  = useTreatmentsProvider();

    const { API, handleReqError } = useAPI();

    const { handleLoading, handleNotLoading } = useAppProvider();

    const searchRef = useRef();

    const handleCleanPosologies =  (_patients) => {
        if( _patients.find(pat => pat.id === activePatient) === undefined) {
            setActivePatient(null);
            setActivePatientMedicines(null);
            setIntakes(null);
        }
    };

    const getPatients = (searchText,hospital) => {
        handleLoading();
        const url = searchText
          ? `patients/?text=${searchText}&page_all=true&exclude_inactive=true`
          : 'patients/?page_all=true&exclude_inactive=true';
        API.get(url)
          .then((response) => {
            handleNotLoading();
            if (response) {
                let _patients = response.data;

                const _hospital = hospital ? hospital : hospitalSelected;
                _patients = _hospital !== 'all'
                ?  _patients.filter(pat => pat.hospital.id === _hospital)
                : _patients;
                handleCleanPosologies(_patients);
                setDisplayedPatients(_patients);
            }
          })
          .catch((error) => handleReqError(error));
    };

    const filterByHospital = hospital => {
        if(hospital === 'all') {
            getPatients(searchRef.current.value, 'all');
        } else {
            getPatients(searchRef.current.value, hospital);
        }
    }

    const getHospitals = () => {
        handleLoading();
        API.get('hospitals/')
            .then((response) => {
                handleNotLoading();
                if (response) {
                    let _hospitals = response.data.results;
                    const _hospitalsMap = _hospitals.map(hospital => (
                        {
                            value: hospital.id,
                            name: hospital.name
                        }
                    ));
                    setHospitals(
                        [ { value: 'all', name: t('general.all') }, ..._hospitalsMap ]
                    );
                }
            })
            .catch((error) => handleReqError(error))
        ;
    };

    const handleHospitalChange = e => {
        handleLoading();
        setHospitalSelected(e.target.value);
        filterByHospital(e.target.value);
        handleNotLoading();
    }

    // On Init ...
    useEffect(() => {
        getHospitals();
        getPatients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <div className="PatientsColTreatments py-2 pe-2 border-end">
            <div className="PatientsColTreatments__Selects mb-3">
                <Select
                    options={hospitals}
                    hasPlaceholder={false}
                    labelText={t('navigation.hospitals')}
                    containerClassNames={'mb-2'}
                    classnames={'d-inline-block text-truncate'}
                    onChange={e => handleHospitalChange(e)}
                />
            </div>

            <List
                items={displayedPatients}
                labelText={t('navigation.patients')}
                activeID={activePatient}
                className="TreatmentsPatientsList"
                handleSearch={e => getPatients(e.target.value)}
                onClick={(patient) => getMedicinesFromPatient({patient_id: patient.id})}
                searchRef={searchRef}
                hasMoreInfoBtn={true}
            />
        </div>
    );
}

export default withTranslation()(PatientsColTreatments);