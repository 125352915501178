import { nanoid } from "nanoid";
import { useAppProvider } from "../../../../../context/AppContext";
import { useTreatmentsProvider } from "../../../../../context/TreatmentsContext";
import useAPI from '../../../../../hooks/useAPI';
import classNames from 'classnames';

const MedicinesList = () => {
    const { API, handleReqError } = useAPI();
    const { handleLoading, handleNotLoading } = useAppProvider();
    const {
        setActiveMedicine,
        setDispPosologies,
        setMedicinePosologies,
        activePatientMedicines,
        setShowPosologiesModal,
    } = useTreatmentsProvider();

    const handleClickMedicine = (medicine_id) => {
        handleLoading();
        setActiveMedicine(medicine_id);
        API.get(`posologies/?treatment_medicine=${medicine_id}&active=true`)
        .then((response) => {
            handleNotLoading();
            if (response) {
                setMedicinePosologies(response.data.results);
                setDispPosologies(response.data.results);
                setShowPosologiesModal(true);
            }
        })
        .catch((error) => handleReqError(error));
    };

    let counterRow = 2;

    return (
        <>
        {
            activePatientMedicines && activePatientMedicines.map( (medicine) => {
                const rowStart = counterRow;
                const length = medicine.posologies.length ? medicine.posologies.length : 1;
                const rowEnd = rowStart + length;
                counterRow = rowEnd;

                return (
                    <div
                        className="MedicineGridItem cursor-pointer p-1"
                        style={{
                            gridColumn: '1 / 2',
                            gridRow: `${rowStart} / ${rowEnd}`
                        }}
                        key={nanoid()}
                    >
                    <small><small>
                        <div
                            className={classNames([
                                "h-100 rounded p-2 text-break lh-1 border",
                                medicine.is_blistable === false && "bg-orange-light border-orange",
                                medicine.posologies.length === 0 && "bg-light-grey",
                                medicine.is_blistable && medicine.posologies.length > 0 && "bg-white"
                            ])}
                            onClick={() => handleClickMedicine(medicine.treatment_medicine)}
                            title={medicine.name}
                        >{ medicine.name }
                        </div>
                    </small></small>
                    </div>
                )
            }
        )}
        </>
    );
}

export default MedicinesList;