import { nanoid } from 'nanoid';

// Libs
import { withTranslation } from "react-i18next";

const SelectMonthDay = ({ t,value,onChange,key, disabled }) => {
    const monthDays = [];
    for (let index = 1; index < 32; index++) {
        const value =  index < 10 ? `${index}` : index;
        const label =  index;
        monthDays.push({value: value, label: label});
    }

    const handleSelect = e => {
        const day = e.target.value;
        onChange(day);
    }

    return (
        <select
            disabled={disabled}
            className="form-select w-100 h-100 border-primary"
            onChange={ev => handleSelect(ev)}
            value={value}
            style={{textAlignLast: 'center'}}
            key={key}
        >
            { value === undefined &&
                <option
                    key={nanoid()}
                    value={''}
            >{t('general.select')}</option>
            }
            {
                monthDays.map(monthDay => (
                    <option
                        key={nanoid()}
                        value={monthDay.value}
                    >{monthDay.label}</option>
                ))
            }
        </select>
    );
}

export default withTranslation()(SelectMonthDay);