import { useState, useEffect } from 'react';
import { nanoid } from 'nanoid';
import FormInput from '../FormInput';
import './styles.scss';
import { withTranslation } from 'react-i18next';
import Classnames from 'classnames';

const DropdownCountriesWithSearch = ({
    t,
    id,
    value,
    isSmall,
    options,
    onChange,
}) => {

    const [_options, setOptions] = useState([]);
    const [_value, setValue] = useState('');
    const [str, setStr] = useState('');

    const getValue = value => {
        let country;
        country = options.find(op => op['code'] === value.toUpperCase());
        return country ? country['name'] : '';
    };

    const handleFilter = (value) => {
        setStr(value);
        const filteredOptions = options.filter(op => op['name'].toLowerCase().indexOf(value) === 0);
        setOptions(filteredOptions);
    };

    const handleClick = (code) => {
        setValue(getValue(code));
        onChange(code);
    };

    useEffect(() => {
        setOptions(options);
        if(value !== undefined) { setValue(getValue(value)) };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options,value]);


    return (
        <div className="dropdown DropdownCountriesWithSearch">
            <button
                className={Classnames([
                    "form-control dropdown-toggle", isSmall && "form-control-sm"
                ])}
                type="button"
                id="DropdownCountriesWithSearch"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <div
                    className="text-start"
                    style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        paddingRight: '10px',
                    }}
                
                >{_value ? _value : t("general.add") + ' ' + t("pages.hospitals.fields.country")}</div>
            </button>
            <ul 
                className="dropdown-menu p-3" 
                aria-labelledby="DropdownCountriesWithSearch"
                style={{
                    maxHeight: '320px',
                    overflow: 'auto'
                }}
            >
                <li>
                    <FormInput
                        value={str}
                        onChange={ev => handleFilter(ev.target.value)}
                        id={id}
                        type={'text'}
                        placeholder={'Search'}
                        classnames={'mb-4'}
                    />
                </li>
                {   
                    _options && _options[0] && _options[0]['code'] ? _options.map(option => (
                        <li key={nanoid()}>
                            <button
                                className="dropdown-item"
                                selected={ option['code'] === value ? true : false}
                                value={option['code']}
                                onClick={() => handleClick(option["code"])}
                            >{option['name']}</button>
                        </li>
                    )) : <li>{t('general.noResults')}</li>
                }
            </ul>
        </div>
    );
};

export default withTranslation()(DropdownCountriesWithSearch);