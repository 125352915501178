const LANGUAGES = {
    en: 'English',
    es: 'Español',
    // ca: 'Català',
    // fr: 'Français',
    pt: 'Português',
    it: 'Italiano',
    gr: 'Ελληνικά'
};

export default LANGUAGES;