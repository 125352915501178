import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import TablePosologiesList from '../../Table/TablePosologiesList';
import { withTranslation } from "react-i18next";

import './styles.css';
import { useTreatmentsProvider } from "../../../../../context/TreatmentsContext";

const ModalPosologiesSummary = ({
    t,
    show,
    handleClose,
}) => {

    const { activeMedicineIsProposal } = useTreatmentsProvider();

    return (
        <Modal show={show} onHide={handleClose} size={'xl'} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                { activeMedicineIsProposal 
                    ? t('pages.proposals.posololgies_summary_title') 
                    : t('pages.planning.texts.medicinePosologies')
                }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TablePosologiesList />
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={() => handleClose()}>{t('general.close')}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default withTranslation()(ModalPosologiesSummary);