// Components
import UserDropdown from '../../UI/UserDropdown';

// Context
import { useAppProvider } from '../../../context/AppContext';

// CSS
import './styles.scss';
import classNames from 'classnames';

const Header = () => {
    const { loggedIn, sidebarIsExpanded } = useAppProvider();


    return (
        <nav className={classNames([
            "Header navbar navbar-expand-lg navbar fixed-top bg-white shadow-sm",
            !sidebarIsExpanded && "Header--SidebarCollapsed"
        ])}>
            <div className="container-fluid">
                <div className="ms-auto d-flex">
                    { loggedIn && 
                        <UserDropdown /> 
                    }
                </div>
            </div>
        </nav>
    );
}

export default Header;