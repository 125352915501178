const ButtonMultipleAdder = ({
    handleMultipleAdd,
    type,
    children,
    is6Doses,
    disabled
}) => {

    const addMultipleStep = (e) => {
        e.preventDefault();
        handleMultipleAdd(true,type,is6Doses);
    };

    const removeMultipleStep = (e) => {
        e.preventDefault();
        handleMultipleAdd(false,type,is6Doses);
    };

    const handleKeyPress = e => {
        if(e.code === 'Enter') {
            e.preventDefault();
        }
    };

    return (
        <button
            disabled={disabled}
            className="btn btn-link"
            onClick={(e) => addMultipleStep(e)}
            onContextMenu={(e) => removeMultipleStep(e)}
            onKeyPress={e => handleKeyPress(e)}
        >{children}</button>
    );
}

export default ButtonMultipleAdder;