import { useState, useEffect, useRef } from 'react';
import Classnames from 'classnames';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import { withTranslation } from "react-i18next";

const FormInput = ({
    t,
    id,
    type,
    step,
    value,
    dataCy,
    isSmall,
    pattern,
    disabled,
    required,
    readOnly,
    onChange,
    labelText,
    classnames,
    onKeyPress,
    placeholder,
    errorMessage,
    responseError,
    needsValidation,
    containerClasses,
    hasCopyBtn
}) => {
    const inputRef = useRef(null);
    const copyBtn = useRef(null);

    const [_value, setValue]                  = useState('');
    const [isValid, setIsValid]               = useState(true);
    const [wasValidated, setWasValidated]     = useState(false);
    const [_responseError, setResponseError]  = useState('');
    const [showCopyBtn, setShowCopyBtn]       = useState(false);

    const handleFocus = ev => {
        if(_responseError === '' || _responseError === undefined){
            setWasValidated(true);
            setIsValid(ev.target.checkValidity());
        }
    };

    const handleChange = (ev) => {
        setValue(ev.target.value);
        setResponseError('');
        handleFocus(ev);
        onChange(ev);
    };

    const handleCopyContent = ev => {
        ev.preventDefault();
        inputRef.current.select();
        document.execCommand("copy");
        setShowCopyBtn(true);
        setTimeout(() => {
            setShowCopyBtn(false);
        },3000);
    };

    useEffect( () => {
        setValue(value);
    },[value]);

    useEffect( () => {
        setResponseError(responseError);
        responseError == '' || responseError == undefined ? setIsValid(true) : setIsValid(false);
    },[responseError]);

    return (
        <div className={Classnames([
            'position-relative',
            containerClasses,
            hasCopyBtn && value && 'mb-3',
            (required || needsValidation) && "needs-validation",
            (required || needsValidation) && wasValidated && 'was-validated'
        ])}>
            { labelText &&
                <label htmlFor={id} className={Classnames([
                    "form-label",
                    isSmall && "form-label-sm",
                    hasCopyBtn && "mb-3"
                ])}>
                    {labelText}{' '}{required && <span>(*)</span>}

                </label>
            }
            { hasCopyBtn && value &&
            <>
                <button
                    ref={copyBtn}
                    onClick={ev => handleCopyContent(ev)}
                    className="btn btn-sm btn-outline-primary"
                    style={{
                        position: 'absolute',
                        right: '0'
                    }}
                    title="Copy this content"
                >
                    <span class="material-icons-outlined">content_copy</span>
                </button>
                <Overlay target={copyBtn.current} show={showCopyBtn} placement="top">
                {(props) => (
                  <Tooltip id="overlay-example" {...props}>
                    {t('general.contentCopied')}
                  </Tooltip>
                )}
              </Overlay>
            </>
            }
            <div className="input-group has-validation">
                <input
                    id={id}
                    step={step}
                    type={type}
                    ref={inputRef}
                    pattern={pattern}
                    required={required}
                    disabled={disabled}
                    data-cy={dataCy}
                    placeholder={placeholder}
                    onFocus={ev => handleFocus(ev)}
                    onChange={ev => handleChange(ev)}
                    value={_value || _value === 0 ? _value : ''}
                    className={Classnames([
                        'form-control', classnames, !isValid && 'is-invalid', isSmall && 'form-control-sm'
                    ])}
                    onKeyPress={onKeyPress}
                    readOnly={readOnly}
                />
                <div className="invalid-feedback">
                    {_responseError === "" || _responseError === undefined ? errorMessage : _responseError}
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(FormInput);

FormInput.defaultProps = {
    needsValidation: false,
}
