const MEDICINE_FILTERS = {
    INACTIVE:       true,
    NOT_BLISTABLE: true,
    IF_NEEDED: true
};

export default MEDICINE_FILTERS;

export const MEDICINE_FILTERS_LABELS = {
    INACTIVE: 'pages.planning.filters.unactive',
    NOT_BLISTABLE: 'pages.planning.filters.notBlistable',
    IF_NEEDED: 'pages.planning.filters.ifNeeded'
}