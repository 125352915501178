// React & Libs
import { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { withTranslation } from 'react-i18next';
import { Flags } from "react-feature-flags";

// Contexts & Hooks
import { useAppProvider } from '../../context/AppContext';
import useAPI from '../../hooks/useAPI';

// Router
import { Link } from 'react-router-dom';
import ROUTES from '../../constants/ROUTES';

// Components
import Pagination from '../../components/UI/Pagination';
import SearchBar from '../../components/UI/SearchBar';
import NoResults from '../../components/UI/NoResults';
import useToastCustom from '../../hooks/useToastCustom';
import ModalDelete from '../../components/CommonModals/ModalDelete';

// Constants
import classNames from 'classnames';
import PERMISSIONS from '../../constants/PERMISSIONS';

// Utils
import { orderMedicinesArray } from '../../utils/generalUtils';

import ModalTreatmentMedicines from '../../components/containers/ModalBookMedicines';

const MedicinesList = ({ t }) => {
  const {
    handleLoading,
    handleNotLoading,
  } = useAppProvider();

  const { showSuccessMessage, showErrorMessage } = useToastCustom();
  const { API, handleReqError } = useAPI();

  const [ medicines, setMedicines ]                   = useState([]);
  const [ totalPages, setTotalPages ]                 = useState(null);
  const [ currentPage, setCurrentPage ]               = useState(1);
  const [ currentID, setCurrentID ]     = useState(null);
  const [ showModalDelete, setShowModalDelete ] = useState(false);

  const getTherapeuticMedicines= (string) => {
    handleLoading();

    const url = string
      ? `/therapeutic-book-medicines/?text=${string}&page_size=25&page=${currentPage}`

      : `/therapeutic-book-medicines/?page=${currentPage}`
    ;

    API.get(url)
    .then((response) => {
      if (response) {
        handleNotLoading();
        setTotalPages(response.data.num_pages);
        setMedicines(orderMedicinesArray(response.data.results));
      }
    })
    .catch((error) => handleReqError(error));
  }

  const handleAddMedicine = medicine_id => {
    handleLoading();
    const data = { medicine: medicine_id};
    API.post('therapeutic-book-medicines/', data)
    .then((response) => {
      if (response) {
        showSuccessMessage(t('pages.bookMedicines.texts.addedOk'));
        setListModalIsOpen(false);
        handleNotLoading();
        getTherapeuticMedicines();
      }
    })
    .catch((error) => handleReqError(error));
  };

  const deleteMedicine = book_id => {
    handleLoading();
    API.delete(`therapeutic-book-medicines/${book_id}/`)
    .then((response) => {
      handleNotLoading();
      if(response.status === 409){
        showErrorMessage(t("pages.bookMedicines.texts.cannotDeleteBecauseUsed"));
      } else if(response.status === 204){
        getTherapeuticMedicines();
        showSuccessMessage(t("pages.bookMedicines.texts.deleteOk"));
      }else{
        showErrorMessage(t("pages.bookMedicines.texts.errorDeleting"));
      };
      setShowModalDelete(false);
    })
    .catch((error) => {
      const messageError = error && error.request && error.request.status === 409 ? t("pages.bookMedicines.texts.cannotDeleteBecauseUsed") : error;
      handleReqError(error, messageError);
      handleNotLoading();
      setShowModalDelete(false);
    });
  }

  const medicinesRows = medicines && medicines.map(medicine => (
    <tr key={nanoid()}>
      <td>{medicine.national_code}</td>
      <td>{medicine.is_blistable ? <span className='material-icons'>checked</span> : <span className='material-icons orange'>mail_outline</span>}</td>
      <td className={classNames([!medicine.is_blistable && 'orange'])}>{medicine.name}</td>
      <td>{medicine.type}</td>
      <td>{medicine.is_official ? <span className='material-icons'>checked</span> : <span className='material-icons'>close</span> }</td>
      <td style={{minWidth: '194px'}}>
        <Flags authorizedFlags={[PERMISSIONS['CAN_EDIT_BOOKS']]}>
          <Link to={`${ROUTES.THERAPEUTIC_BOOK.INDEX}/${medicine.id}`}>
            <button className='btn btn-outline-primary btn-sm me-2'>
              <span className='material-icons'>edit</span> <span>{t('general.edit')}</span>
            </button>
          </Link>
          </Flags>
        <Flags authorizedFlags={[PERMISSIONS['CAN_EDIT_BOOKS']]}>
        <button
          className='btn btn-outline-primary btn-sm me-2'
          onClick={() => {
            setCurrentID(medicine.id);
            setShowModalDelete(true);
          }}
        >
          <span className='material-icons'>delete</span> <span>{t('general.delete')}</span>
        </button>
        </Flags>
      </td>
    </tr>
  ));

  useEffect(() => {
    getTherapeuticMedicines();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const [ listModalIsOpen, setListModalIsOpen ] = useState(false);

  return (
    <>
      <div className="ContentWrapper container p-4">
        <h2 className='font-header text-primary display-6 fw-bold dots-side-left fwp mb-4'>
          {t('pages.bookMedicines.titles.list')}
        </h2>
        <div className='card p-2'>
          <div className='d-flex py-2 pb-2 pb-3 mb-3 border-bottom'>
            <SearchBar
              className='col-md-6'
              onChange={(string) => getTherapeuticMedicines(string)}
            />
            <div className='ms-auto d-flex align-items-center'>
            <Flags authorizedFlags={[PERMISSIONS['CAN_EDIT_BOOKS']]}>
              <button
                className='btn btn-primary btn-sm px-2 me-2'
                onClick={ () => {
                  setListModalIsOpen(true);
                }}
              >
                  <span className='material-icons me-2'>search</span>
                  <span>{t('pages.bookMedicines.texts.addToBook')}</span>
              </button>
              </Flags>
              <Flags authorizedFlags={[PERMISSIONS['CAN_EDIT_BOOKS']]}>
                <Link to={ROUTES.THERAPEUTIC_BOOK.NEW}>
                  <button className='btn btn-primary btn-sm px-2'>
                    <span className='material-icons me-2'>add_box</span>
                    <span>{t('pages.bookMedicines.texts.createItem')}</span>
                  </button>
                </Link>
                </Flags>
            </div>
          </div>
          {medicines[0] !== undefined ? (
            <>
              <table className='table'>
                <thead>
                  <tr>
                    <th scope='col'>{t('pages.bookMedicines.fields.nationalCode')}</th>
                    <th scope='col'>{t('pages.bookMedicines.fields.isBlistable')}</th>
                    <th scope='col'>{t('pages.bookMedicines.fields.name')}</th>
                    <th scope='col'>
                      {t('pages.bookMedicines.fields.type')}
                    </th>
                    <th scope='col'>{t('pages.bookMedicines.fields.isOfficial')}</th>
                    <th scope='col'>{t('general.action')}</th>
                  </tr>
                </thead>
                <tbody>{medicinesRows}</tbody>
              </table>
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={(pageNumber) => setCurrentPage(pageNumber)}
              />
            </>
          ) : (
            <>
              <NoResults />
            </>
          )}
        </div>
      </div>
      <ModalDelete
        show={showModalDelete}
        handleDelete={() => {
          deleteMedicine(currentID);
          setCurrentID(null);
        }}
        handleClose={() => {
          setShowModalDelete(false);
          setCurrentID(null);
        }}
      />
      <ModalTreatmentMedicines
        show={listModalIsOpen}
        onChange={handleAddMedicine}
        handleClose={() => setListModalIsOpen(false)}
      />
    </>
  );
};

export default withTranslation()(MedicinesList);
