import React, { useState, useEffect } from 'react'
import { serverURL } from '../../../constants/SERVERS';
import useAPI from '../../../hooks/useAPI';
import useToastCustom from '../../../hooks/useToastCustom';
import { withTranslation } from 'react-i18next';
import { useAppProvider } from '../../../context/AppContext';

const FileUpload = ({
  t,
  updateFn,
  patientId,
}) => {

  const [file, setFile] = useState(null);
  const { API, handleReqError } = useAPI();
  const { handleLoading, handleNotLoading } = useAppProvider();

  const { showSuccessMessage } = useToastCustom();

  const onChange = e => {
    setFile(e.target.files[0]);
  };

  const fileUpload = () => {
    handleLoading();
    const url = `${serverURL}api/patient-files/`;
    const formData = new FormData();
    formData.append('file',file);
    formData.append('patient',patientId);
    API.post(url, formData)
    .then(response => {
      handleNotLoading();
      showSuccessMessage(t('general.changesSavedProperly'));
      updateFn();
    })
    .catch(() => handleReqError(t('general.errorFetch')));
  };

  useEffect(() => {
    file !== null && fileUpload();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ file ]);

  return (
    <div className="mt-2">
        <button className="btn btn-primary position-relative">
          <span>{t('general.upload_file')}</span>
          <input className="InputFileCustom" type="file" onChange={e =>  onChange(e)} />
        </button>
    </div>
  );
}

export default withTranslation()(FileUpload);