import SelectAnHour from "../Modals/ModalComponents/SelectAnHour";
import ButtonAdder from "./ButtonAdder";
import { nanoid } from "nanoid";
import { useTreatmentsProvider } from "../../../../context/TreatmentsContext";
import { withTranslation } from "react-i18next";

// Libs
import Classnames from 'classnames';

const TableCustomWeekly = ({ t, step, disabled }) => {

  const { patterns, setPatterns } = useTreatmentsProvider();

  const _ = require("lodash");

  let intake_timeRows =
    patterns && _.isArray(patterns) === true ? [...patterns] : [];

  for (let index = 0; index < intake_timeRows.length; index++) {
    let row = intake_timeRows[index];
    if (row !== undefined && row.intake_time !== undefined) {
      if (row.intake_time.length > 5) {
        intake_timeRows[index].intake_time = row.intake_time.slice(0, -3);
      }
    }
  }

  const addStep = (e) => {
    e.preventDefault();
    const id = e.target.parentElement.id;
    const split = id.split(".");
    const day = split[0];
    const time = split[1];
    let _patterns = [...patterns];
    let index = _patterns.findIndex((item) => item.intake_time === time);
    const quantity = _patterns[index].posology[day]
      ? Number(_patterns[index].posology[day])
      : 0;
    _patterns[index].posology[day] = quantity + step;
    setPatterns([..._patterns]);
  };

  const removeStep = (e) => {
    e.preventDefault();
    const id = e.target.parentElement.id;
    const split = id.split(".");
    const day = split[0];
    const time = split[1];
    let _patterns = [...patterns];
    let index = _patterns.findIndex((item) => item.intake_time === time);
    let quantity = _patterns[index].posology[day]
      ? Number(_patterns[index].posology[day])
      : 0;
    quantity = quantity - step;
    quantity = quantity < 0 ? 0 : quantity;
    _patterns[index].posology[day] = quantity;
    setPatterns([..._patterns]);
  };

  const handleRowAdd = (intake_time) => {
    const _intake_timeRows = [...intake_timeRows];
    const rowObj = {
      intake_time: intake_time,
      posology: {
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
      },
    };
    _intake_timeRows.push(rowObj);
    _intake_timeRows.sort(function (a, b) {
      const keyA = a.hour,
        keyB = b.hour;
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    setPatterns([..._intake_timeRows]);
  };

  const removeRow = (intake_time) => {
    const _intake_timeRows = [...intake_timeRows];
    const index = _intake_timeRows.findIndex(
      (obj) => obj["intake_time"] === intake_time
    );
    _intake_timeRows.splice(index, 1);
    setPatterns(_intake_timeRows);
  };

  const RowComponent = ({ intake_time, posology, removeRow }) => (
    <tr>
      <td className="position-relative">
      <span className={Classnames([
          disabled && 'text-muted'
      ])}>{intake_time}</span>
        <button
          disabled={disabled}
          type="button"
          className="btn-close"
          aria-label="Close"
          style={{
            position: "absolute",
            top: "6px",
            right: "32px",
            fontSize: "8px",
            backgroundColor: "white",
            border: "1px solid black",
            borderRadius: "50%",
            padding: "4px",
          }}
          onClick={() => removeRow(intake_time)}
        ></button>
      </td>
      <td>
        <ButtonAdder
          disabled={disabled}
          id={`monday.${intake_time}`}
          value={posology["monday"]}
          addStep={(e) => addStep(e)}
          removeStep={(e) => removeStep(e)}
        />
      </td>
      <td>
        <ButtonAdder
          disabled={disabled}
          id={`tuesday.${intake_time}`}
          value={posology["tuesday"]}
          addStep={(e) => addStep(e)}
          removeStep={(e) => removeStep(e)}
        />
      </td>
      <td>
        <ButtonAdder
          disabled={disabled}
          id={`wednesday.${intake_time}`}
          value={posology["wednesday"]}
          addStep={(e) => addStep(e)}
          removeStep={(e) => removeStep(e)}
        />
      </td>
      <td>
        <ButtonAdder
          disabled={disabled}
          id={`thursday.${intake_time}`}
          value={posology["thursday"]}
          addStep={(e) => addStep(e)}
          removeStep={(e) => removeStep(e)}
        />
      </td>
      <td>
        <ButtonAdder
          disabled={disabled}
          id={`friday.${intake_time}`}
          value={posology["friday"]}
          addStep={(e) => addStep(e)}
          removeStep={(e) => removeStep(e)}
        />
      </td>
      <td>
        <ButtonAdder
          disabled={disabled}
          id={`saturday.${intake_time}`}
          value={posology["saturday"]}
          addStep={(e) => addStep(e)}
          removeStep={(e) => removeStep(e)}
        />
      </td>
      <td>
        <ButtonAdder
          disabled={disabled}
          id={`sunday.${intake_time}`}
          value={posology["sunday"]}
          addStep={(e) => addStep(e)}
          removeStep={(e) => removeStep(e)}
        />
      </td>
    </tr>
  );

  return (
    <>
      <SelectAnHour disabled={disabled} onChange={(e) => handleRowAdd(e)} />
      <table
        className="table table-layout-fixed"
        onContextMenu={(e) => e.preventDefault()}
      >
        <thead>
          <tr>
            <th scope="col">{t("general.hours")}</th>
            <th scope="col">{t("date.weekdays.monday")}</th>
            <th scope="col">{t("date.weekdays.tuesday")}</th>
            <th scope="col">{t("date.weekdays.wednesday")}</th>
            <th scope="col">{t("date.weekdays.thursday")}</th>
            <th scope="col">{t("date.weekdays.friday")}</th>
            <th scope="col">{t("date.weekdays.saturday")}</th>
            <th scope="col">{t("date.weekdays.sunday")}</th>
          </tr>
        </thead>
        <tbody>
          {intake_timeRows.map((intake_timeRow) => (
            <RowComponent
              key={nanoid()}
              intake_time={intake_timeRow.intake_time}
              posology={intake_timeRow.posology}
              removeRow={(intake_timeRow) => removeRow(intake_timeRow)}
            />
          ))}
        </tbody>
      </table>
    </>
  );
};

export default withTranslation()(TableCustomWeekly);
