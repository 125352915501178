import classNames from "classnames";
import { nanoid } from "nanoid";
import FormCheckbox from "../../../../../components/UI/FormCheckbox"
import { useAppProvider } from '../../../../../context/AppContext';
import { useTreatmentsProvider } from '../../../../../context/TreatmentsContext';
import useAPI from "../../../../../hooks/useAPI";
import MedicinePosologiesGrid from "../MedicinePosologiesGrid";
import { withTranslation } from 'react-i18next';
import ProposalStatusBadge from '../../ui/ProposalStatusBadge';
import { Flags } from "react-feature-flags";
import MODULES from '../../../../../constants/MODULES';

const ProposalItem = ({
    t,
    medicine,
    index,
    proposalsSelected,
    handleCheckboxClick,
}) => {

    const { API, handleReqError } = useAPI();

    const {
        setDispPosologies,
        setActiveMedicine,
        setMedicinePosologies,
        setShowPosologiesModal,
        setActiveMedicineIsProposal,
        setIsStoppedMedicine,
    } = useTreatmentsProvider();

    const { handleLoading, handleNotLoading } = useAppProvider();

    const handleClickStoppedMedicine = (medicine_id) => {
        setActiveMedicine(medicine_id);
        setIsStoppedMedicine(true);
        setShowPosologiesModal(true);
    };

    const handleClickMedicine = (medicine_id) => {
        handleLoading();
        setIsStoppedMedicine(false);
        setActiveMedicine(medicine_id);
        API.get(`posologies/?treatment_medicine=${medicine_id}`)
        .then((response) => {
            handleNotLoading();
            if (response) {
                setIsStoppedMedicine(false);
                setActiveMedicineIsProposal(true);
                setMedicinePosologies(response.data.results);
                setDispPosologies(response.data.results);
                setShowPosologiesModal(true);
            }
        })
        .catch((error) => handleReqError(error));
    };

    // Conditions
    const hasProposalPosologies = medicine.posologies !== undefined && medicine.posologies.length > 0;
    const hasCurrentPosologies = medicine.prev_posologies !== undefined && medicine.prev_posologies.length > 0;
    const hasPosologies = (hasProposalPosologies === true || hasCurrentPosologies === true);

    return (
        <div
            className="ProposalItem__Wrapper"
        >
            <Flags authorizedFlags={[MODULES["PROPOSALS_VALIDATION"]]}>
                <FormCheckbox
                    value={proposalsSelected ? proposalsSelected[medicine.name] : false}
                    labelText={t('general.change') + ` ${(index + 1)}`}
                    onChange={(e) => handleCheckboxClick(e,medicine.name)}
                />
            </Flags>
            <p>{t('general.change') + ` ${(index + 1)}`}</p>

            <div
                className={classNames([
                    "ProposalItem MedicineGridItem p-1",
                    proposalsSelected && proposalsSelected[medicine.name] === true ? 'active' : 'disabled'
                ])}
                style={{
                    display: 'grid',
                    gridTemplateColumns: '168px repeat(1, 80px)'
                }}
            >
                <div
                    className="MedicineGridItem__Title cursor-pointer"
                    style={{
                        gridColumn: "1/2",
                    }}
                    onClick={() => medicine.proposal_is_stopper === false
                    ? handleClickMedicine(medicine.treatment_medicine)
                    : handleClickStoppedMedicine(medicine.treatment_medicine)
                    }
                >
                    <div
                        className={classNames([
                            "border h-100 rounded p-2 text-break lh-1 d-flex flex-column justify-content-evenly",
                            medicine.is_blistable === true && ((medicine.posologies && medicine.posologies.length > 0) || (medicine.prev_posologies && medicine.prev_posologies.length > 0)) && 'bg-white',
                            medicine.is_blistable === false && "bg-orange-light border-orange",
                            (medicine.posologies && medicine.posologies.length === 0) && "bg-light-grey",
                        ])}
                        title={medicine.name}
                    >
                        <div>
                            <p>{medicine.name}</p>
                            <ProposalStatusBadge
                                proposalStatus={medicine.proposal_status}
                                isApplied={medicine.proposal_is_applied}
                            />
                        </div>
                        <button
                            className="btn btn-sm btn-primary d-flex align-items-center w-100 justify-content-center"
                        >
                            <span className="material-icons-outlined">bloodtype</span>
                            <span style={{fontSize: '9px'}}>{t('pages.proposals.editPosologyProposal')}</span>
                        </button>
                    </div>
                </div>
                <div
                    className="MedicineGridItem__Posology"
                    style={{
                        gridColumn: "2/17",
                    }}
                >
                    { hasPosologies === false &&
                        <p className="text-left ms-2">{t('pages.proposals.noElements')}</p>
                    }
                    { hasPosologies === true &&
                    <>
                        <div className={`Posology Posology__Proposal`}>
                            <p className="ps-2">{t('general.proposal')}</p>
                            { medicine.posologies && medicine.posologies.map(posology => (
                                <MedicinePosologiesGrid
                                    isProposalItem={true}
                                    isNotBlistable={medicine.is_blistable === false}
                                    isFuturePosology={true}
                                    key={nanoid()}
                                    posology={posology}
                                />
                            ))}
                        </div>
                        <div className={`Posology Posology__Current`}>
                            <p className="ps-2">{t('general.current')}</p>
                            { medicine.prev_posologies && medicine.prev_posologies.map(posology => (
                                <MedicinePosologiesGrid
                                    isProposalItem={true}
                                    isNotBlistable={medicine.is_blistable === false}
                                    isPreviousPosology={true}
                                    key={nanoid()}
                                    posology={posology}
                                />
                            ))}
                        </div>
                    </>
                    }
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(ProposalItem);