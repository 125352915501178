import { withTranslation } from "react-i18next";

const Home = ({t}) => {
    return (
        <div className="ContentWrapper container p-4">
            <div className="container">
                <div className="row">
                    <h1 className="font-header text-primary display-4 fw-bold dots-side-left fwp mb-4">{t('pages.home.title')}</h1>
                    <h2 className="text-secondary mb-4">{t('pages.home.subTitle')}</h2>
                </div>
                <div className="row align-items-center">
                    <div className="col">
                        <p className="lead">{t('pages.home.mainText1')}</p>
                        <p>{t('pages.home.mainText2')}</p>
                        <p>{t('pages.home.mainText3')}</p>

                    </div>
                    <div className="col-12 col-md-4">
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <a className="btn btn-primary" rel="noreferrer" href="https://www.ti-medi.com/" target="_blank">
                        {t('pages.home.CTA')}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(Home);