import classNames from 'classnames';

const Avatar = ({
    icon,
    color,
    width,
    height,
    initial,
    bgClass,
    iconSize,
    className,
}) => {
    return (
        <div 
            className={classNames([
                "rounded-circle text-capitalize d-flex justify-content-center align-items-center flex-shrink-0",
                bgClass ? bgClass : 'bg-primary ',
                color ? `text-${color}` : 'text-white',
                className
            ])}
            style={{
                width: width ? width : '32px',
                height: height ? height : '32px',
            }}
        >
            {icon 
                ? <span
                    className="material-icons"
                    style={{
                        fontSize: iconSize
                    }}
                >{icon}</span>
                : initial
                    ? <span className="fs-5">{initial}</span>
                    : null
            }
        </div>
    );
}
 
export default Avatar;