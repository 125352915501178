import { useState } from 'react';
import { nanoid } from "nanoid";
import Button from "react-bootstrap/Button";
import { useTreatmentsProvider } from "../../../../context/TreatmentsContext";
import useAPI from "../../../../hooks/useAPI";
import useToastCustom from "../../../../hooks/useToastCustom";

// Libs
import { withTranslation } from "react-i18next";
import Classnames from 'classnames';
import {DDMMYYYY, DDMMYYYYFromStringDate} from '../../../../utils/dateUtils';
import FormCheckbox from "../../../../components/UI/FormCheckbox";
import { useAppProvider } from '../../../../context/AppContext';

const TablePosologiesList = ({t}) => {

    const {
        activePatient,
        setMedicinePosologies,
        medicinePosologies,
        setActivePosologyID,
        setShowPosologyModal,
        setShowPosologiesModal,
        getMedicinesFromPatient,
        setActivePosologyWasProduced,
        displayedPosologies,
        setDispPosologies,
        activeMedicine,
    } = useTreatmentsProvider();

    const { handleLoading, handleNotLoading } = useAppProvider();

    const { API, handleReqError } = useAPI();
    const { showSuccessMessage } = useToastCustom();
    const [isEmpty, setisEmpty] = useState(displayedPosologies && displayedPosologies[0] === undefined); // TODO: clean


    const getPosologies = (filter) => {
        handleLoading();
        const url = filter !== null && filter !== undefined
        ? `posologies/?treatment_medicine=${activeMedicine}&active=${filter}`
        : `posologies/?treatment_medicine=${activeMedicine}`
        ;
        API.get(url)
        .then((response) => {
            handleNotLoading();
            if (response) {
                setMedicinePosologies(response.data.results);
                setDispPosologies(response.data.results);
                setisEmpty(response.data.results[0] === undefined);
            }
        })
        .catch((error) => handleReqError(error));
    }

    const handleOpeningPosology = (posology_id,isProduced) => {
        handleLoading();
        setActivePosologyID(posology_id ? posology_id : null);
        setActivePosologyWasProduced(isProduced);
        setShowPosologyModal(true);
        setShowPosologiesModal(false);
        handleNotLoading();
    };

    const handleRemovePosology = (e,posology_id) => {
        handleLoading();
        e.stopPropagation();
        const url = `posologies/${posology_id}`;
        API.delete(url)
            .then( () => {
                getPosologies(filter);
                getMedicinesFromPatient({patient_id: activePatient});
                showSuccessMessage(t('general.changesSavedProperly'));
                handleNotLoading();
            })
            .catch((error) => handleReqError(error))
        ;
    }

    const handleRemoveMedicine = (e) => {
        e.stopPropagation();
        handleLoading();

        const url = `treatment-medicines/${activeMedicine}`;
        API.delete(url)
            .then( () => {
                handleNotLoading();
                getPosologies(filter);
                getMedicinesFromPatient({patient_id: activePatient});
                showSuccessMessage(t('general.changesSavedProperly'));
                setShowPosologiesModal(false);
            })
            .catch((error) => handleReqError(error))
        ;
    }


    const [ filter, setFilter ] = useState(true);

    const handleFilterChange = (e,filter) => {
        handleLoading();
        e.preventDefault();
        setFilter(filter);
        getPosologies(filter);
        handleNotLoading();
    };

    return (
        <div>
            <div className="mb-3">
                <span className="d-inline-block me-4">{t('general.view')}</span>
                <FormCheckbox type={'radio'} value={filter === true}    labelText={t('general.activesF')}     containerClassName={'d-inline-block me-3'} onChange={e => handleFilterChange(e,true)}/>
                <FormCheckbox type={'radio'} value={filter === false}   labelText={t('general.inactivesF')}   containerClassName={'d-inline-block me-3'} onChange={e => handleFilterChange(e, false)}/>
                <FormCheckbox type={'radio'} value={filter === null}    labelText={t('general.allF')}         containerClassName={'d-inline-block me-3'} onChange={e => handleFilterChange(e, null)}/>
            </div>
            {  !isEmpty ?
            <>
                <table className="table table-striped">
                    <tbody>
                        <tr>
                            <td>{t('pages.planning.fields.type')}</td>
                            <td>{t('pages.planning.fields.startDate')}</td>
                            <td>{t('pages.planning.fields.endDate')}</td>
                            <td>{t('pages.planning.texts.ifNeeded')}</td>
                            <td>{t('pages.planning.texts.specialDosage')}</td>
                            <td>{t('pages.planning.texts.isProduced')}</td>
                            <td>{t('general.action')}</td>
                        </tr>

                    { displayedPosologies && displayedPosologies.map( ( posology ) => (
                        <tr
                            key={nanoid()}
                            onClick={ () => handleOpeningPosology(posology.id,posology.produced)}
                            className={Classnames([
                                posology.produced === false ? "cursor-pointer" : "cursor-pointer text-muted"
                            ])}
                        >
                            <td>{posology.type}</td>
                            <td>{DDMMYYYYFromStringDate(posology.start_date)}</td>
                            <td>{posology.end_date ? DDMMYYYYFromStringDate(posology.end_date) : null}</td>
                            <td>{posology.if_needed && <span className="material-icons-outlined">done</span>}</td>
                            <td>{posology.special_dosage && <span className="material-icons-outlined">done</span>}</td>
                            <td>{posology.produced && <span className="material-icons-outlined text-success">done</span>}</td>
                            <td>
                                { !posology.produced && (
                                <button
                                    className='btn btn-outline-primary btn-sm me-2'
                                    onClick={(e) => handleRemovePosology(e,posology.id)}
                                >
                                    <span className='material-icons'>delete</span> <span>{t('general.delete')}</span>
                                </button>
                                )}
                                <button
                                    className='btn btn-outline-primary btn-sm me-2'
                                >
                                    <span className='material-icons'>edit</span> <span>{t('general.edit')}</span>
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </>
            : (
                <div style={{minHeight: '100px'}}>{t('general.noResults')}</div>
            )}
            {
                <>
                        <Button className="me-2" onClick={() => handleOpeningPosology()}>
                            + {t('pages.planning.texts.createPosology')}
                        </Button>

                        { medicinePosologies[0] === undefined && (
                        <Button variant="outline-danger"  onClick={(e) => handleRemoveMedicine(e)}>
                            <span className="material-icons-outlined">delete</span> <span>{t('general.delete')}</span>
                        </Button>
                        )}

                    </>
            }

        </div>
    );
}

export default withTranslation()(TablePosologiesList);
