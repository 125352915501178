
import { Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";

const ModalAlert = ({
    t,
    show,
    handleClose,
    handleDelete
}) => {

    return (
        <Modal
            show={show}
            animation={false}
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {t('general.delete')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{t("general.areYouSure")}</p>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex justify-content-between w-100">
                    <div>
                        <button
                            className="btn btn-outline-primary me-2"
                            onClick={() => handleClose()}
                        >{t('general.cancel')}</button>
                        <button
                            className="btn btn-primary"
                            onClick={() => handleDelete()}
                        >{t('general.delete')}</button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default withTranslation()(ModalAlert);
