// Libs & React
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import useAPI from '../../../hooks/useAPI';

// Components
import DropdownCountriesWithSearch from '../../UI/DropdownCountriesWithSearch';

const SelectCountries = ({
    id,
    value,
    isSmall,
    onChange,
    labelText,
    containerClassName,
}) => {
    const [countries, setCountries] = useState([]);
    const { API, handleReqError } = useAPI();

    useEffect(() => {
        API.get('countries?page_all=1')
        .then((response) => {
            if (response) {
                // let countries = [{code: '', name: "---"}];
                // setCountries(countries.concat(response.data));
                setCountries(response.data);
            }
        })
        .catch((error) => handleReqError(error));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={containerClassName}>
            { labelText && <label className={classNames([
                "form-label", isSmall && "form-label-sm"
            ])}>{labelText}</label> }
            <DropdownCountriesWithSearch
                isSmall
                id={id}
                options={countries}
                value={value}
                onChange={onChange}
            ></DropdownCountriesWithSearch>
        </div>
    );
}
 
export default SelectCountries;