import { useEffect, useState } from 'react';
import { withTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import { nanoid } from 'nanoid'
import useAPI from "../../../../../hooks/useAPI";
import SearchBar from "../../../../../components/UI/SearchBar";
import { useTreatmentsProvider } from '../../../../../context/TreatmentsContext';

const ModalMedicines = ({ show,handleClose,onChange,t }) => {

    const [ medicines, setMedicines ] = useState(null);
    const [ canShowNoResults, setCanShowNoResults ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);

    const medicinesItems = (medicines && medicines[0]) ? medicines.map(medicine => (
            <ListGroup.Item key={nanoid()}>
                <Button size="sm" variant="link" onClick={() => onChange(medicine.id)}>
                    {medicine.name}
                </Button>
            </ListGroup.Item>
        )
    ) : (isLoading)
            ?
            <ListGroup.Item key={nanoid()} className="text-center">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden"></span>
                </div>
            </ListGroup.Item>
            :
            canShowNoResults && 
            <ListGroup.Item key={nanoid()}>{t('pages.bookMedicines.texts.noMedicinesFound')}</ListGroup.Item>
    ;

    const { API, handleReqError } = useAPI();
    const { activePatient } = useTreatmentsProvider();

    const getTherapeuticMedicines= (string) => {
        const _string = string ? string : '';
        API.get(`/therapeutic-book-medicines/?text=${_string}&not_used_by_patient=${activePatient}`)
        .then((response) => {
            setIsLoading(false);
            setCanShowNoResults(true);
            if (response) {
                setMedicines(response.data.results);
            }
        })
        .catch((error) => handleReqError(error));
    };

    const handleOnSearch = str => {
        if(str.length > 0) {
            setIsLoading(true);
            setCanShowNoResults(false);
            getTherapeuticMedicines(str);
        } else {
            setIsLoading(false);
            setCanShowNoResults(false)
            setMedicines(null);
        }
    };

    useEffect(() => {
        return () => {
            setMedicines(null);
            setCanShowNoResults(false);
        };
    },[show])

    return (
        <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{t('pages.bookMedicines.texts.addMedicine')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{t('general.search')}</p>
                <SearchBar className="mb-2" onChange={str => handleOnSearch(str)}/>
                <ListGroup className="MedicinesList">
                    {medicinesItems}
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={() => handleClose()}>
                {t('general.close')}
            </Button>
            </Modal.Footer>
        </Modal>
    );
}
 
export default withTranslation()(ModalMedicines);