import { withTranslation } from "react-i18next";

const NoResults = ({ t }) => {
    return (
      <div
        style={{
          height: "40vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="flex">
          <div className="display" style={{ display: "inline-block" }}>
            <h6 className="display-6">
              <span className="me-2">{t("general.noResults")}</span>
              <span className="material-icons" style={{ fontSize: "2.5rem" }}>
                search
              </span>
            </h6>
          </div>
        </div>
      </div>
    );
};

export default withTranslation()(NoResults);
