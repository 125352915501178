import { useEffect, useState } from 'react';

// Components
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import EditPosologyModalContent from '../EditPosologiesModalContent';

// Libs
import { withTranslation } from "react-i18next";

// Styles
import './styles.css';

// Context
import { useTreatmentsProvider } from "../../../../../context/TreatmentsContext";
import useAPI from "../../../../../hooks/useAPI";

// Constants
import LUNCHTIMES_LABELS_POSOLOGY  from '../../../../../constants/LUNCHTIMES_LABELS_POSOLOGY';
import useToastCustom from "../../../../../hooks/useToastCustom";
import { useAppProvider } from "../../../../../context/AppContext";
import { YYYYMMDD, YYYYMMDDFromStringDate, getDateFromXdays } from "../../../../../utils/dateUtils";
import POSOLOGY_TYPES from "../../../../../constants/POSOLOGY_TYPES";
import { getArraysForProposalsPage } from '../../../../../utils/proposalsUtils';
import { modalPosologyIsReadyToPost, addMedicineFilters } from "../../../../../utils/generalUtils";

const ModalPosologies = ({
    t,
    show,
    handleClose,
}) => {
    const _ = require("lodash");

    const {
        patterns,
        initialDate,
        activePatient,
        activeMedicine,
        evaluateError,
        currentPosology,
        setActivePatient,
        currentPosologyID,
        activeMedicineIsProposal,
        activeBookMedicine,
        posologyTypeSelected,
        setShowPosologyModal,
        setShowPosologiesModal,
        setActivePatientMedicines,
        setProposalsPatientMedicines,
        setActiveMedicine,
        setActiveBookMedicine,
        editPosologiesIsDisabled,
        setEditPosologiesIsDisabled,
        medicinesFilters,
    } = useTreatmentsProvider();

    const isDisabled = editPosologiesIsDisabled;

    const { API, handleReqError, handleReqErrorBasic } = useAPI();

    const { handleLoading, handleNotLoading }       = useAppProvider();
    const { showSuccessMessage, showErrorMessage }  = useToastCustom();

    const [ canSendPosology, setCanSendPosology ] = useState(false);

    const __getProposalsAndMedicinesFromPatient = data => {
        handleLoading();
        setActivePatientMedicines([]);
        setProposalsPatientMedicines([]);

        const patient_id = data && data.patient_id ? data.patient_id : activePatient;
        const start_date = data && data.start_date ? data.start_date : initialDate;
        const _initialDate = start_date ? start_date : initialDate;
        const _start_date = YYYYMMDD(_initialDate);
        const _end_date = YYYYMMDD(getDateFromXdays(_initialDate, 14));

        if(patient_id) { setActivePatient(patient_id) };
        if(patient_id === (undefined || null)) { return };

        const _url = `treatments/?patient=${patient_id}&start_date=${_start_date}&end_date=${_end_date}&include_proposals=true`;
        const url = addMedicineFilters(_url, medicinesFilters);

        API.get(url)
            .then((response) => {
                handleNotLoading();
                if (response) {
                    const result = getArraysForProposalsPage(response.data.medicines);
                    setProposalsPatientMedicines([...result[0]]);
                    setActivePatientMedicines([...result[1]]);
                    setShowPosologyModal(false);
                    setShowPosologiesModal(false);
                }
            })
            .catch((error) => {
                handleNotLoading();
                setProposalsPatientMedicines(null);
                setActivePatientMedicines(null);
            })
        ;
    };

    const getPosologies = () => {
        API.get(`posologies/?treatment_medicine=${activeMedicine}`)
        .then((response) => {
            if (response) {
                __getProposalsAndMedicinesFromPatient();
            }
        })
        .catch((error) => handleReqError(error));
    }

    const defineMissingMonthlyDates = patterns => {
        let _patterns = [...patterns];
        for (let index = 0; index < patterns.length; index++) {
            let pattern = patterns[index];

            if(pattern['month_start_day'] === undefined){
                pattern['month_start_day'] = "1";
            }

            if(pattern['month_end_day'] === undefined){
                pattern['month_end_day'] = "31";
            }

            _patterns[index] = pattern;
        }

        return _patterns;
    }

    const getAPIPatterns = () => {
        if(!patterns) { return null };
        const previousPatterns = currentPosology.patterns ? currentPosology.patterns : [];
        let result = previousPatterns;
        let data = [];
        if(patterns[0] && patterns[0].intake_time !== undefined) {
            patterns.forEach(pattern => {
                const intake_time = pattern.intake_time;
                Object.entries(pattern.posology).forEach(item => {
                    if(item[1] !== null && item[1] > 0) {
                        const interval = {
                            quantity: item[1],
                            weekday: item[0],
                            intake_time: intake_time
                        };
                        data.push(interval);
                    }

                })
            });
        } else {
            const entries = Object.entries(patterns);
            entries.forEach(entry => {
                const lunchtime = LUNCHTIMES_LABELS_POSOLOGY[entry[0]];
                const entryValues = Object.entries(entry[1]);
                entryValues.forEach(pattVal => {
                    let patt = {
                        quantity: pattVal[1],
                        weekday: pattVal[0],
                        intake_time: lunchtime
                    }
                    if(patt.quantity > 0) {
                        data.push(patt);
                    } else {
                        result = result.filter(el => el.intake_time !== lunchtime)
                    }

                })
            });
        }
        result = _.merge(result,data);
        return result;
    };

    const handleSavePosology = () => {
        handleLoading();
        let {start_date, end_date } = currentPosology;
        start_date = start_date ? YYYYMMDDFromStringDate(start_date) : null;
        end_date = end_date ? YYYYMMDDFromStringDate(end_date) : null;
        const url = currentPosologyID ? `posologies/${currentPosologyID}/` : 'posologies/';
        const method = currentPosologyID ? 'put' : 'post';
        let _patterns = posologyTypeSelected === POSOLOGY_TYPES[0] || posologyTypeSelected === POSOLOGY_TYPES[1] || posologyTypeSelected === POSOLOGY_TYPES[2]
        ?
        getAPIPatterns()
        :
        patterns
        ;
        if(_patterns) {
            // Monthly interval does not need to have defined start and end date on each row, so we define it in case it is not.
            if(posologyTypeSelected === 'monthly') {
                _patterns = defineMissingMonthlyDates(_patterns);
            }
            const data = { ...currentPosology, type: posologyTypeSelected, start_date, end_date, patterns: _patterns };

            API({
                method: method,
                url: url,
                data: data,
            })
                .then((response) => {
                        handleNotLoading();
                        if (response.status === 200 || 201) {
                            showSuccessMessage(t('general.changesSavedProperly'));
                            getPosologies();
                        }
                    })
                .catch((error) => evaluateError(error) )
            ;
        } else {
            handleNotLoading();
            showErrorMessage('No hay ninguna dosis activa.')
        }
    };

    const handleSavePosologyWhenTreament = (treatment_medicine) => {
        handleLoading();
        let {start_date, end_date} = currentPosology;
        start_date = start_date ? YYYYMMDDFromStringDate(start_date) : null;
        end_date = end_date ? YYYYMMDDFromStringDate(end_date) : null;
        const url = currentPosologyID ? `posologies/${currentPosologyID}/` : 'posologies/';
        const method = currentPosologyID ? 'put' : 'post';
        let _patterns = posologyTypeSelected === POSOLOGY_TYPES[0] || posologyTypeSelected === POSOLOGY_TYPES[1] || posologyTypeSelected === POSOLOGY_TYPES[2]
        ?
        getAPIPatterns()
        :
        patterns
        ;
        if(_patterns) {
            if(posologyTypeSelected === 'monthly') {
                _patterns = defineMissingMonthlyDates(_patterns);
            }
            const data = { ...currentPosology, treatment_medicine: treatment_medicine, start_date, end_date, patterns: _patterns, is_proposal: true, is_produced: false, type: posologyTypeSelected};

            API({
                method: method,
                url: url,
                data: data,
            })
                .then((response) => {
                        handleNotLoading();
                        if (response.status === 200 || 201) {
                            // showSuccessMessage(t('general.changesSavedProperly'));
                            // setProposalsPatientMedicines(null);
                            // setActivePatientMedicines(null);
                            // _getProposalsAndMedicinesFromPatient();
                            // _getPosologies();
                            // setShowPosologiesModal(false);
                            showSuccessMessage(t('general.changesSavedProperly'));
                            getPosologies();
                        }
                    })
                .catch((error) => handleReqError(error))
            ;
        } else {
            handleNotLoading();
            showErrorMessage('No hay ninguna dosis activa.')
        }
    };

    const handleCreatingPosologyFromTreatment = () => {
        if(patterns === null) {
            showErrorMessage(t('pages.planning.texts.noPosology'));
            return;
        }
        handleLoading();
        const data = {
            book_medicine: activeBookMedicine,
            is_proposal: true,
            patient: activePatient,
        };
        API.post("/treatment-medicines/",data)
        .then((response) => {
            if (response.status === 200 || 201) {
                const treatment_medicine = response.data.id;
                const book_medicine = response.data.book_medicine;
                setActiveMedicine(treatment_medicine);
                setActiveBookMedicine(book_medicine);
                handleSavePosologyWhenTreament(treatment_medicine);
            }
        })
        .catch((error) => { handleReqErrorBasic(error); handleNotLoading()})
        ;
    };

    useEffect(() => {
        const _disabledValue = modalPosologyIsReadyToPost(patterns,posologyTypeSelected);

        setCanSendPosology(_disabledValue);
    }, [ posologyTypeSelected, patterns, canSendPosology ]);

    const _handleClose = () => {
        setEditPosologiesIsDisabled(false);
        handleClose();
    };

    return (
        <Modal show={show} backdrop="static" onHide={ () => setShowPosologyModal(false) } size={'xl'} animation={false}>
            <Modal.Header closeButton>
            {
                isDisabled === true ?
                <Modal.Title><span className="text-capitalize">{t('general.posology')}</span></Modal.Title> :
                currentPosologyID ?
                <Modal.Title>{t('pages.planning.texts.editPosology')}</Modal.Title>
                :
                <Modal.Title>{t('pages.planning.texts.createPosology')}</Modal.Title>

            }
            </Modal.Header>
            <Modal.Body>
                <EditPosologyModalContent isDisabled={isDisabled}/>
            </Modal.Body>
            <Modal.Footer>
                { !isDisabled ? (
                    <>
                    <Button variant="outline-primary" onClick={() => _handleClose()}>
                        {t('general.cancel')}
                    </Button>
                    <Button
                        disabled={!canSendPosology}
                        variant="primary"
                        onClick={() => {
                        activeMedicineIsProposal
                        ? handleSavePosology()
                        : handleCreatingPosologyFromTreatment()
                        ;
                    }}>{t('general.save')}
                    </Button>
                    </>
                ) : (
                    <Button variant="outline-primary" onClick={() => _handleClose()}>
                        {t('general.close')}
                    </Button>
                )}

            </Modal.Footer>
        </Modal>
    );
}

export default withTranslation()(ModalPosologies);
