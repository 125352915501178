import './styles.scss';
import Classnames from 'classnames';
// import { nanoid } from 'nanoid';

const ButtonAdder = ({
    id,
    value,
    onClick,
    addStep,
    disabled,
    fontSize,
    className,
    isFocused,
    removeStep,
    isDoseSelector,
}) => {

    const handleKeyPress = e => {
        if(e.code === 'Enter') {
            e.preventDefault();
        }
    };

    return (
        <div
            id={id}
            className={Classnames([
                "ButtonAdder btn d-flex align-items-center ",
                !isDoseSelector && 'w-100 h-100 p-0 ',
                isFocused ? "btn-primary " : "btn-outline-primary ",
                disabled && 'disabled',
                className,
            ])}
            style={{
                fontSize: fontSize && `${fontSize}px`
            }}
            onClick={onClick}
        >
            <button
                disabled={disabled}
                className={Classnames([
                    "w-100 h-100 border-0 text-center bg-transparent border-0",
                    isFocused ? "text-white" : "text-primary",
                    disabled && "text-muted"
                ])}
                onKeyPress={e => handleKeyPress(e)}
                onClick={e => addStep(e)}
                onContextMenu={e => removeStep(e)}
            >{value ? Number(value) : 0}
            </button>
        </div>
    );
}

//
export default ButtonAdder;