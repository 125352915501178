import { useState } from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { withTranslation } from "react-i18next";
import DayPickerInput from "react-day-picker/DayPickerInput";
import i18n from '../../../../../i18n';

// Libs
import MomentLocaleUtils, {
    formatDate,
    parseDate,
  } from "react-day-picker/moment";
import "moment/locale/es";

// Utils
import {
    DDMMYYYY,
    YYYYMMDD,
    getDateFromXdays
} from '../../../../../utils/dateUtils';
// import { getArraysForProposalsPage } from '../../../../../utils/proposalsUtils';
import { useTreatmentsProvider } from '../../../../../context/TreatmentsContext';
// import useAPI from '../../../../../hooks/useAPI';
// import { useAppProvider } from '../../../../../context/AppContext';
// import useToastCustom from '../../../../../hooks/useToastCustom';


const ModalStopMedicine = ({
    t,
    show,
    handleClose,
}) => {

    const { language } = i18n;

    const {
        activeBookMedicine,
        handleAddProposalMedicine,
        setShowStopMedicineModal
    } = useTreatmentsProvider();
    const [ start_date, setStartDate ] = useState(new Date());

    const handleDateChange = date => {
        const newDate = getDateFromXdays(date,1);
        setStartDate(newDate)
    };

    const handleSave = () => {
        const _book_medicine = activeBookMedicine;
        const _start_date = YYYYMMDD(start_date);
        handleAddProposalMedicine(_book_medicine,_start_date);
        setShowStopMedicineModal(false);
    }

    return (
        <Modal show={show} onHide={handleClose} size={'xl'} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{t('pages.proposals.stopMedicine')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row justify-content-center">
                    <div className="col col-md-4">
                        <label htmlFor="date2" className="form-label">
                            {t('pages.proposals.stopMedicine_label')}
                        </label>

                        <DayPickerInput
                            onDayChange={(date) => handleDateChange(date)}
                            formatDate={formatDate}
                            parseDate={parseDate}
                            format="DD-MM-YYYY"
                            placeholder={t('general.datePlaceholder')}
                            inputProps={{
                                className: "form-control form-control-lg bg-white",
                                readOnly: false,
                            }}
                            dayPickerProps={{
                                locale: language,
                                localeUtils: MomentLocaleUtils,
                                onDayClick: (date) => handleDateChange(date),
                                // disabledDays: [],
                                todayButton: t('date.today')
                            }}
                            value={DDMMYYYY(start_date)}
                        />
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={() => handleClose()}>{t('general.close')}</Button>
                <Button variant="primary" onClick={() => handleSave()}>{t('general.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default withTranslation()(ModalStopMedicine);