import { useRef, useState, useEffect } from 'react';
import {withTranslation} from 'react-i18next';
import Classnames from 'classnames';
import { useAppProvider } from '../../../../../context/AppContext';
import MessageRow from '../../../../../components/UI/MessageRow';
import MessageNotificationRow from '../../../../../components/UI/MessageNotificationRow';
import Button from '../../../../../components/UI/Button';
import { useTreatmentsProvider } from '../../../../../context/TreatmentsContext';
import useAPI from '../../../../../hooks/useAPI';
import { toast } from "react-toastify";
import { nanoid } from 'nanoid';

const OffsetMessagesContainer = ({
    t,
}) => {

    // Ref
    const inputRef = useRef();
    const wrapperRef = useRef();
    const wrapperBodyRef = useRef();

    // State
    const [ message, setMessage ] = useState(null);
    // const [ messagesPage, setMessagesPage ] = useState(1);
    const [ disableSendButton, setDisableSendButton ] = useState(true);

    // Providers
    const { messages, activePatient, getMessages, messagesPage, setMessagesPage } = useTreatmentsProvider();
    const { messagesVisible, setMessagesVisible } = useAppProvider();
    const { API, handleReqError } = useAPI();

    // Effects
    useEffect(() => {
        if(messages.length <= 10){
            wrapperBodyRef.current.scrollTop = 0;
        }
    }, [messages]);

    // Methods
    const handleClose = () => {
        wrapperRef.current.classList.remove('show');
        setTimeout(() => {
            setMessagesVisible(false);
        },200);
    };

    const handleMessageChange = e => {
        if(e.target.value.trim() != ''){
            setMessage(e.target.value);
            setDisableSendButton(false);
        }
    };

    const handleKeyPress = (event) =>{
        if(event.code == 'Enter' && event.target.value.trim() != ''){
            setMessage(event.target.value);
            setDisableSendButton(false);
            sendMessage();
        }
    };

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop >= e.target.clientHeight;
        if (bottom && messagesPage) {
            getMessages(activePatient, messagesPage);
        }
    };

    const sendMessage = () => {
        inputRef.current.blur();
        inputRef.current.value = '';
        inputRef.current.textContent = '';
        inputRef.current.focus();
        wrapperBodyRef.current.scrollTop = 0;

        const url = 'patient-messages/';
        API({
            method: 'post',
            url: url,
            data: {
                patient: activePatient,
                data: {
                    text: message
                },
                topic: 'user-message',
            },
        })
        .then((response) => {
            if (response.status === 200 || response.status === 201) {
                setMessagesPage(1);
                getMessages(activePatient, 1);
                toast(
                    t("general.changesSavedProperly"),
                    {
                    position: toast.POSITION.TOP_CENTER,
                    type: toast.TYPE.SUCCESS,
                    }
                );
                setMessage('');
                setDisableSendButton(true);
            }
        })
        .catch((error) => {
            handleReqError(error);
            setMessage('');
            setDisableSendButton(true);
        });
    };

    // Conditions
    const hasMessages = messages && messages.length > 0;

    return (
        <div
            className={Classnames([
                "offcanvas offcanvas-end",
                messagesVisible && "show active"
            ])}
            tabIndex="-1"
            id="offcanvas"
            aria-labelledby="offcanvasLabel"
            ref={wrapperRef}
        >
            <div className="offcanvas-header">
                <h6 className="offcanvas-title d-flex" id="offcanvasLabel">
                    <span className="material-icons-outlined me-1">question_answer</span>
                    {t('pages.proposals.messages_logs')}
                </h6>
                <button
                    type="button"
                    className="btn-close text-reset"
                    aria-label="Close"
                    onClick={() => handleClose()}
                ></button>
            </div>
            <div
                className="offcanvas-body"
                onScroll={(ev) => handleScroll(ev)}
                ref={wrapperBodyRef}
            >
                <div className="d-flex flex-column h-100">
                    { hasMessages ? (
                        <ul
                            className="list-group rounded-0 pb-4 border"
                            style={{
                                backgroundColor: "#f8f8f8",
                                padding: "8px"
                            }}
                        >
                            { messages.map(message => (
                                message.topic === 'user-message' ?
                                <MessageRow message={message} key={nanoid()}/>
                                :
                                <MessageNotificationRow message={message} key={nanoid()}/>
                            ))}
                        </ul>
                    ) : (
                        <p className="text-muted">{t('general.noMessages')}</p>
                    )}
                </div>
            </div>
            <div className="offcanvas-footer border-top">
                <div className="py-1 px-3">
                    <label
                        className="form-label mb-1 text-capitalize"
                        htmlFor="messageTextArea"
                        style={{
                            fontSize: '13px'
                        }}
                    >{t('general.message')}</label>
                    <textarea
                        ref={inputRef}
                        placeholder={t('general.typeHere')}
                        className="form-control mb-1"
                        id="messageTextArea"
                        rows="3"
                        style={{
                            fontSize: '13px',
                            resize: 'none'
                        }}
                        onKeyPress={(ev) => handleKeyPress(ev)}
                        onChange={e => handleMessageChange(e)}
                    />
                    <Button
                        disabled={disableSendButton}
                        type="submit"
                        stylingClass={'btn-sm text-capitalize px-4'}
                        onClick={ () => sendMessage() }
                    >{t('general.send')}</Button>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(OffsetMessagesContainer);