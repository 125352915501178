import SelectAnHour from './../Modals/ModalComponents/SelectAnHour';
import SelectMonthDay from './../Modals/ModalComponents/SelectMonthDay';

import './styles.css';

import { useState } from 'react';

import ButtonAdder from './ButtonAdder';
import { useTreatmentsProvider } from '../../../../context/TreatmentsContext';
import useToastCustom from '../../../../hooks/useToastCustom';
import { nanoid } from 'nanoid';
import { withTranslation } from 'react-i18next';
import Classnames from 'classnames';

const RowComponent = ({
    t,
    index,
    disabled,
    intake_time,
    interval_active_period,
    interval_inactive_period,
    month_start_day,
    month_end_day,
    quantity,
    removeRow,
    step
}) => {

    const {
        patterns,
        setPatterns
    } = useTreatmentsProvider();

    const addStep = (e, index) => {
        e.preventDefault();
        let _patterns = [...patterns];
       _patterns[index].quantity = Number(_patterns[index].quantity) + step;
        setPatterns([..._patterns]);
    };

    const removeStep = (e, index) => {
        e.preventDefault();
        let _patterns = [...patterns];
        let quantity = _patterns[index].quantity - step;
        quantity = quantity < 0 ? 0 : quantity;
        _patterns[index].quantity = quantity;
        setPatterns([..._patterns]);
    };

    const addMonthsYes = (e, index) => {
        e.preventDefault();
        let _patterns = [...patterns];
       _patterns[index].interval_active_period = _patterns[index].interval_active_period + 1;
        setPatterns([..._patterns]);
    };

    const removeMonthsYes = (e, index) => {
        e.preventDefault();
        let _patterns = [...patterns];
        let interval_active_period = _patterns[index].interval_active_period - 1;
        interval_active_period = interval_active_period < 0 ? 0 : interval_active_period;
        _patterns[index].interval_active_period = interval_active_period;
        setPatterns([..._patterns]);
    };

    const addMonthsNo= (e, index) => {
        e.preventDefault();
        let _patterns = [...patterns];
       _patterns[index].interval_inactive_period = _patterns[index].interval_inactive_period + 1;
        setPatterns([..._patterns]);
    };

    const removeDaysNo = (e, index) => {
        e.preventDefault();
        let _patterns = [...patterns];
        let interval_inactive_period = _patterns[index].interval_inactive_period - 1;
        interval_inactive_period = interval_inactive_period < 0 ? 0 : interval_inactive_period;
        _patterns[index].interval_inactive_period = interval_inactive_period;
        setPatterns([..._patterns]);
    };

    const onDayStartChange = (day,intake_time, index) => {
        let _patterns = [...patterns];
        _patterns[index].month_start_day = day;
        setPatterns([..._patterns]);
    };

    const onDayEndChange = (day,intake_time, index) => {
        let _patterns = [...patterns];
        _patterns[index].month_end_day = day;
        setPatterns([..._patterns]);
    };

    const [elSelected, setElSelected ] = useState(null);
    return (
        <tr>
            <td className="position-relative">
                <span className={Classnames([
                    disabled && 'text-muted'
                ])}>{intake_time}</span>
                <button
                    disabled={disabled}
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    style={{
                        position: 'absolute',
                        top: '6px',
                        right: '32px',
                        fontSize: '8px',
                        backgroundColor: 'white',
                        border: '1px solid black',
                        borderRadius: '50%',
                        padding: '4px',
                    }}
                    onClick={ () => removeRow(intake_time,index) }
                ></button>
            </td>
            <td>
                <ButtonAdder
                    disabled={disabled}
                    key={nanoid()}
                    isFocused={elSelected === 'quantity'}
                    onClick={() => setElSelected('quantity')}
                    id={"quantity"}
                    value={Number(quantity)}
                    addStep={ e => addStep(e,index)}
                    removeStep={e => removeStep(e,index)}
                />
            </td>
            <td>
                <ButtonAdder
                    disabled={disabled}
                    key={nanoid()}
                    isFocused={elSelected === 'active_p'}
                    onClick={() => setElSelected('active_p')}
                    id={"interval_active_period"}
                    value={interval_active_period}
                    addStep={ e => addMonthsYes(e,index)}
                    removeStep={e => removeMonthsYes(e,index)}
                /></td>
            <td>
                <ButtonAdder
                    disabled={disabled}
                    key={nanoid()}
                    isFocused={elSelected === 'inactive_p'}
                    onClick={() => setElSelected('inactive_p')}
                    id={"interval_inactive_period"}
                    value={interval_inactive_period}
                    addStep={ e => addMonthsNo(e,index)}
                    removeStep={e => removeDaysNo(e,index)}
                />
            </td>
            <td>
                <SelectMonthDay
                    disabled={disabled}
                    key={nanoid()}
                    value={month_start_day}
                    onChange={day => onDayStartChange(day,intake_time,index)}
                /></td>
            <td>
                <SelectMonthDay
                    disabled={disabled}
                    key={nanoid()}
                    value={month_end_day}
                    onChange={day => onDayEndChange(day,intake_time,index)}
                /></td>
        </tr>
    )
};

const TableMonthly = ({
    t,
    step,
    disabled
}) => {

    const {
        patterns,
        setPatterns
    } = useTreatmentsProvider();

    const {
        showErrorMessage
    } = useToastCustom();

    let intake_timeRows = patterns ? [...patterns] : [];

    for (let index = 0; index < intake_timeRows.length; index++) {
        let row = intake_timeRows[index];
        if(row !== undefined && row.intake_time !== undefined) {
            if(row.intake_time.length > 5 ) {
                intake_timeRows[index].intake_time = row.intake_time.slice(0,-3);
            };
        }
    }

    const handleRowAdd = intake_time => {
        let _intake_timeRows = [...intake_timeRows];
        if(_intake_timeRows.findIndex(row => row.intake_time === intake_time) !== -1) {
            showErrorMessage('intake_time already exists');
        } else {
            const rowObj = {
                "intake_time": intake_time,
                "quantity": 0,
                "interval_active_period": 0,
                "interval_inactive_period": 0

            };
            _intake_timeRows.push(rowObj);
            _intake_timeRows.sort(function(a, b) {
                const keyA = a.intake_time,
                  keyB = b.intake_time;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
              });
            setPatterns([..._intake_timeRows]);
        };
    };

    const removeRow = intake_time => {
        let _patterns = [...patterns];
        const index = _patterns.findIndex((obj) => obj["intake_time"] === intake_time);
        _patterns.splice(index, 1);
        setPatterns([..._patterns]);
    };

    return (
        <>
        <SelectAnHour disabled={disabled} onChange={e => handleRowAdd(e)}/>
        <table className="table table-layout-fixed" onContextMenu={e => e.preventDefault()}>
            <thead>
                <tr>
                    <th scope="col">{t('general.hours')}</th>
                    <th scope="col">{t('pages.planning.texts.doseQuantity')}</th>
                    <th scope="col">{t('modals.monthsRowTakes')}</th>
                    <th scope="col">{t('modals.monthsRowStops')}</th>
                    <th scope="col">{t('modals.startDay')}</th>
                    <th scope="col">{t('modals.endDay')}</th>
                </tr>
            </thead>
            <tbody>
                { intake_timeRows.map((intake_timeRow, index) => (
                    <RowComponent
                        disabled={disabled}
                        step={step}
                        key={nanoid()}
                        index={index}
                        intake_time={intake_timeRow.intake_time}
                        interval_inactive_period={intake_timeRow.interval_inactive_period}
                        interval_active_period={intake_timeRow.interval_active_period}
                        month_start_day={intake_timeRow.month_start_day}
                        month_end_day={intake_timeRow.month_end_day}
                        quantity={intake_timeRow.quantity}
                        removeRow={intake_time => removeRow(intake_time)}
                    />
                ))}
            </tbody>
        </table>
        </>
    );
}

export default withTranslation()(TableMonthly);