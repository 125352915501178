import TreatmentsPageContent from './components/layout/TreatmentsPageContent';
import OffsetMessagesContainer from "./components/ui/OffsetMessagesContainer";

// Context & Provider
import { TreatmentsProvider } from '../../context/TreatmentsContext';

// Styles
import './styles.scss';

const Proposals = () => {
    return (
        <TreatmentsProvider>
            <TreatmentsPageContent />
            <OffsetMessagesContainer />
        </TreatmentsProvider>
    );
}
 
export default Proposals;