import { useState } from 'react';
import SelectAnHour from './../Modals/ModalComponents/SelectAnHour';
import './styles.css';
import ButtonAdder from './ButtonAdder';

// Libs
import { withTranslation } from 'react-i18next';
import { useTreatmentsProvider } from '../../../../context/TreatmentsContext';
import useToastCustom from '../../../../hooks/useToastCustom';
import ButtonAdderInput from './ButtonAdderInput';
import Classnames from 'classnames';

const RowComponent = ({
    step,
    index,
    disabled,
    quantity,
    removeRow,
    intake_time,
    interval_active_period,
    interval_inactive_period,
}) => {

    const {
        patterns,
        setPatterns
    } = useTreatmentsProvider();

    const [elSelected, setElSelected] = useState(null);

    const addStep = e => {
        e.preventDefault();
        const id = e.target.parentElement.id;
        let _patterns = [...patterns];
        let index = _patterns.findIndex(item => item.intake_time === id );
       _patterns[index].quantity = Number(_patterns[index].quantity) + step;
        setPatterns([..._patterns]);
        setQuantity(Number(_patterns[index].quantity));
    };

    const removeStep = e => {
        e.preventDefault();
        const id = e.target.parentElement.id;
        let _patterns = [...patterns];
        let index = _patterns.findIndex(item => item.intake_time === id );
        let quantity = Number(_patterns[index].quantity) - step;
        quantity = quantity < 0 ? 0 : quantity;
        _patterns[index].quantity = quantity;
        setPatterns([..._patterns]);
        setQuantity(quantity);
    };

    const handleInactiveChange = (value,index) => {
        let _patterns = [...patterns];
       _patterns[index].interval_inactive_period = value;
        setPatterns([..._patterns]);
        setInactive(value);
    };

    const handleActiveChange = (value,index) => {
        let _patterns = [...patterns];
       _patterns[index].interval_active_period = value;
        setPatterns([..._patterns]);
        setActive(value);
    };

    const [_quantity, setQuantity] = useState(quantity ? Number(quantity) : undefined)
    const [_interval_active_period, setActive] = useState(interval_active_period ? interval_active_period : undefined)
    const [_interval_inactive_period , setInactive] = useState(interval_inactive_period ? interval_inactive_period : undefined)


    return (
        <tr key={index}>
            <td className="position-relative">
                <span className={Classnames([
                    disabled && 'text-muted'
                ])}>{intake_time}</span>
                <button
                    disabled={disabled}
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    style={{
                        position: 'absolute',
                        top: '6px',
                        right: '32px',
                        fontSize: '8px',
                        backgroundColor: 'white',
                        border: '1px solid black',
                        borderRadius: '50%',
                        padding: '4px',
                    }}
                    onClick={ () => removeRow(intake_time) }
                ></button>
            </td>
            <td>
                <ButtonAdder
                    disabled={disabled}
                    key={`intake-${index}`}
                    isFocused={elSelected === 'intake_time'}
                    id={intake_time}
                    value={_quantity}
                    addStep={ e => addStep(e)}
                    removeStep={ e => removeStep(e)}
                    onClick={() => setElSelected('intake_time') }
                /></td>
            <td>
                <ButtonAdderInput
                    disabled={disabled}
                    key={`active-${index}`}
                    isFocused={elSelected === 'active_period'}
                    isInput={true}
                    id={intake_time}
                    value={_interval_active_period}
                    handleOnChange={value => handleActiveChange(value,index)}
                    onClick={() => setElSelected('active_period')}
                /></td>
            <td>
                <ButtonAdderInput
                    disabled={disabled}
                    key={`inactive-${index}`}
                    isFocused={elSelected === 'inactive_period'}
                    isInput={true}
                    id={intake_time}
                    value={_interval_inactive_period}
                    handleOnChange={value => handleInactiveChange(value,index)}
                    onClick={() => setElSelected('inactive_period')}
                />
            </td>
        </tr>
    );
};


const TableInterval = ({
    t,
    step,
    disabled
}) => {

    const {
        patterns,
        setPatterns
    } = useTreatmentsProvider();

    const {
        showErrorMessage
    } = useToastCustom();

    let intake_timeRows = patterns ? [...patterns] : [];

    for (let index = 0; index < intake_timeRows.length; index++) {
        let row = intake_timeRows[index];
        if(row !== undefined && row.intake_time !== undefined) {
            if(row.intake_time.length > 5 ) {
                intake_timeRows[index].intake_time = row.intake_time.slice(0,-3);
            };
        }
    }


    const handleRowAdd = intake_time => {
        let _intake_timeRows = [...intake_timeRows];
        if(_intake_timeRows.findIndex(row => row.intake_time === intake_time) !== -1) {
            showErrorMessage('intake_time already exists');
        } else {
            const rowObj = {
                "intake_time": intake_time,
                "quantity": 0,
                "interval_active_period": 0,
                "interval_inactive_period": 0

            };
            _intake_timeRows.push(rowObj);
            _intake_timeRows.sort(function(a, b) {
                const keyA = a.intake_time,
                  keyB = b.intake_time;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
              });
            setPatterns([..._intake_timeRows]);
        };
    };

    const removeRow = intake_time => {
        let _patterns = [...patterns];
        const index = _patterns.findIndex((obj) => obj["intake_time"] === intake_time);
        _patterns.splice(index, 1);
        setPatterns([..._patterns]);
    };


    return (
        <>
        <SelectAnHour disabled={disabled} onChange={intake_time => handleRowAdd(intake_time)}/>
        <table className="table table-layout-fixed" onContextMenu={e => e.preventDefault()}>
            <thead>
                <tr>
                    <th scope="col">{t('general.hours')}</th>
                    <th scope="col">{t('pages.bookMedicines.fields.strength')}</th>
                    <th scope="col">{t('pages.planning.texts.daysTaking')}</th>
                    <th scope="col">{t('pages.planning.texts.daysNOTTaking')}</th>
                </tr>
            </thead>
            <tbody>
                { intake_timeRows.map((intake_timeRow,index) => (
                    <RowComponent
                        disabled={disabled}
                        step={step}
                        index={index}
                        key={index}
                        intake_time={intake_timeRow.intake_time}
                        interval_inactive_period={intake_timeRow.interval_inactive_period}
                        interval_active_period={intake_timeRow.interval_active_period}
                        quantity={intake_timeRow.quantity}
                        removeRow={intake_time => removeRow(intake_time)}
                    />
                ))}
            </tbody>
        </table>
        </>
    );
}

export default withTranslation()(TableInterval);