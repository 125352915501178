// Libs
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import { withTranslation } from 'react-i18next';

// styles
import './styles.scss';

const Pagination = ({
    totalPages,
    currentPage,
    setCurrentPage,
    isBig,
    isSmall,
    screenReader,
    t
}) => {
    const pagesItems = [];
    
    for (let index = 1; index < totalPages + 1; index++) {
        pagesItems[index] = (
            <li key={nanoid()} className={classNames({
                "page-item": true,
                "active": index === currentPage
            })}>
                <button
                    type="button"
                    className="page-link"
                    onClick={() => setCurrentPage(index)}
                >{index}</button>
        </li>
        )
    };

    return (
        <>
        {totalPages > 1 && (
            <nav aria-label="Page navigation">
                <ul className={classNames({
                    "pagination": true,
                    "pagination-lg": isBig,
                    "pagination-sm": isSmall
                })}>
                    {totalPages > 2 && 
                        <li className="page-item">
                                <button
                                    type="button"
                                    className="page-link"
                                    aria-label={t('general.previous')}
                                    disabled={currentPage === 1}
                                    onClick={() => currentPage - 1 !== 0 && setCurrentPage(currentPage - 1) }
                                >
                                    { screenReader
                                        ?
                                            <span aria-hidden="true">&laquo;</span>
                                        :
                                            <span>{t('general.previous')}</span>
                                    }
                                    
                                </button>
                            </li>
                    }
                    { pagesItems }
                    {totalPages > 2 && 
                        <li className="page-item">
                        <button
                            type="button"
                            className="page-link"
                            aria-label={t('general.next')}
                            disabled={currentPage === totalPages}
                            onClick={() => currentPage + 1 <= totalPages && setCurrentPage(currentPage + 1)}
                        >
                            { screenReader
                                ?
                                    <span aria-hidden="true">&raquo;</span>
                                :
                                <span>{t('general.next')}</span>
                            }
                        </button>
                    </li>
                    }
                </ul>
            </nav>
        )}
        </>
    );
}
 
export default withTranslation()(Pagination);;
