import { useAppProvider } from '../../../context/AppContext';

const IsLoading = () => {
    const { isLoading } = useAppProvider();
    return isLoading && (
        <div
            style={{
                position: 'fixed',
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                zIndex: '99999999',
                height: '100vh',
                width: '100vw',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div>
                <div
                    className="spinner-border text-secondary"
                    role="status"
                    style={{
                        width: '3rem',
                        height: '3rem'
                    }}
                >
                    <span className="visually-hidden"></span>
                </div> 
            </div>
        </div>
    );
}
 
export default IsLoading;