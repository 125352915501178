import { useState } from 'react';
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Classnames from 'classnames';

// Libs
import { withTranslation } from "react-i18next";
import ButtonAdderInput from '../../Table/ButtonAdderInput';

const DoseSelector = ({
    t,
    disabled,
    doseSelected,
    setDoseSelected,
}) => {

    const [customDose, setCustomDose] = useState(null);
    const [doseActive, setDoseActive] = useState("1");

    const handleCustomDose = (value) => {
        setCustomDose(Number(value));
        setDoseSelected(Number(value));
        setDoseActive('custom');
    };

    const handleDoseChange = number => {
        setDoseSelected(number);
        setDoseActive(number.toString());
    };

    const handleKeyPress = e => {
        console.log("click: " + e)
        if(e.code === 'Enter') {
            e.preventDefault();
        }
    };

    const handleDoseActive = value => {
        setDoseActive('custom');
        handleCustomDose(value);
    };

    return (
        <div className="DoseSelector">
            <label className="form-label">{t('pages.planning.texts.doseQuantity')}</label>
            <br />

            <div className="d-flex">
                <ButtonGroup toggle className="flex-fill mb-2 w-100 me-1">
                    <ToggleButton
                        type="checkbox"
                        variant=""
                        checked={doseActive === "1"}
                        value="1"
                        onClick={() =>  handleDoseChange(1)}
                        className={'btn btn-outline-primary btn-lg ToggleButton'}
                        disabled={disabled}
                    >1</ToggleButton>
                </ButtonGroup>
                <ButtonGroup toggle className="flex-fill mb-2 w-100 me-1">
                    <ToggleButton
                        type="checkbox"
                        variant=""
                        checked={doseActive === '0.5'}
                        value="0.5"
                        onClick={() =>  handleDoseChange(0.5)}
                        className={'btn btn-outline-primary btn-lg ToggleButton'}
                        disabled={disabled}
                    >0.5</ToggleButton>
                </ButtonGroup>
                <ButtonGroup toggle className="flex-fill mb-2 w-100 me-1">
                    <ToggleButton
                        type="checkbox"
                        variant=""
                        checked={doseActive === '0.25'}
                        value="0.25"
                        onClick={() =>  handleDoseChange(0.25)}
                        className={'btn btn-outline-primary btn-lg ToggleButton'}
                        disabled={disabled}
                    >0.25</ToggleButton>
                </ButtonGroup>

                <ButtonAdderInput
                    key={'customSelector'}
                    fontSize={20}
                    value={ customDose }
                    step="0.01"
                    handleOnChange={ value => handleCustomDose(value)}
                    isDoseSelector={true}
                    className={Classnames([
                        "form-control form-control-sm p-0 flex-fill w-100 text-center border-primary mb-2",
                        doseSelected === 10 && 'btn-primary text-white',
                        doseSelected !== 10 && 'text-primary'
                    ])}
                    isFocused={doseActive === 'custom' }
                    onClick={e => handleDoseActive(e.target.value)}
                    disabled={disabled}
                />
            </div>
        </div>
    );
}

export default withTranslation()(DoseSelector);

