const ROUTES = {
  ROOT: {
    INDEX: '/',
    LOGIN: '/login'
  },

  HOSPITALS: {
    INDEX: '/hospitals',
    HOSPITAL: '/hospitals/:hospital_id',
    NEW: '/hospitals/new/'
  },

  PATIENTS: {
    INDEX: '/patients',
    PATIENT: '/patients/:patient_id',
    NEW: '/patients/new/',
    PATIENT_PAGE: '/patients/patient/:patient_id'
  },

  THERAPEUTIC_BOOK: {
    INDEX: '/therapeutic-book-medicines',
    MEDICINE: '/therapeutic-book-medicines/:medicine_id',
    NEW: '/therapeutic-book-medicines/new/'
  },

  PLANNING: {
    INDEX: '/planning',
  },

  PROPOSALS: {
    INDEX: '/proposals',
  },

  PRODUCTIONS: {
    INDEX: '/productions',
    PRODUCTION: 'productions/:production_id',
    NEW: '/productions/new/'
  }
};

export default ROUTES;