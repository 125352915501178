import { useState } from 'react';
import { withTranslation } from 'react-i18next';

const SelectAnHour = ({ t, onChange, disabled }) => {
    const [value, setValue] = useState(null);

    const moments = [];
    for (let index = 0; index < 24; index++) {
        const value = index < 10 ? `0${index}` : index;
        moments.push(`${value}:00`);
        moments.push(`${value}:30`);
    }

    const handleSelect = e => {
        const hour = e.target.value;
        setValue(hour)
        onChange(hour);
    };

    const handleKeyPress = e => {
        if(e.code === 'Enter') {
            e.preventDefault();
        }
    };

    return (
        <>
        <label>{t('general.addHour')}</label>
        <br/>
        <select
            disabled={disabled}
            className="form-select w-auto mb-2"
            onChange={ev => handleSelect(ev)}
            value={value ? value : ''}
            onKeyPress={e => handleKeyPress(e)}
        >
            <option>HH:mm</option>
            {
                moments.map(moment => (
                    <option
                        key={moment}
                        value={moment}
                    >{moment}</option>
                ))
            }
        </select>
        </>
    );
}

export default withTranslation()(SelectAnHour);