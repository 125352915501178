import { useTreatmentsProvider } from '../../../../../context/TreatmentsContext';
import DaysHeaderRow from '../DaysHeaderRow';
import MedicinesList from '../MedicinesList';
import PosologiesGrid from '../PosologiesGrid';

import './styles.scss';

// Libs
import { withTranslation } from 'react-i18next';

const TreatmentsGrid = ({ t }) => {

    const { setShowMedicinesModal, activePatient } = useTreatmentsProvider(); 

    return (
        <div className="TreatmentsGrid pt-2">
            <div className="AddMedicineButton d-flex align-items-center px-2 border-end">
                <button className="btn btn-primary w-100 mb-2"  disabled={activePatient === null} onClick={() => setShowMedicinesModal(true)}>
                    <span className="material-icons">add</span> 
                    <span>{t('pages.bookMedicines.texts.addMedicine')}</span>
                </button>
            </div>
            <DaysHeaderRow />
            <MedicinesList />
            <PosologiesGrid />
        </div>
    );
}

export default withTranslation()(TreatmentsGrid);