import { nanoid } from "nanoid";
import { withTranslation } from 'react-i18next';
import { bytesToUnits } from "../../../../utils/generalUtils";

const FileList = ({
    t,
    files,
    handleDeleteFile
}) => {

    const getFileType = str => {
        let result;
        if(str.includes('image')){
            result = 'image';
        } else if(str.includes('pdf')){
            result = 'pdf';
        } else {
            result = 'document';
        }
        return result;
    };

    const getIconFileType = str => {
        let result;
        if(str.includes('image')){
            result = 'image';
        } else {
            result = 'description';
        }
        return result;
    };

    return (
        <>
        <div
            className="list-group"
            style={{
                maxWidth: '600px'
            }}
        >
            { files && files.length > 0 ? files.map((file) => (
                <div className="list-group-item list-group-item-action d-flex" key={nanoid()}>
                    <div
                        className="FileImage img-thumbnail bg-light me-2 d-flex align-items-center justify-content-center"
                        style={{
                            width: '76px',
                            height: '76px',
                        }}
                    >
                        <span className="material-icons-outlined">
                            { getIconFileType(file.content_type) }
                        </span>
                    </div>
                    <div className="FileInfo w-100 d-flex justify-content-between">
                        <div>
                            <h5 className="mb-0">{ file.name }</h5>
                            <p className="mb-0">{ bytesToUnits(file.size,4) } · <span>{ getFileType(file.content_type) }</span></p>
                            <a target="_blank" download href={file.file} className="btn btn-sm btn-outline-primary py-0">{t('general.download')}</a>
                        </div>
                        <div>
                            <button
                                className="btn btn-outline-danger btn-sm me-2"
                                onClick={() => handleDeleteFile(file.id)}
                            >
                                <span className="material-icons">delete</span>
                            </button>
                        </div>
                    </div>
                </div>
            )) : <p className="my-2 text-muted"><span className=""></span>{t('general.noResults')}</p>}
        </div>
        </>
    );
}

export default withTranslation()(FileList);