export const LOCALE = {
    'en': 'en-IN',
    'es': 'es-ES',
    'pt': 'pt',
    'gr': 'el',
    'it': 'it',
}

export const SPANISH = {
    MONTHS: [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ],
    WEEKDAYS_LONG: [
        'Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'
    ],
    WEEKDAYS_SHORT: [
        'Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'
    ]
}

export const ENGLISH = {
    MONTHS: [
        'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
    ],
    WEEKDAYS_LONG: [
        'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'
    ],
    WEEKDAYS_SHORT: [
        'Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'
    ]
}

export const PORTUGUESE = {
    MONTHS: [
        'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
    ],
    WEEKDAYS_LONG: [
        'domingo', '2a feira', '3a feira', '4a feira', '5a feira', '6a feira', 'sabado'
    ],
    WEEKDAYS_SHORT: [
        'Do', '2a', '3a', '4a', '5a', '6a', 'Sa'
    ]
}
export const GREEK = {
    MONTHS: [
        'Ιανουάριος', 'Φεβρουάριος', 'Μάρτιος', 'Απρίλιος', 'Μάιος', 'Ιούνιος', 'Ιούλιος', 'Αύγουστος', 'Σεπτέμβριος', 'Οκτώβριος', 'Νοέμβριος', 'Δεκέμβριος'
    ],
    WEEKDAYS_LONG: [
        'Κυριακή', 'Δευτέρα', 'Τρίτη', 'Τετάρτη', 'Πέμπτη', 'Παρασκευή', 'Σάββατο'
    ],
    WEEKDAYS_SHORT: [
        'Κυρ', 'Δευ', 'Τρί', 'Τετ', 'Πεμ', 'Παρ', 'Σαβ'
    ]
}
export const ITALIAN = {
    MONTHS: [
        'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Decembre'
    ],
    WEEKDAYS_LONG: [
        'Domenica', 'Lunedi', 'Martedi', 'Mercoledi', 'Giovedi', 'Venerdi', 'Sabato'
    ],
    WEEKDAYS_SHORT: [
        'Do', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'
    ]
}

const DATE_LOCALE = {
    'es': SPANISH,
    'en': ENGLISH,
    'pt': PORTUGUESE,
    'gr': GREEK,
    'it': ITALIAN,
}

export default DATE_LOCALE;