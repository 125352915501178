// Libs & React
// import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

// Components
import FormInput from "../../../components/UI/FormInput";

// Router
import { Link, useHistory } from "react-router-dom";
import ROUTES from "../../../constants/ROUTES";
import { useAppProvider } from "../../../context/AppContext";

const UserInfoTabContent = ({ t, patient, isModal }) => {
  let history = useHistory();

  const { setOpenPatientModal } = useAppProvider();

  const handleClickEdit = () => {
    setOpenPatientModal(false);
    history.push(`/patients/${patient.id}/`);
  };

  return (
    <div className="py-2">
      {/* 1 */}
      <div className="row">
        <div className="col-md-8">
          <div className="row">
            <div className="col-md-4 mb-2">
              <FormInput
                id="first_name"
                value={patient.first_name ? patient.first_name : "-"}
                labelText={t("pages.patients.fields.firstName")}
                classnames={"border-0"}
                readOnly={true}
              />
            </div>

            <div className="col-md-4 mb-2">
              <FormInput
                id="last_name"
                value={patient.last_name ? patient.last_name : '-'}
                labelText={t("pages.patients.fields.lastName")}
                classnames={"border-0"}
                readOnly={true}
              />
            </div>

            <div className="col-md-4 mb-2">
              <FormInput
                id="printing_label_text"
                value={patient.printing_label_text ? patient.printing_label_text : '-'}
                labelText={t("pages.patients.fields.printingLabelText")}
                classnames={"border-0"}
                readOnly={true}
              />
            </div>

            <div className="col-md-4 mb-2">
              <FormInput
                id="hospital"
                value={patient && patient.hospital ? patient.hospital.name : '-'}
                labelText={t("pages.patients.fields.hospital")}
                classnames={"border-0"}
                readOnly={true}
              />
            </div>

            <div className="col-md-4 mb-2">
              <FormInput
                id="hospital_floor"
                value={
                  patient && patient.hospital_floor
                    ? patient.hospital_floor.name
                    : "-"
                }
                labelText={t("pages.patients.fields.hospitalFloor")}
                classnames={"border-0"}
                readOnly={true}
              />
            </div>
          </div>
        </div>
        <div
          className="col-md-4 py-2"
          style={{ backgroundColor: "rgb(222, 244, 255)" }}
        >
          <FormInput
            hasCopyBtn={true}
            id="socialSecurityNumber"
            value={patient.social_security_number}
            labelText={t("pages.patients.fields.socialSecurityNumber")}
            containerClasses={"mb-2"}
            classnames={"border-0"}
            readOnly={true}
          />
          <FormInput
            hasCopyBtn={true}
            id="socialSecurityBarcode"
            value={patient.social_security_barcode}
            labelText={t("pages.patients.fields.socialSecurityBarcode")}
            containerClasses={"mb-2"}
            classnames={"border-0"}
            readOnly={true}
          />
          <FormInput
            id="socialSecurityRegion"
            value={patient.social_security_region}
            labelText={t("pages.patients.fields.socialSecurityRegion")}
            containerClasses={"mb-2"}
            classnames={"border-0"}
            readOnly={true}
          />
        </div>
      </div>

      <hr />

      {/* 2 */}

      <div className="row">
        <div className="col-md-4">
          <FormInput
            id="date_of_birth"
            value={patient.date_of_birth ? patient.date_of_birth : "-"}
            labelText={t("pages.patients.fields.dateOfBirth")}
            classnames={"border-0"}
            readOnly={true}
          />
        </div>

        <div className="col-md-4">
          <FormInput
            id="doctorName"
            value={patient.doctor_name ? patient.doctor_name : "-"}
            labelText={t("pages.patients.fields.doctorName")}
            classnames={"border-0"}
            readOnly={true}
          />
        </div>

        <div className="col-md-4">
          <FormInput
            id="roomNumber"
            value={patient.room_number ? patient.room_number : "-"}
            labelText={t("pages.patients.fields.roomNumber")}
            classnames={"border-0"}
            readOnly={true}
          />
        </div>
      </div>

      {/* 3 */}

      <div className="row">
        <div className="col-md-3">
          <FormInput
            id="nationalIdentityCardNumber"
            value={
              patient.national_identity_card_number
                ? patient.national_identity_card_number
                : "-"
            }
            labelText={t("pages.patients.fields.nationalIdentityCardNumber")}
            classnames={"border-0"}
            readOnly={true}
          />
        </div>

        <div className="col-md-3">
          <FormInput
            id="contactPerson"
            value={patient.contact_person ? patient.contact_person : "-"}
            labelText={t("pages.patients.fields.contactPerson")}
            classnames={"border-0"}
            readOnly={true}
          />
        </div>

        <div className="col-md-3">
          <FormInput
            id="address"
            value={patient.address ? patient.address : "-"}
            labelText={t("pages.patients.fields.address")}
            classnames={"border-0"}
            readOnly={true}
          />
        </div>

        <div className="col-md-3">
          <FormInput
            id="town"
            value={patient.town ? patient.town : "-"}
            labelText={t("pages.patients.fields.town")}
            classnames={"border-0"}
            readOnly={true}
          />
        </div>
      </div>

      {/* 4*/}

      <div className="row">
        <div className="col-md-3">
          <FormInput
            id="country"
            value={patient.country ? patient.country : "-"}
            labelText={t("pages.patients.fields.country")}
            classnames={"border-0"}
            readOnly={true}
          />
        </div>

        <div className="col-md-3">
          <FormInput
            id="zipCode"
            value={patient.zipCode ? patient.zipCode : "-"}
            labelText={t("pages.patients.fields.zipCode")}
            classnames={"border-0"}
            readOnly={true}
          />
        </div>

        <div className="col-md-3">
          <FormInput
            id="phonePrefix"
            value={patient.phone_prefix ? patient.phone_prefix : "-"}
            labelText={t("pages.patients.fields.phonePrefix")}
            classnames={"border-0"}
            readOnly={true}
          />
        </div>

        <div className="col-md-3">
          <FormInput
            id="phoneNumber"
            value={patient.phone_number ? patient.phone_number : "-"}
            labelText={t("pages.patients.fields.phoneNumber")}
            classnames={"border-0"}
            readOnly={true}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 mt-5">
          {isModal ? (
            <button
              className="btn btn-outline-primary me-2"
              onClick={() => setOpenPatientModal(false)}
            >
              {t("general.close")}
            </button>
          ) : (
            <Link to={`${ROUTES.PATIENTS.INDEX}`}>
              <button className="btn btn-outline-primary me-2">
                {t("general.close")}
              </button>
            </Link>
          )}

          {isModal ? (
            <button
              type="button"
              className="btn btn-primary me-2"
              onClick={() => handleClickEdit()}
            >
              {t("general.edit")}
            </button>
          ) : (
            <Link to={`/patients/${patient.id}/`}>
              <button type="button" className="btn btn-primary me-2">
                {t("general.edit")}
              </button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(UserInfoTabContent);
