// React & Libs
import { useEffect, useState, useRef } from "react";

// Router
import { useHistory } from "react-router-dom";
import ROUTES from "../../../constants/ROUTES";

// Language
import i18n from "../../../i18n";
import { withTranslation } from "react-i18next";

// Contexts & Hooks
import { useAppProvider } from "../../../context/AppContext";
import useAPI from "../../../hooks/useAPI";

// Components
import Avatar from "../Avatar";

// Constants
import LANGUAGES from "../../../constants/LANGUAGES";
import ROLES from "../../../constants/ROLES";

// Styles
import "./styles.scss";

const UserDropdown = ({ t }) => {
  const [user, setUser] = useState({ username: null });
  const [userLanguage, setUserLanguage] = useState("es");
  const dropdownEl = useRef(null);
  const ddMenuEl = useRef(null);
  const { API, handleReqError } = useAPI();

  const { setUserRole, setModules } = useAppProvider();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setUserLanguage(lng);
  };

  useEffect(() => {
    API.get("users/current/")
      .then((response) => {
        if (response) {
          setUser(response.data);
          setUserRole(ROLES[response.data.role]);
          setModules(response.data.modules);
          changeLanguage(response.data.language);
          setUserLanguage(response.data.language);
        }
      })
      .catch((error) => handleReqError(error));

    return () => {
      setUser({ username: "" });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { setLoggedIn } = useAppProvider();
  let history = useHistory();

  const LogOut = () => {
    sessionStorage.removeItem("ti-medi-token");
    setLoggedIn(false);
    history.push(ROUTES.ROOT.LOGIN);
  };

  const handleDropddown = (ev) => {
    ev.stopPropagation();
  };

  const toggleDropdown = (dd, mn) => {
    dd.classList.remove("show");
    mn.classList.remove("show");
    dd.setAttribute("aria-expanded", false);
  };

  const handleSelect = (ev) => {
    setUserLanguage(ev.target.value);
    API.put(`/users/${user.id}/`, {
      language: ev.target.value,
    })
      .then((response) => {
        if (response) {
          changeLanguage(ev.target.value);
          toggleDropdown(dropdownEl.current, ddMenuEl.current);
        }
      })
      .catch((error) => handleReqError(error));
  };

  return (
    <div className="UserDropdown">
      <div className="dropdown">
        <button
          className="btn dropdown-toggle d-flex align-items-center UserDropdown__Btn"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          ref={dropdownEl}
        >
          <Avatar
            initial={user && user.username ? user.username.charAt(0) : null}
            className={"me-3"}
          />
          <span className="border-end pe-3 text-capitalize">
            {user.username}
          </span>
        </button>
        <ul
          className="dropdown-menu dropdown-menu-end py-0 shadow"
          aria-labelledby="dropdownMenuButton1"
          ref={ddMenuEl}
          onClick={(ev) => handleDropddown(ev)}
        >
          <li className="px-3 pt-2">
            <div className="d-flex align-items-center">
              <label className="d-flex align-items-center me-3">
                <span className="material-icons-outlined me-2 fs-5">
                  language
                </span>
                <span>{t("general.language")}: </span>
              </label>
              <select
                className="form-select cursor-pointer"
                aria-label="Default select example"
                onChange={(ev) => handleSelect(ev)}
                value={userLanguage}
                style={{ width: "auto" }}
              >
                {Object.keys(LANGUAGES).map((key) => (
                  <option key={key} value={key}>
                    {LANGUAGES[key]}
                  </option>
                ))}
              </select>
            </div>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li>
          <li className="px-3 pb-2">
            <div className="UserMenu">
              <button
                type="button"
                className="btn btn-link ps-0"
                onClick={LogOut}
                style={{
                  height: "38px",
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                }}
              >
                <span className="material-icons-outlined align-middle fs-5 me-2">
                  cancel
                </span>
                <span className="align-middle">
                  {t("general.close-session")}
                </span>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default withTranslation()(UserDropdown);
