import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { withTranslation } from "react-i18next";
import PatientPage from '../../../pages/PatientPage';

const ModalPatientCard = ({
    t,
    show,
    patientID,
    handleClose,
}) => {
    return (
        <Modal show={show} onHide={handleClose} animation={false} size="xl">
            <Modal.Header closeButton />
            <Modal.Body>
                <PatientPage patientID={patientID} isModal={true}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => handleClose()}>
                    {t('general.close')}
                </Button>
            </Modal.Footer>
        </Modal>
    
    );
}
 
export default withTranslation()(ModalPatientCard);