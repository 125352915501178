import ProposalFilterItem from './ProposalFilterItem';
import { withTranslation } from 'react-i18next';
import PROPOSALS_FILTERS from '../../../../../constants/PROPOSALS_FILTERS';
import classNames from 'classnames';
import { useTreatmentsProvider } from '../../../../../context/TreatmentsContext';
import Dropdown from 'react-bootstrap/Dropdown';
import { nanoid } from 'nanoid';

const ProposalsFilters = ({
    t,
}) => {

    const {
        proposalsFiltersValues,
        handleProposalsFilter
    } = useTreatmentsProvider();


    const DropdownItems = PROPOSALS_FILTERS.map((filter) => (
        proposalsFiltersValues['only_with_treatment_proposals'] === true &&
        <ProposalFilterItem
            key={nanoid()}
            id={filter.id}
            hide={!proposalsFiltersValues['only_with_treatment_proposals']}
            labelText={
                <div className={classNames([
                    'badge rounded-pill',
                    filter.bgColor
                ])}>
                    <span className="material-icons-outlined me-1 align-middle" style={{fontSize: '12px'}}>{filter.icon}</span>
                    <span className="align-middle">{filter.labelText}</span>
                </div>
            }
            onChange={e => handleProposalsFilter(e)}
            value={proposalsFiltersValues[filter.id]}
        />
    ));

    const DropdownContent = () => ([
        <ProposalFilterItem
            key={nanoid()}
            id={'only_with_treatment_proposals'}
            labelText={t('pages.proposals.onlyProposalsPatients')}
            onChange={e => handleProposalsFilter(e)}
            value={proposalsFiltersValues['only_with_treatment_proposals']}
        />, ...DropdownItems
    ]);

    return (

        <Dropdown>
            <Dropdown.Toggle variant="primary" id="dropdown-basic" className={'w-100 d-flex align-items-center justify-content-between mb-2'}>
                <>
                    <div className="d-flex justify-content-center">
                        <span className="material-icons-outlined me-2">tune</span>
                        <span className="align-baselines me-2">{t('general.filter')}</span>
                    </div>
                </>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <div className="p-2">
                    <DropdownContent />
                </div>
            </Dropdown.Menu>
        </Dropdown>

    );
}

export default withTranslation()(ProposalsFilters);