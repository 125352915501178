import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import './scss/custom-styles.scss';

//Libs
import 'react-toastify/dist/ReactToastify.css';
import './i18n';

// Context & Provider
import { AppProvider } from './context/AppContext';
import AppWrapper from './AppWrapper';

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <AppWrapper />
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
