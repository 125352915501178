import { useEffect, useState } from 'react';
import { nanoid } from "nanoid";
import Button from "react-bootstrap/Button";
import { useTreatmentsProvider } from "../../../../context/TreatmentsContext";
import useAPI from "../../../../hooks/useAPI";
import useToastCustom from "../../../../hooks/useToastCustom";

// Libs
import { withTranslation } from "react-i18next";
import Classnames from 'classnames';
import { DDMMYYYY, DDMMYYYYFromStringDate, YYYYMMDD, getDateFromXdays } from '../../../../utils/dateUtils';
import { getArraysForProposalsPage } from '../../../../utils/proposalsUtils';
import { addMedicineFilters } from "../../../../utils/generalUtils";

import FormCheckbox from "../../../../components/UI/FormCheckbox";
import { useAppProvider } from '../../../../context/AppContext';

const TablePosologiesList = ({t}) => {

    // Context
    const {
        activePatient,
        setMedicinePosologies,
        medicinePosologies,
        setActivePosologyID,
        setShowPosologyModal,
        setShowPosologiesModal,
        setActivePosologyWasProduced,
        displayedPosologies,
        setDispPosologies,
        activeMedicine,
        activeMedicineIsProposal,
        setActivePatientMedicines,
        setProposalsPatientMedicines,
        initialDate,
        setActivePatient,
        setShowStopMedicineModal,
        isStoppedMedicine,
        setEditPosologiesIsDisabled,
        medicinesFilters,
        proposalsPatientMedicines,
        setShowAlertInvalidation,
        setProducedModalMessage
    } = useTreatmentsProvider();

    const { handleLoading, handleNotLoading } = useAppProvider();

    const { API, handleReqError } = useAPI();
    const { showSuccessMessage } = useToastCustom();
    const [isEmpty, setisEmpty] = useState(displayedPosologies && displayedPosologies[0] === undefined);

    // State
    const [ filter, setFilter ] = useState(true);

    // Methods
    const _getProposalsAndMedicinesFromPatient = data => {
        handleLoading();
        setActivePatientMedicines(null);
        setProposalsPatientMedicines(null);

        const patient_id = data && data.patient_id ? data.patient_id : activePatient;
        const start_date = data && data.start_date ? data.start_date : initialDate;
        const _initialDate = start_date ? start_date : initialDate;
        const _start_date = YYYYMMDD(_initialDate);
        const _end_date = YYYYMMDD(getDateFromXdays(_initialDate, 14));

        if(patient_id) { setActivePatient(patient_id) };
        if(patient_id === (undefined || null)) { return };

        const _url = `treatments/?patient=${patient_id}&start_date=${_start_date}&end_date=${_end_date}&include_proposals=true`;
        const url = addMedicineFilters(_url, medicinesFilters);

        API.get(url)
            .then((response) => {
                handleNotLoading();
                if (response) {
                    const result = getArraysForProposalsPage(response.data.medicines);
                    setProposalsPatientMedicines([...result[0]]);
                    setActivePatientMedicines([...result[1]]);

                }
            })
            .catch((error) => {
                handleNotLoading();
                setProposalsPatientMedicines(null);
                setActivePatientMedicines(null);
            })
        ;
    };

    const getPosologies = (_filter) => {
        handleLoading();
        const __filter = _filter !== undefined ? _filter : filter;
        const url = __filter !== null && __filter !== undefined
        ? `posologies/?treatment_medicine=${activeMedicine}&active=${__filter}`
        : `posologies/?treatment_medicine=${activeMedicine}`
        ;
        API.get(url)
        .then((response) => {
            handleNotLoading();
            if (response) {
                setMedicinePosologies(response.data.results);
                setDispPosologies(response.data.results);
                setisEmpty(response.data.results[0] === undefined);
            }
        })
        .catch((error) => handleReqError(error));
    };

    const handleOpeningPosology = (posology_id,isProduced) => {
        handleLoading();
        setActivePosologyID(posology_id ? posology_id : null);
        setActivePosologyWasProduced(isProduced);
        setShowPosologyModal(true);
        setShowPosologiesModal(false);
        handleNotLoading();
    };

    const handleRemovePosology = (e,posology_id) => {
        handleLoading();
        e.stopPropagation();

        const url = `posologies/${posology_id}`;
        API.delete(url)
            .then( () => {
                getPosologies();
                // getMedicinesFromPatient({patient_id: activePatient});
                _getProposalsAndMedicinesFromPatient();
                showSuccessMessage(t('general.changesSavedProperly'));
                handleNotLoading();
            })
            .catch((error) => handleReqError(error))
        ;
    }

    const handleRemoveMedicine = (e) => {
        e.stopPropagation();
        handleLoading();

        const url = `treatment-medicines/${activeMedicine}`;
        API.delete(url)
            .then( () => {
                handleNotLoading();
                getPosologies();
                // getMedicinesFromPatient({patient_id: activePatient});
                _getProposalsAndMedicinesFromPatient();
                showSuccessMessage(t('general.changesSavedProperly'));
                setShowPosologiesModal(false);
            })
            .catch((error) => handleReqError(error))
        ;
    }

    const handleFilterChange = (e,filter) => {
        handleLoading();
        e.preventDefault();
        setFilter(filter);
        getPosologies(filter);
        handleNotLoading();
    };

    const stopMedicine = () => {
        setShowPosologiesModal(false);
        setShowStopMedicineModal(true);
    };

    const _handleOpeningPosology = (id,produced) => {
        if(activeMedicineIsProposal === false) {
            setEditPosologiesIsDisabled(true);
        } else {
            setEditPosologiesIsDisabled(false);
        }
        handleOpeningPosology(id,produced);
    };

    const [ cannotEditPosology, setCannotEditPosology ] = useState(false);

    const handleShowAlertInvalidation = () => {
        setShowPosologiesModal(false);
        setProducedModalMessage(true);
        setShowAlertInvalidation(true);
    }

    useEffect(() => {

        const cannotHandleProducedPosologies = () => {

            const currentMedObj = proposalsPatientMedicines.find(med => med.treatment_medicine === activeMedicine);
            const isApplied = currentMedObj && currentMedObj.proposal_is_applied ? currentMedObj.proposal_is_applied : false;
            const cannotHandle =  activeMedicineIsProposal && isApplied;

            if(cannotHandle) {
                setCannotEditPosology(true);
            } else {
                setCannotEditPosology(false);
            }
        };

        cannotHandleProducedPosologies();

    },[ medicinePosologies,activeMedicineIsProposal, activeMedicine, proposalsPatientMedicines]);

    return (
        <div>

            { isStoppedMedicine === false && activeMedicineIsProposal === false &&
            <div className="mb-3">
                <span className="d-inline-block me-4">{t('general.view')}</span>
                <FormCheckbox type={'radio'} value={filter === true}    labelText={t('general.activesF')}     containerClassName={'d-inline-block me-3'} onChange={e => handleFilterChange(e,true)}/>
                <FormCheckbox type={'radio'} value={filter === false}   labelText={t('general.inactivesF')}   containerClassName={'d-inline-block me-3'} onChange={e => handleFilterChange(e, false)}/>
                <FormCheckbox type={'radio'} value={filter === null}    labelText={t('general.allF')}         containerClassName={'d-inline-block me-3'} onChange={e => handleFilterChange(e, null)}/>
            </div>
            }

            { isStoppedMedicine === true &&
                    <div style={{minHeight: '100px'}}>{t('pages.proposals.medicineIsStopped')}</div>
            }

            { isStoppedMedicine === false && !isEmpty &&
            <>
                <table className="table table-striped">
                    <tbody>
                        <tr>
                            <td>{t('pages.planning.fields.type')}</td>
                            <td>{t('pages.planning.fields.startDate')}</td>
                            <td>{t('pages.planning.fields.endDate')}</td>
                            <td>{t('pages.planning.texts.ifNeeded')}</td>
                            <td>{t('pages.planning.texts.specialDosage')}</td>
                            <td>{t('pages.planning.texts.isProduced')}</td>
                            <td>{t('general.action')}</td>
                        </tr>

                    { displayedPosologies && displayedPosologies.map( ( posology ) => (
                        <tr
                            key={nanoid()}
                            onClick={ () => cannotEditPosology ? handleShowAlertInvalidation() : _handleOpeningPosology(posology.id,posology.produced)} // activeMedicineIsProposal === true &&
                            className={Classnames([
                                posology.produced === false && "cursor-pointer", // && activeMedicineIsProposal)
                                (posology.produced || activeMedicineIsProposal === false) && "cursor-pointer text-muted"
                            ])}
                        >
                            <td>{posology.type}</td>
                            <td>{DDMMYYYYFromStringDate(posology.start_date)}</td>
                            <td>{posology.end_date ? DDMMYYYYFromStringDate(new Date(posology.end_date)) : null}</td>
                            <td>{posology.if_needed && <span className="material-icons-outlined">done</span>}</td>
                            <td>{posology.special_dosage && <span className="material-icons-outlined">done</span>}</td>
                            <td>{posology.produced && <span className="material-icons-outlined text-success">done</span>}</td>
                            <td>
                                { !posology.produced && activeMedicineIsProposal && (
                                <button
                                    className='btn btn-outline-primary btn-sm me-2'
                                    onClick={(e) => cannotEditPosology ? handleShowAlertInvalidation() : handleRemovePosology(e,posology.id)}
                                >
                                    <span className='material-icons'>delete</span> <span>{t('general.deleteProposal')}</span>
                                </button>
                                )}
                                { activeMedicineIsProposal && (
                                <button
                                    className='btn btn-outline-primary btn-sm me-2'
                                >
                                    <span className='material-icons'>edit</span> <span>{t('general.editProposal')}</span>
                                </button>
                                )}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </>
            }

            { isStoppedMedicine === false && isEmpty === true && (
                <div style={{minHeight: '100px'}}>{t('general.noResults')}</div>
            )}

            { isStoppedMedicine === false &&
                <>

                <Button className="me-2" onClick={() => cannotEditPosology ? handleShowAlertInvalidation(): handleOpeningPosology()}>
                    + {t('pages.planning.texts.createProposalPosology')}
                </Button>

                { !activeMedicineIsProposal && <Button variant="danger" className="me-2" onClick={() => stopMedicine()}>
                    {t('pages.proposals.stopMedicine')}
                </Button> }
                </>
            }

            { ( (medicinePosologies && medicinePosologies[0] === undefined) ||  (isStoppedMedicine === true) ) && (
                <Button variant="outline-danger"  onClick={(e) => handleRemoveMedicine(e)}>
                    <span className="material-icons-outlined">delete</span> <span>{t('pages.proposals.deleteProposal')}</span>
                </Button>
            )}


        </div>
    );
}

export default withTranslation()(TablePosologiesList);

