// React & Libs
import { useEffect, useState, useRef } from 'react';
import useAPI from '../../../hooks/useAPI';
import { nanoid } from 'nanoid';
import { toast } from "react-toastify";
import { withTranslation } from 'react-i18next';

// Components
import MessageRow from '../../../components/UI/MessageRow';
import MessageNotificationRow from '../../../components/UI/MessageNotificationRow';
import Button from '../../../components/UI/Button';

const MessagesTabContent = ({
    t,
    patientId
}) => {

    // Ref
    const inputRef = useRef();

    // Hooks
    const { API, handleReqError } = useAPI();

    // State
    const [ patientMessages, setPatientMessages ] = useState([]);
    const [ message, setMessage ] = useState(null);
    const [ disableSendButton, setDisableSendButton ] = useState(true);

    // Methods
    const getMessages = () => {
        API.get(`patient-messages/?patient=${patientId}`)
        .then(response => {
            if(response.data.results && response.data.results.length > 0) {
                setPatientMessages(response.data.results);
            } else {
                setPatientMessages([]);
            }
        })
        .catch(error => handleReqError(error));
    };

    const handleMessageChange = e => {
        if(e.target.value.trim() !== ''){
            setMessage(e.target.value);
            setDisableSendButton(false);
        }
    };

    const handleKeyPress = (event) =>{
        if(event.code === 'Enter' && event.target.value.trim() !== ''){
            setMessage(event.target.value);
            setDisableSendButton(false);
            sendMessage();
        }
    };

    const sendMessage = () => {
        inputRef.current.value = '';
        const url = 'patient-messages/';
        API({
            method: 'post',
            url: url,
            data: {
                patient: patientId,
                data: {
                    text: message
                },
                topic: 'user-message',
            },
          })
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
                getMessages();
                toast(
                    t("general.changesSavedProperly"),
                    {
                    position: toast.POSITION.TOP_CENTER,
                    type: toast.TYPE.SUCCESS,
                    }
                );
            }
          })
          .catch((error) => handleReqError(error));
    };

    // Conditions
    const hasMessages = patientMessages && patientMessages.length > 0;

    useEffect(() => {
        getMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ patientId ] );

    return (
        <div style={{
            maxWidth: '960px    '
        }}>
            <div className="offcanvas-body">
                <div className="d-flex flex-column h-100">
                    { hasMessages ? (
                        <ul
                            className="list-group rounded-0 pb-4 border"
                            style={{
                                backgroundColor: "#f8f8f8",
                                padding: "8px"
                            }}
                        >
                            { patientMessages.map(message => (
                                message.topic === 'user-message' ?
                                <MessageRow message={message} key={nanoid()}/>
                                :
                                <MessageNotificationRow message={message} key={nanoid()}/>
                            ))}
                        </ul>
                    ) : (
                        <p className="text-muted">{t('general.noMessages')}</p>
                    )}
                </div>
            </div>
            <div className="offcanvas-footer border-top">
                <div className="py-1 px-3">
                    <label
                        className="form-label mb-1 text-capitalize"
                        htmlFor="messageTextArea"
                        style={{
                            fontSize: '13px'
                        }}
                    >{t('general.message')}</label>
                    <textarea
                        ref={inputRef}
                        placeholder={t('general.typeHere')}
                        className="form-control mb-1"
                        id="messageTextArea"
                        rows="3"
                        style={{
                            fontSize: '13px',
                            resize: 'none'
                        }}
                        onChange={e => handleMessageChange(e)}
                        onKeyPress={(ev) => handleKeyPress(ev)}
                    />
                    <Button
                        type="submit"
                        disabled={disableSendButton}
                        stylingClass={'btn-sm text-capitalize px-4'}
                        onClick={ () => sendMessage() }
                    >{t('general.send')}</Button>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(MessagesTabContent);