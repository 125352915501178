import TreatmentsPageContent from './components/layout/TreatmentsPageContent';

// Context & Provider
import { TreatmentsProvider } from '../../context/TreatmentsContext';

// Styles
import './styles.scss';

const Treatments = () => {
    return (
        <TreatmentsProvider>
            <TreatmentsPageContent />
        </TreatmentsProvider>
    );
}
 
export default Treatments;