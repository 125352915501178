import classNames from "classnames";
import { nanoid } from "nanoid";
import MedicinePosologiesGrid from "../MedicinePosologiesGrid";
import { withTranslation } from 'react-i18next';

const TreatmentItem = ({
    t,
    medicine,
    isProposalPage,
    treatmentsSelected,
    handleClickMedicine,
}) => {

    return (
        <div
            className="TreatmentItem__Wrapper"
        >
            <div
                className={classNames([
                    "TreatmentItem MedicineGridItem p-1",
                    treatmentsSelected && treatmentsSelected[medicine.name] === true ? 'active' : 'disabled',
                ])}
                style={{
                    display: 'grid',
                    gridTemplateColumns: '168px repeat(15, 80px)'
                }}
            >
                <div
                    className="MedicineGridItem__Title cursor-pointer"
                    style={{
                        gridColumn: "1/2",
                    }}
                    onClick={() => handleClickMedicine(medicine)}
                >
                    <small><small>
                        <div
                            className={classNames([
                                "border h-100 rounded p-2 text-break lh-1  d-flex flex-column justify-content-evenly",
                                medicine.is_blistable === false && "bg-orange-light border-orange",
                                medicine.is_blistable && medicine.posologies.length === 0 && "bg-light-grey",
                                medicine.is_blistable && medicine.posologies.length  > 0 && "bg-white"
                            ])}
                            title={medicine.name}
                        >
                            <p>{medicine.name}</p>
                            { isProposalPage && (
                            <button
                                className="btn btn-sm btn-primary d-flex align-items-center w-100 justify-content-center"
                            >
                                <span className="material-icons-outlined">bloodtype</span>
                                <span style={{fontSize: '9px'}}>{t('pages.proposals.modifyAsProposal')}</span>
                            </button>
                            )}
                        </div>
                    </small></small>
                </div>
                <div
                    className="MedicineGridItem__Posology"
                    style={{
                        gridColumn: "2/17",
                    }}
                >
                    <div className={`Posology Posology__Current`}
                    >
                        { medicine.posologies && medicine.posologies.map(posology => (
                            <MedicinePosologiesGrid
                                key={nanoid()}
                                posology={posology}
                                isNotBlistable={!medicine.is_blistable}
                                isProposalItem={false}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(TreatmentItem);
