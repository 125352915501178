import { useRef, useState } from 'react';
import { withTranslation } from "react-i18next";
import classNames from 'classnames';
import { useTreatmentsProvider } from '../../../context/TreatmentsContext';
import { MEDICINE_FILTERS_LABELS } from '../../../constants/MEDICINE_FILTERS';
import { nanoid } from 'nanoid';
import { orderMedicinesArray, addMedicineFilters } from '../../../utils/generalUtils';
import { YYYYMMDD, getDateFromXdays } from '../../../utils/dateUtils';
import { getArraysForProposalsPage } from '../../../utils/proposalsUtils';

import { useAppProvider } from '../../../context/AppContext';
import useAPI from '../../../hooks/useAPI';

const MedicinesFilter = ({
    t,
    style,
    isOnProposalsPage = false
}) => {

    const {
        initialDate,
        activePatient,
        medicinesFilters,
        setMedicinesFilters,
        setActivePatientMedicines,
        setProposalsPatientMedicines
    } = useTreatmentsProvider();

    const { API } = useAPI();

    const { handleLoading, handleNotLoading } = useAppProvider();

    const [isOpened, setIsOpened] = useState(false);

    const dropDownRef   = useRef(null);
    const containerRef  = useRef(null);

    // const handleBodyClick = e => {
    //     e.stopPropagation();
    //     const el = e.target;
    //     if(containerRef && containerRef.current !== null) {
    //         if(containerRef.current.contains(el) === false) {
    //             setIsOpened(false);
    //         };
    //     };
    // };

    const handleToggleDropdown = e => {
        const ariaExpandedValue = dropDownRef.current.getAttribute('aria-expanded') === "true";
        if(!ariaExpandedValue === true){
           setIsOpened(true);
        } else {
            setIsOpened(false);
        };
    };

    const getMeds = (filters) => {
        handleLoading();

        const _end_date = YYYYMMDD(getDateFromXdays(initialDate, 14));
        let url = `treatments/?patient=${activePatient}&start_date=${YYYYMMDD(initialDate)}&end_date=${_end_date}`;
        url = addMedicineFilters(url, filters);
        url = isOnProposalsPage ? url.concat('&include_proposals=true'): url;

        API.get(url)
            .then((response) => {
                handleNotLoading();
                if (response) {
                    if(isOnProposalsPage) {
                        const result = getArraysForProposalsPage(response.data.medicines);
                        setProposalsPatientMedicines([...result[0]]);
                        setActivePatientMedicines([...result[1]]);
                    } else {
                        setActivePatientMedicines(orderMedicinesArray(response.data.medicines));
                    }
                }
            })
            .catch((error) => {
                handleNotLoading();
                setActivePatientMedicines([]);
            })
        ;
    }

    const handleFilterChange = (e,filterName) => {
        e.stopPropagation();
        const currentValue = e.target.checked;
        let _medicinesFilters = { ...medicinesFilters };
        const result = { ..._medicinesFilters,  [filterName]: currentValue };
        setMedicinesFilters(result);
        getMeds(result);
    };

    return (
        <>
        {/* Non-Collapsed Version appears on 1600px  */}
        <div
            className="d-none d-xxxl-flex flex-column justify-content-between"
            style={ style }
        >
            <label>{t('pages.planning.filters.showLabel')}</label>
            <div className="d-flex">

            { Object.entries(medicinesFilters).map(filter => (
                <div
                    key={nanoid()}
                    className="form-check form-switch"
                    style={{ marginRight: '16px'}}
                >
                <input
                    onChange={e => handleFilterChange(e,filter[0])}
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    id={filter[0]}
                    checked={filter[1]}
                />

                <label
                    className="form-check-label cursor-pointer"
                    htmlFor={filter[0]}
                    style={{
                        fontSize: '14px',
                        color: filter[0] === 'NOT_BLISTABLE' ? '#ed9a00' : 'inherit',
                    }}
                >{t(MEDICINE_FILTERS_LABELS[filter[0]])}</label>

            </div>
            ))}

            </div>
        </div>

        {/* Collapsed Version disappears on 1600 */}
        <div
            className="d-xxxl-none dropdown ms-auto mb-1"
            ref={containerRef}
        >
            <button
                className="btn btn-sm btn-primary dropdown-toggle"
                type="button"
                id="dropdownFilters"
                // data-bs-toggle="dropdown"
                aria-expanded={isOpened ? 'true' : 'false'}
                onClick={e => handleToggleDropdown(e)}
                ref={dropDownRef}
            >
                <span className="material-icons-outlined">filter_alt</span>
            </button>
            <ul
                className={classNames([
                    "dropdown-menu",
                    isOpened && "show"
                ])}
                aria-labelledby="dropdownFilters"
                style={{ right: 0}}
            >
                <li key={nanoid()}>
                    <label className="dropdown-item">{t('pages.planning.filters.showLabel')}</label>
                </li>
                { Object.entries(medicinesFilters).map(filter => (
                <li key={nanoid()}>
                    <div
                        key={nanoid()}
                        className="dropdown-item form-check form-switch"
                    >
                        <input
                            onChange={e => handleFilterChange(e,filter[0])}
                            className="form-check-input cursor-pointer ms-0 me-1"
                            type="checkbox"
                            id={filter[0]}
                            checked={filter[1]}
                        />

                        <label
                            className="form-check-label cursor-pointer"
                            htmlFor="med-unactives"
                            style={{
                                fontSize: '14px',
                                color: filter[0] === 'NOT_BLISTABLE' ? '#ed9a00' : 'inherit',
                            }}
                        >{t(MEDICINE_FILTERS_LABELS[filter[0]])}</label>
                    </div>
                </li>
                )) }
            </ul>
        </div>

        </>
    );
}

export default withTranslation()(MedicinesFilter);
