import { orderProsposalsArray } from './generalUtils';

export function mixTreatmentMedicinesWithProposalsMedicines(data) {
    let proposals = data[0];
    let treatments = data[1];
    for (let index = 0; index < proposals.length; index++) {
        let proposalMed = proposals[index];
        const repeatedMed = treatments.find(treatmentMed => treatmentMed.name === proposalMed.name);
        if(repeatedMed !== undefined) {
            proposals[index]['prev_posologies'] = repeatedMed.posologies;
            treatments = treatments.filter(treatmentMed => proposalMed.name !== treatmentMed.name);
        }
    }

    return [[...proposals], [...treatments]];
}

export function getArraysForProposalsPage(data) {
    let result = orderProsposalsArray(data);
    result = mixTreatmentMedicinesWithProposalsMedicines(result);
    return result;
}