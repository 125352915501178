import { useEffect, useState, useRef } from 'react';
import axios from 'axios'
import { withTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { useAppProvider } from '../../context/AppContext';

// Context & Hooks
import useAPI from '../../hooks/useAPI';

// Constants
import { serverURL } from '../../constants/SERVERS';

// Components
import Button from '../../components/UI/Button';
import FormInput from '../../components/UI/FormInput';
import useToastCustom from '../../hooks/useToastCustom';

const Login = ({t}) => {
  const { handleLoggedIn } = useAPI();
  const formEl = useRef(null);
  const history = useHistory();

  const { loggedIn } = useAppProvider();
  const [ body,setBody ] = useState({ username: null, password: null });
  const [ btnIsLoading, setBtnIsLoading ]  = useState(false);
  const [ cannotSubmit, setcannotSubmit ]   = useState(true);

  // Toast
  const { showErrorMessage } = useToastCustom();

  // Redirect if user is already logged in
  useEffect(() => {
    if(loggedIn){
      history.push('/');
    }
  });

  const handleJSONError = error => {
    let textError = '';
    Object.entries(JSON.parse(error.request.response)).forEach(errorEntry => {
      let [key, errors] = errorEntry;
      textError += `${errors[0]} `;
    });
    showErrorMessage(textError);
  };

  const logInCall = body => {
    setBtnIsLoading(true);
    if(body.username && body.password) {
      axios.post(`${serverURL}api/v1/token/obtain/`,body)
      .then(response => {
        setBtnIsLoading(false);
        if(response && response.data) {
          handleLoggedIn(response.data);
          history.push('/');
        }}
      )
      .catch((error) => { setBtnIsLoading(false); handleJSONError(error)} )
    }
  }

  const isValidForm = () => {
    setcannotSubmit(!formEl.current.checkValidity());
  };

  const updateBody = (data) => {
    setBody({...body, ...data});
    isValidForm();
  }

  const handleSubmit = event => {
    event.preventDefault();
    logInCall(body);
  }

  return (
    <div
      className="LoginPage text-white d-flex justify-content-center align-items-center"
      style={{
        minHeight: '100vh',
        minWidth: '100vw'
      }}
    >
      <form
        className="bg-white text-body px-4 py-5 rounded col-12 col-md-4 col-xl-3 border"
        onSubmit={handleSubmit}
        ref={formEl}
      >
        <div className="flex text-center">
          <h1 className="text-primary">TiDose Pro</h1>
          <img src={process.env.PUBLIC_URL + '/timedi_logo.png'} alt="timedi-logo" width="80px" />
        </div>
        <FormInput
            id="InputUsername"
            type="text"
            dataCy={'username'}
            value={body.username}
            containerClasses={"mb-5"}
            labelText={t('pages.login.fields.username')}
            required={true}
            errorMessage={t("errors.minimum4characters")}
            pattern={".{4,}"}
            onChange={(ev) => {
              updateBody({username: ev.target.value});
              isValidForm();
            }}
          />
        <FormInput
            id="InputPassword"
            type="password"
            dataCy={'password'}
            value={body.password}
            containerClasses={"mb-5"}
            labelText={t('pages.login.fields.password')}
            required={true}
            errorMessage={t("errors.minimum10characters")}
            pattern={".{10,}"}
            onChange={(ev) => {
              updateBody({password: ev.target.value});
              isValidForm();
            }}
          />

        <Button
          type="submit"
          dataCy={'loginSubmit'}
          stylingClass="btn-secondary text-white w-100"
          isLoading={btnIsLoading}
          disabled={btnIsLoading || cannotSubmit}
        >{(t('pages.login.texts.login'))}</Button>
      </form>
    </div>
  );
};

export default withTranslation()(Login);