const PERMISSIONS = {
    CAN_EDIT_HOSPITALS:         'CAN_EDIT_HOSPITALS',
    CAN_ONLY_VIEW_HOSPITALS:    'CAN_ONLY_VIEW_HOSPITALS',
    CAN_DELETE_HOSPITALS:       'CAN_DELETE_HOSPITALS',
    CAN_EDIT_PATIENTS:          'CAN_EDIT_PATIENTS',
    CAN_EDIT_BOOKS:             'CAN_EDIT_BOOKS',
    CAN_EDIT_TREATMENTS:        'CAN_EDIT_TREATMENTS',
    CAN_EDIT_PROPOSALS:         'CAN_EDIT_PROPOSALS',
    CAN_EDIT_PRODUCTIONS:       'CAN_EDIT_PRODUCTIONS',
    CAN_EDIT_USERS:             'CAN_EDIT_USERS',
    CAN_APPLY_PROPOSALS:        'CAN_APPLY_PROPOSALS'
};

export default PERMISSIONS;