import { FlagsProvider } from 'react-feature-flags';
import { ToastContainer } from 'react-toastify';
import App from './App';
import { useAppProvider } from './context/AppContext';
import MODULES from './constants/MODULES';
import ROLES from './constants/ROLES';
import PERMISSIONS from './constants/PERMISSIONS';

const AppWrapper = () => {

    const { modules, userRole } = useAppProvider();

    const flags = [
        { name: MODULES["HOSPITALS"],                       isActive: modules && modules.includes(MODULES["HOSPITALS"])    },
        { name: MODULES["PATIENTS"],                        isActive: modules && modules.includes(MODULES["PATIENTS"])     },
        { name: MODULES["BOOK"],                            isActive: modules && modules.includes(MODULES["BOOK"])         },
        { name: MODULES["TREATMENTS"],                      isActive: modules && modules.includes(MODULES["TREATMENTS"]) && ROLES["CAN_EDIT_TREATMENTS"].includes(userRole) },
        { name: MODULES["PROPOSALS"],                       isActive: modules && (modules.includes(MODULES["PROPOSALS"]) && userRole === 'PRESCRIBING_DOCTOR'  )},
        { name: MODULES["PROPOSALS_VALIDATION"],            isActive: modules && (modules.includes(MODULES["PROPOSALS"]) && userRole !== 'PRESCRIBING_DOCTOR'  )},
        { name: MODULES["PRODUCTIONS"],                     isActive: modules && modules.includes(MODULES["PRODUCTIONS"])  },
        { name: PERMISSIONS["CAN_DELETE_HOSPITALS"],        isActive: modules && userRole && ROLES[PERMISSIONS["CAN_DELETE_HOSPITALS"]].includes(userRole)},
        { name: PERMISSIONS["CAN_EDIT_HOSPITALS"],          isActive: modules && userRole && ROLES[PERMISSIONS["CAN_EDIT_HOSPITALS"]].includes(userRole)   },
        { name: PERMISSIONS["CAN_EDIT_PATIENTS"],           isActive: modules && userRole && ROLES[PERMISSIONS["CAN_EDIT_PATIENTS"]].includes(userRole)    },
        { name: PERMISSIONS["CAN_EDIT_BOOKS"],              isActive: modules && userRole && ROLES[PERMISSIONS["CAN_EDIT_BOOKS"]].includes(userRole)       },
        { name: PERMISSIONS["CAN_EDIT_TREATMENTS"],         isActive: modules && userRole && ROLES[PERMISSIONS["CAN_EDIT_TREATMENTS"]].includes(userRole)  },
        { name: PERMISSIONS["CAN_EDIT_PRODUCTIONS"],        isActive: modules && userRole && ROLES[PERMISSIONS["CAN_EDIT_PRODUCTIONS"]].includes(userRole) },
        { name: PERMISSIONS["CAN_EDIT_USERS"],              isActive: modules && userRole && ROLES[PERMISSIONS["CAN_EDIT_USERS"]].includes(userRole)       },
        { name: PERMISSIONS["CAN_APPLY_PROPOSALS"],         isActive: modules && userRole && modules.includes(MODULES["PROPOSALS"]) && ROLES[PERMISSIONS["CAN_APPLY_PROPOSALS"]].includes(userRole) },
];

    return (
        <FlagsProvider value={flags}>
            <App />
            <ToastContainer
                containerId={"toastContainerID"}
                // limit={1}
                hideProgressBar={true}
                draggable={false}
                autoClose={3000}
            />
        </FlagsProvider>
    );
}

export default AppWrapper;