import InitialDateInput from '../../ui/InitialDateInput';
import MoveDays from '../../ui/MoveDays';
import MedicinesFilter from '../../../../../components/UI/MedicinesFilter';

const TreatmentsHeader = () => {
    return (
        <div
            className="TreatmentsHeader d-flex py-2 border-bottom align-items-end"
        >
            <InitialDateInput />
            <MoveDays value={7}/>
            <MedicinesFilter
                style={{
                    height: '68px',
                    marginLeft: '52px',
                }}
            />
        </div>
    );
}
 
export default TreatmentsHeader;