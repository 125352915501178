import Classnames from 'classnames';
import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import FormCheckbox from '../FormCheckbox/index';
import ModalPatientCard from '../../../components/containers/ModalPatientCard';
import { useAppProvider } from '../../../context/AppContext';
import ProposalsFilter from '../../../pages/Proposals/components/ui/ProposalsFilters';

const List = ({
    t,
    items,
    onClick,
    activeID,
    labelText,
    searchRef,
    className,
    handleSearch,
    hasMoreInfoBtn,
    hasProposalsFilter,
    handleSelectAllChange,
 }) => {

    const {
        openPatientModal,
        setOpenPatientModal
    } = useAppProvider();

    const [ _items, setItems ] = useState([]);

    const [ modalPatient, setModalPatient ] = useState(null);

    const setAllCheckboxesAreSelected = () => {
        if(items.length === 0 ) { return false };
        for (let index = 0; index < items.length; index++) {
            if(items[index].value === false) {
                return false;
            }
        }
        return true;
    }

    const handleOpenModal = patientID => {
        setModalPatient(patientID);
        setOpenPatientModal(true);
    };

    useEffect( () => {
        setItems(items);
    },[items]);

    return (
        <>
        <div className={Classnames([
            "List",
            className
        ])}>
            { labelText &&
            <label className="form-label">{ labelText }</label>
            }
            { handleSearch && (
                <div className="position-relative">
                    <input
                        placeholder={t('general.search')}
                        type="text"
                        onChange={ e => handleSearch(e) }
                        className="form-control mb-2 pe-4"
                        ref={searchRef}
                    />
                    <span
                        className="material-icons"
                        style={{
                            position: 'absolute',
                            right: '4px',
                            top: '8px',
                            opacity: '0.4',
                        }}
                    >search</span>
                </div>
            )}
            { handleSelectAllChange && (
                <div className="form-check mt-4 mb-2">
                    <input
                        id="selectAll"
                        type="checkbox"
                        className="form-check-input"
                        onChange={(e) => handleSelectAllChange(e.target.checked)}
                        checked={setAllCheckboxesAreSelected()}
                    />
                    <label className="form-check-label" htmlFor="selectAll">{t('general.selectAll')}</label>
                </div>
            )}

            { hasProposalsFilter === true &&
                <ProposalsFilter />
            }

            { handleSelectAllChange ? (
                <ul className="list-group">
                    { _items ? _items[0] ? _items.map((item) => (
                        <li
                            key={item.id}
                            className={Classnames([
                                "list-group-item text-wrap text-break lh-1",
                                onClick && 'cursor-pointer',
                                item.id === activeID && 'active'
                            ])}
                        >
                            <div className="form-check">
                                <FormCheckbox
                                    id={item.id}
                                    labelText={item.name}
                                    value={item.value}
                                    onChange={ (e) => onClick(e.target)}
                                />
                            </div>
                        </li>
                        )) : (
                            <li className="list-group-item">{t('general.noResults')}</li>
                        )
                    : (
                        <li
                            className="list-group-item text-center"
                        >
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden"></span>
                            </div>
                        </li>
                    )}
                </ul>
            ) : (
                <ul className="list-group">
                    { items ? items[0] ? items.map(item => (
                        <li
                            key={item.id}
                            className={Classnames([
                                "list-group-item text-wrap text-break d-flex justify-content-between",
                                onClick && 'cursor-pointer',
                                item.id === activeID && 'active',
                                item.has_pending_proposals === true && 'has-changes',
                                hasMoreInfoBtn && 'flex-column'
                            ])}
                            onClick={ () => onClick(item) }
                        >
                            <span>{item.name}</span>
                            { item.has_pending_proposals && <span className="material-icons-outlined">change_circle</span> }
                            { hasMoreInfoBtn && item.id === activeID &&
                                <button
                                    className="btn btn-sm btn-outline-warning mt-2 py-0"
                                    onClick={() => handleOpenModal(item.id)}
                                >
                                    <small>{t('general.showMoreInfo')}</small>
                                </button>
                            }
                        </li>
                        )) : (
                            <li className="list-group-item">{t('general.noResults')}</li>
                        )
                    : (
                        <li
                            className="list-group-item text-center"
                        >
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden"></span>
                            </div>
                        </li>
                    )}
                </ul>
            )}
        </div>
        <ModalPatientCard
            patientID={modalPatient}
            show={openPatientModal}
            handleClose={() => setOpenPatientModal(false)}
        />
        </>
    );
}

export default withTranslation()(List);
