// Libs & React
import { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import Classnames from 'classnames';

// Router
import { Link, useParams, useHistory } from 'react-router-dom';
import ROUTES from '../../constants/ROUTES';

// Contexts & Hooks
import { useAppProvider } from '../../context/AppContext';

// Components
import FormInput from '../../components/UI/FormInput';
import FormCheckbox from '../../components/UI/FormCheckbox';
import useAPI from '../../hooks/useAPI';
import useToastCustom from '../../hooks/useToastCustom';

const BookDetail = ({ t }) => {
  const { medicine_id } = useParams();
  const { handleLoading, handleNotLoading } = useAppProvider();

  const [ cannotSubmit, setcannotSubmit ]   = useState(true);
  const [ medicine, setMedicine ]           = useState({});

  const isEditPage = medicine_id !== 'new';
  let history = useHistory();

  const { API, handleReqError } = useAPI();
  const formEl = useRef(null);

  const { showSuccessMessage } = useToastCustom();

  // Get Medicine Data
  useEffect(() => {
    handleLoading();
    if(isEditPage) {
      API.get(`therapeutic-book-medicines/${medicine_id}/`)
      .then((response) => {
        if (response) {
          handleNotLoading();
          setMedicine(response.data);
        }
      })
      .catch((error) => handleReqError(error));
    } else { handleNotLoading()};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addCustomMedicineToBook = medicine_id => {
    const data = { medicine: medicine_id};
    API.post('therapeutic-book-medicines/', data)
    .then((response) => {
      if (response) {
        showSuccessMessage(t('pages.bookMedicines.texts.addedOk'));
        history.push(ROUTES.THERAPEUTIC_BOOK.INDEX);
      } else { handleReqError() }
    })
    .catch((error) => handleReqError(error));
  };

  const createCustomMedicine = () => {
    if(isValidForm() === false) return;
    API.post('medicines/', {...medicine, "country": "es"}) // TODO: #189
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        addCustomMedicineToBook(response.data.id);
      } else if(response.status === 400 || response.status === 409) {
        handleReqError(t('pages.bookMedicines.error.repeated'));
      } else{ handleReqError(response) }
    })
    .catch((error) => {
      if(error.status === 400 || error.status === 409) {
        handleReqError(t('pages.bookMedicines.error.repeated'));
      } else {
        handleReqError(error)
      }
    });
  }

  // Send New Data
  const saveMedicine = () => {
    handleLoading();
    API.put(`therapeutic-book-medicines/${medicine_id}/`,medicine)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        handleNotLoading();
        history.push(ROUTES.THERAPEUTIC_BOOK.INDEX);
        const message = response.status === 200 ? t('general.changesSavedProperly') : t('pages.bookMedicines.texts.newMedicineOk');
        showSuccessMessage(message);
      }
    })
    .catch((error) => handleReqError(error));
  };

  // Is Valid Form // TODO: DRY
  const isValidForm = () => {
    setcannotSubmit(!formEl.current.checkValidity());
  };

  // Update Medicine Data before Send
  const updateMedicine = (data) => {
    setMedicine({ ...medicine, ...data });
    isValidForm();
  };

  return (
    <div className="ContentWrapper container p-4">
      <h2 className='font-header text-primary display-6 fw-bold dots-side-left fwp mb-4'>
        { isEditPage ? t('pages.bookMedicines.titles.edit') : t('pages.bookMedicines.titles.create') }
      </h2>
      <div className='card p-2'>
        <form
          ref={formEl}
          className={Classnames([
            'row g-3 p-3',
        ])}
        >
          <h6>{t('pages.hospitals.fields.mainInfo')}</h6>
          <hr />

          {/* 0 */}

          <FormInput
            containerClasses={'col-12'}
            id={'nationalCode'}
            labelText={ `* ${t('pages.bookMedicines.fields.nationalCode')}`}
            type={'text'}
            value={medicine.national_code}
            onChange={(ev) => updateMedicine({ national_code: ev.target.value })}
            disabled={isEditPage ? true : false}
            required={true}
            pattern={".{6,}"}
            errorMessage={t('errors.minimum6characters')}
          />


          <div className='col-12'>
            { isEditPage &&
              <FormCheckbox
                id={'_isBlistable'}
                value={medicine._is_blistable}
                disabled
                labelText={t('pages.bookMedicines.fields.isBlistable')}
                containerClassName={'opacity-50'}
              />
            }
            <FormCheckbox
              id={'isBlistable'}
              value={medicine.is_blistable}
              labelText={t('pages.bookMedicines.fields.isBlistable')}
              onChange={(ev) => updateMedicine({ is_blistable: ev.target.checked })}
            />
          </div>

          {/* 1 */}

          <div className='col-4'>

            { isEditPage &&
              <FormInput
                id={'_name'}
                labelText={t('pages.bookMedicines.fields.name')}
                type={'text'}
                value={medicine._name}
                disabled
                classnames={'mb-1'}
              />
            }

            <FormInput
              id={'name'}
              type={'text'}
              value={medicine.name}
              onChange={(ev) => updateMedicine({ name: ev.target.value })}
              labelText={ !isEditPage && `* ${t('pages.bookMedicines.fields.name')}`}
              required={isEditPage ? false : true}
              pattern={".{1,}"}
              errorMessage={t('errors.isRequired')}
            />
          </div>

          <div className='col-4'>

            { isEditPage &&
              <FormInput
                id={'_description'}
                labelText={t('pages.bookMedicines.fields.description')}
                type={'text'}
                value={medicine._description}
                disabled
                classnames={'mb-1'}
              />
            }

            <FormInput
              id={'description'}
              type={'text'}
              value={medicine.description}
              onChange={(ev) => updateMedicine({ description: ev.target.value })}
              labelText={ !isEditPage && t('pages.bookMedicines.fields.description')}
            />
          </div>

          <div className='col-4'>

            { isEditPage &&
              <FormInput
                id={'strength'}
                labelText={t('pages.bookMedicines.fields.strength')}
                type={'number'}
                value={medicine._strength}
                disabled
                classnames={'mb-1'}
              />
            }

            <FormInput
              id={'strength'}
              type={'number'}
              value={medicine.strength}
              onChange={(ev) => updateMedicine({ strength: ev.target.value })}
              step={0.01}
              labelText={ !isEditPage && t('pages.bookMedicines.fields.strength')}
            />
          </div>


          {/* 2 */}

          <div className='col-4'>
            { isEditPage &&
              <FormInput
                id={'_unitsPerBox'}
                labelText={t('pages.bookMedicines.fields.unitsPerBox')}
                type={'number'}
                value={medicine._units_per_box}
                disabled
                classnames={'mb-1'}
            />
            }

            <FormInput
              id={'unitsPerBox'}
              type={'number'}
              value={medicine.units_per_box}
              onChange={(ev) => updateMedicine({ units_per_box: ev.target.value })}
              labelText={ !isEditPage && t('pages.bookMedicines.fields.unitsPerBox')}
            />
          </div>

          <div className='col-4'>

            { isEditPage &&
              <FormInput
                id={'_type'}
                labelText={t('pages.bookMedicines.fields.type')}
                type={'text'}
                value={medicine._type}
                disabled
                classnames={'mb-1'}
              />
            }

            <FormInput
              id={'type'}
              type={'text'}
              value={medicine.type}
              onChange={(ev) => updateMedicine({ type: ev.target.value })}
              labelText={ !isEditPage && t('pages.bookMedicines.fields.type')}
            />
          </div>

          <div className='col-4'>

            { isEditPage &&
              <FormInput
                id={'_laboratories'}
                labelText={t('pages.bookMedicines.fields.laboratories')}
                type={'text'}
                value={medicine._laboratories}
                disabled
                classnames={'mb-1'}
              />
            }

            <FormInput
              id={'laboratories'}
              type={'text'}
              value={medicine.laboratories}
              onChange={(ev) => updateMedicine({ laboratories: ev.target.value })}
              labelText={ !isEditPage && t('pages.bookMedicines.fields.laboratories')}
            />
          </div>

          {/* 3 */}

          <div className='col-4'>

            { isEditPage &&
              <FormInput
                id={'_price'}
                labelText={t('pages.bookMedicines.fields.price')}
                type={'number'}
                value={medicine._price}
                disabled
                classnames={'mb-1'}
              />
            }

            <FormInput
              id={'price'}
              type={'number'}
              value={medicine.price}
              onChange={(ev) => updateMedicine({ price: ev.target.value })}
              step={0.01}
              labelText={ !isEditPage && t('pages.bookMedicines.fields.price')}
            />
          </div>

          <div className='col-12 mt-5'>
            <Link to={`${ROUTES.THERAPEUTIC_BOOK.INDEX}`}>
              <button className='btn btn-outline-primary me-2'>{t("general.cancel")}</button>
            </Link>
            <button
              type='button'
              className='btn btn-primary me-2'
              onClick={isEditPage ? saveMedicine : createCustomMedicine}
              disabled={cannotSubmit ? true : false}
            >
              {t('general.save')}
            </button>
          </div>

        </form>
      </div>
    </div>
  );
};

export default withTranslation()(BookDetail);
