import Classnames from 'classnames';

const FormCheckbox = ({
    id,
    type,
    value,
    onChange,
    labelText,
    containerClassName
}) => {

    return (
        <div className={Classnames([
            containerClassName
        ])}>
            <div className="form-check">
                <input
                    type={type ? type : 'checkbox'}
                    className="form-check-input cursor-pointer"
                    checked={value ? value : false}
                    id={id}
                    onChange={ev => onChange(ev)}
                    readOnly
                />
                <label
                    className='form-check-label'
                    htmlFor={id}
                    // style={{verticalAlign: 'sub'}}
                >
                    {labelText}
                </label>
            </div>
        </div>
    );
}

export default FormCheckbox;
