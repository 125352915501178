// React & Libs
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

// Contexts & Hooks
import { useAppProvider } from "../../context/AppContext";
import useToastCustom from "../../hooks/useToastCustom";
import { useHistory } from "react-router-dom";

// Components
import useAPI from "../../hooks/useAPI";
import DayPickerInput from "react-day-picker/DayPickerInput";
import Select from "../../components/UI/Select";
import List from "../../components/UI/List";
import LoadingSpinner from "../../components/UI/LoadingSpinner";

// Utils
import { getDateFromXdays, YYYYMMDD } from "../../utils/dateUtils";

// Constants
import ROUTES from "../../constants/ROUTES";

// Libs
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import "moment/locale/es";
import i18n from "i18next";

const ProductionCreate = ({ t }) => {
  let history = useHistory();
  const { language } = i18n;

  const { handleLoading, handleNotLoading } = useAppProvider();

  const { showSuccessMessage } = useToastCustom();

  const [endDate, setEndDate] = useState(null);
  const [patients, setPatients] = useState([]);
  const [canCreate, setCanCreate] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [hospitals, setHospitals] = useState([]);
  const [selHospital, setSelHospital] = useState(null);
  const [hospitalFloors, setHospitalFloors] = useState([]);
  const [selHospitalFloor, setSelHospitalFloor] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [listUpdating, setListUpdating] = useState(false);
  const { API, handleReqError } = useAPI();

  const getHospitals = () => {
    handleLoading();
    API.get("hospitals/")
      .then((response) => {
        handleNotLoading();
        let hospitals = response.data.results;
        for (let i = 0; i < hospitals.length; i++) {
          hospitals[i]["value"] = hospitals[i]["id"];
        }
        setHospitals([...hospitals]);
      })
      .catch((error) => handleReqError(error));
  };

  const getHospitalFloors = (ID) => {
    const hospitalId = hospitals.find((hospital) => hospital.id === ID).id;
    handleLoading();
    API.get(`hospital-floors/?hospital=${hospitalId}`)
      .then((response) => {
        handleNotLoading();
        let _floors = [...response.data.results];
        for (let index = 0; index < _floors.length; index++) {
          _floors[index]["value"] = _floors[index]["id"];
        }
        setHospitalFloors([
          { name: t("general.allF"), value: "" },
          ...response.data.results,
        ]);
      })
      .catch((error) => handleReqError(error));
  };

  const getPatients = () => {
    handleLoading();

    const url = "patients/available-for-production/";
    const hospital_id =
      selHospital !== null
        ? hospitals.find((hos) => hos.id === selHospital).id
        : null;
    const _startDate = YYYYMMDD(new Date(startDate)); // DDMMYYYY(new Date(startDate));
    const _endDate = YYYYMMDD(new Date(endDate)); // DDMMYYYY(new Date(endDate));

    const data = {
      start_date: _startDate,
      end_date: _endDate,
      hospital: hospital_id,
      hospital_floors: [],
    };

    if (selHospitalFloor) {
      data.hospital_floors = [
        hospitalFloors.find((floor) => floor.id === selHospitalFloor).id,
      ];
    }

    API({
      method: "post",
      url: url,
      data: data,
    })
      .then((response) => {
        setListUpdating(false);
        if (response) {
          handleNotLoading();
          let _patients = response.data;
          for (let index = 0; index < _patients.length; index++) {
            _patients[index].value = false;
          }
          setPatients(_patients);
          setCanCreate(false);
        }
      })
      .catch((error) => {
        setListUpdating(false);
        handleReqError(error);
      });
  };

  const handleHospitalChange = (e) => {
    setHospitalFloors([]);
    setSelHospitalFloor("");
    setSelHospital(e.target.value);
    getHospitalFloors(e.target.value);
  };

  const handleHospitalFloorsChange = (e) => {
    setSelHospitalFloor(e.target.value);
  };

  const handleDateChange = (date, isStartDate) => {
    if (!date) {
      setPatients([]);
    }
    if (isStartDate) {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const handleButtonDateChange = (daysNum) => {
    let date = getDateFromXdays(new Date(startDate), daysNum).setHours(
      0,
      0,
      0,
      0
    );
    date = new Date(date);
    setEndDate(date);
  };

  const handleSelectAllChange = (value) => {
    let _patients = patients;
    for (let index = 0; index < _patients.length; index++) {
      _patients[index]["value"] = value;
    }
    setPatients([...patients]);
    setCanCreate(value);
  };

  const handlePatientClick = (patient) => {
    const { id, checked } = patient;
    let _patients = patients;
    const index = _patients.findIndex((_patient) => _patient.id === id);
    _patients[index].value = checked;
    setPatients([..._patients]);
    _patients.length > 0 ? setCanCreate(true) : setCanCreate(false);
  };

  const createProduction = () => {
    setCanCreate(false);
    setIsLoading(true);
    let _patients = [];
    patients.forEach((pat) => {
      if (pat.value === true) {
        _patients.push(pat.id);
      }
    });
    const data = {
      start_date: YYYYMMDD(startDate),
      end_date: YYYYMMDD(endDate),
      patients: _patients,
    };
    API({
      url: "productions/",
      method: "post",
      data: data,
    })
      .then((response) => {
        setCanCreate(true);
        setIsLoading(false);
        if (response.status === 200 || 201) {
          showSuccessMessage(t("general.changesSavedProperly"));
          history.push(ROUTES.PRODUCTIONS.INDEX);
        }
      })
      .catch((error) => {
        setCanCreate(true);
        setIsLoading(false);
        handleReqError(error);
      });
  };

  useEffect(() => {
    getHospitals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (endDate && startDate && selHospital) {
      setListUpdating(true);
      setTimeout(() => getPatients(), 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, startDate, selHospital, selHospitalFloor]);

  return (
    <div className="ContentWrapper container p-4">
      <h2 className="font-header text-primary display-6 fw-bold dots-side-left fwp mb-4">
        {t("pages.productions.titles.create")}
      </h2>
      <div className="card p-2">
        <div className="d-flex w-100 align-items-end justify-content-between p-2">
          <h5 className="w-100">
            1. {t("pages.productions.texts.productionInterval")}
          </h5>
        </div>
        <div className="d-flex w-100 align-items-end mb-2 justify-content-between p-2">
          <div className="col me-4">
            <label className="form-label">
              {t("pages.planning.fields.startDate")}
            </label>
            <DayPickerInput
              onDayChange={(date) => handleDateChange(date, true)}
              formatDate={formatDate}
              parseDate={parseDate}
              format="DD-MM-YYYY"
              placeholder={t("general.datePlaceholder")}
              inputProps={{
                className: "form-control bg-white",
                readOnly: false,
              }}
              dayPickerProps={{
                locale: language,
                localeUtils: MomentLocaleUtils,
                onDayClick: (date) => handleDateChange(date, true),
                disabledDays: [
                  {
                    after: endDate ? new Date(endDate) : null,
                  },
                ],
                todayButton: t("date.today"),
              }}
              value={startDate}
            />
          </div>
          <div className="col me-4 d-flex flex-column">
            <label className="form-label">
              {t("pages.proposals.blisterDuration")}
            </label>
            <div className="d-flex">
              <button
                className="btn btn-primary me-4 col"
                disabled={!startDate}
                onClick={() => handleButtonDateChange(6)}
              >
                + {t("pages.productions.texts.7days")}
              </button>
              <button
                className="btn btn-primary col"
                disabled={!startDate}
                onClick={() => handleButtonDateChange(13)}
              >
                + {t("pages.productions.texts.14days")}
              </button>
            </div>
          </div>
          <div className="col">
            <label className="form-label">
              {t("pages.planning.fields.endDate")}
            </label>
            <DayPickerInput
              onDayChange={(date) => handleDateChange(date, false)}
              formatDate={formatDate}
              parseDate={parseDate}
              format="DD-MM-YYYY"
              placeholder={t("general.datePlaceholder")}
              inputProps={{
                className: "form-control bg-white",
                readOnly: false,
              }}
              dayPickerProps={{
                locale: language,
                localeUtils: MomentLocaleUtils,
                onDayClick: (date) => handleDateChange(date, false),
                disabledDays: [
                  {
                    before: startDate ? new Date(startDate) : null,
                  },
                ],
                todayButton: t("date.today"),
              }}
              value={endDate}
            />
          </div>
        </div>
        <hr />

        <div className="row">
          <div className="col-8">
            <div className="d-flex w-100 align-items-end justify-content-between p-2">
              <h5 className="w-100">
                2. {t("pages.productions.texts.choosePatients")}
              </h5>
            </div>
            <div className="row">
              <div className="col">
                <Select
                  disabled={listUpdating}
                  hasChooseOption
                  options={hospitals}
                  hasPlaceholder={true}
                  labelText={t("navigation.hospitals")}
                  onChange={(e) => handleHospitalChange(e)}
                />
              </div>
              <div className="col">
                <Select
                  disabled={listUpdating}
                  options={hospitalFloors}
                  labelText={t("pages.productions.fields.hospitalFloors")}
                  onChange={(e) => handleHospitalFloorsChange(e)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                {!listUpdating ? (
                  <List
                    items={patients}
                    onClick={(patient) => handlePatientClick(patient)}
                    handleSelectAllChange={(value) =>
                      handleSelectAllChange(value)
                    }
                  />
                ) : (
                  <div className="p-4">
                    <LoadingSpinner />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-4 d-flex flex-column border-start">
            <div className="d-flex w-100 align-items-start mb-2 justify-content-between p-2">
              <h5 className="w-100">
                3. {t("pages.productions.texts.createProduction")}
              </h5>
            </div>
            <div
              className="d-flex position-sticky w-100 align-items-start pb-1 justify-content-between p-2"
              style={{ top: "100px" }}
            >
              <button
                className="btn btn-lg btn-primary col"
                onClick={() => createProduction()}
                disabled={!canCreate}
              >
                {!isLoading ? (
                  t("pages.productions.texts.createProduction")
                ) : (
                  <LoadingSpinner />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ProductionCreate);
