import { withTranslation } from 'react-i18next';
import { Flags } from "react-feature-flags";
import PERMISSIONS from '../../../../../constants/PERMISSIONS';

const AcceptDecline = ({
    t,
    disabled,
    handleApplyProposals,
    handleAcceptProposals,
    handleDeclineProposals
}) => {

    const handleOver = (e,isProposal) => {
        e.preventDefault();
        const Proposals = document.querySelectorAll('.ProposalItem.active .Posology__Proposal');
        const Treatments = document.querySelectorAll('.ProposalItem.active .Posology__Current');

        if (isProposal === null) {
            Proposals.forEach(item => item.classList.remove('disabled'));
            Treatments.forEach(item => item.classList.remove('disabled'));
            return;
        };

        if(isProposal === true) {
             Proposals.forEach(item => item.classList.remove('disabled'));
             Treatments.forEach(item => item.classList.add('disabled'));
        } else {
            Treatments.forEach(item => item.classList.remove('disabled'));
            Proposals.forEach(item => item.classList.add('disabled'));
        }
    }

    return (
        <div
            className="btn-group mt-3 mb-3 ms-2"
            role="group"
            aria-label="Accept or Decline Proposals"
            onMouseLeave={ e => handleOver(e,null)}
        >
            <button
                type="button"
                className="btn btn-danger d-flex align-items-center"
                disabled={disabled}
                onMouseEnter={ e => handleOver(e,false)}
                onClick={e => handleDeclineProposals(e)}
            >
                <span className="material-icons-outlined align-middle me-1">close</span>
                <small>{t('pages.proposals.buttongroup.reject')}</small>
            </button>
            <button
                type="button"
                className="btn btn-success text-white d-flex align-items-center"
                disabled={disabled}
                onMouseEnter={ e => handleOver(e,true)}
                onClick={e => handleAcceptProposals(e)}
            >
                <span className="material-icons-outlined align-middle me-1">check</span>
                <small>{t('pages.proposals.buttongroup.validate')}</small>
            </button>
            <Flags authorizedFlags={[PERMISSIONS["CAN_APPLY_PROPOSALS"]]}>
                <button
                    type="button"
                    className="btn btn-secondary text-white d-flex align-items-center"
                    disabled={disabled}
                    onMouseEnter={ e => handleOver(e,true)}
                    onClick={e => handleApplyProposals(e)}
                >
                    <span className="material-icons-outlined align-middle me-1">assignment_turned_in</span>
                    <small>{t('pages.proposals.buttongroup.apply')}</small>
                </button>
            </Flags>
        </div>
    );
}

export default withTranslation()(AcceptDecline);