import PERMISSIONS from './PERMISSIONS';

const ROLES = {
    'admin'                 : 'ADMIN',
    'pharmacist'            : 'PHARMACIST',
    'standard'              : 'STANDARD',
    'supervising-doctor'    : 'SUPERVISING_DOCTOR',
    'prescribing-doctor'    : 'PRESCRIBING_DOCTOR',
    [ PERMISSIONS['CAN_EDIT_HOSPITALS'] ]           : [ 'ADMIN', 'PHARMACIST', 'STANDARD' ],
    [ PERMISSIONS['CAN_ONLY_VIEW_HOSPITALS'] ]      : [ 'SUPERVISING_DOCTOR', 'PRESCRIBING_DOCTOR' ],
    [ PERMISSIONS['CAN_DELETE_HOSPITALS'] ]         : [ 'ADMIN', 'PHARMACIST' ],
    [ PERMISSIONS["CAN_EDIT_PATIENTS"] ]            : [ 'ADMIN', 'PHARMACIST', 'STANDARD', 'SUPERVISING_DOCTOR', 'PRESCRIBING_DOCTOR' ],
    [ PERMISSIONS["CAN_EDIT_BOOKS"] ]               : [ 'ADMIN', 'PHARMACIST' ],
    [ PERMISSIONS["CAN_EDIT_TREATMENTS"] ]          : [ 'ADMIN', 'PHARMACIST', 'STANDARD'],
    [ PERMISSIONS["CAN_EDIT_PRODUCTIONS"] ]         : [ 'ADMIN', 'PHARMACIST', 'STANDARD' ],
    [ PERMISSIONS["CAN_EDIT_USERS"] ]               : [ 'ADMIN' ],
    [ PERMISSIONS["CAN_APPLY_PROPOSALS"] ]          : [ 'ADMIN', 'PHARMACIST', 'STANDARD' ],
};

export default ROLES;