// Libs & React
import { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import Classnames from "classnames";

// Router
import { Link, useParams, useHistory } from "react-router-dom";
import ROUTES from "../../constants/ROUTES";

// Contexts & Hooks
import { useAppProvider } from "../../context/AppContext";
import useAPI from "../../hooks/useAPI";

// Components
import { toast } from "react-toastify";
import FormInput from "../../components/UI/FormInput";
import FormDate from "../../components/UI/FormDate";
import FormCheckbox from "../../components/UI/FormCheckbox";
import Select from "../../components/UI/Select";
import SelectCountries from "../../components/containers/SelectCountries";

const PatientsDetail = ({ t }) => {
  const { patient_id } = useParams();
  const { handleLoading, handleNotLoading } = useAppProvider();
  const [patient, setPatient] = useState({});
  const [hospitals, setHospitals] = useState([]);
  const [hospitalFloors, setHospitalFloors] = useState([]);
  const [cannotSubmit, setcannotSubmit] = useState(true);

  const isEditPage = patient_id !== "new";
  let history = useHistory();

  const defaultFormResponseErrors = {
    first_name: "",
    last_name: "",
    printing_label_text: "",
    doctor_name: "",
    room_number: "",
    national_identity_card_number: "",
    contact_person: "",
    address: "",
    town: "",
    zip_code: "",
    phone_prefix: "",
    phone_number: "",
    socialSecurityNumber: "",
    socialSecurityBarcode: "",
    socialSecurityRegion: "",
  };
  const [formResponseErrors, setFormResponseErrors] = useState({
    ...defaultFormResponseErrors,
  });

  const formEl = useRef(null);

  const defineHospitals = (arr) => {
    let result = [];
    arr.forEach((el) => {
      const data = {
        value: el.id,
        name: el.name,
      };
      result.push(data);
    });
    setHospitals(result);
  };

  const defineHospitalFloors = (hospitalId) => {
    API.get(`hospital-floors/?hospital=${hospitalId}`)
      .then((response) => {
        if (response) {
          handleNotLoading();
          let hospitalSelectOptions = [
            {
              name: t("pages.hospitals.floors.notAssigned"),
              value: "",
            },
          ];
          response.data.results.length > 0 &&
            response.data.results.forEach((floor) => {
              const data = {
                name: floor.name,
                value: floor.id,
              };
              hospitalSelectOptions.push(data);
            });
          setHospitalFloors([...hospitalSelectOptions]);
        }
      })
      .catch((error) => handleReqError(error));
  };

  const { API, handleReqError } = useAPI();

  // Send New Data
  const savePatient = () => {
    handleLoading();
    clearResponseErrors();

    const url = isEditPage ? `patients/${patient_id}/` : "patients/";
    const method = isEditPage ? "put" : "post";

    API({
      method: method,
      url: url,
      data: patient,
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          handleNotLoading();
          history.push(ROUTES.PATIENTS.INDEX);
          toast(
            response.status === 200
              ? t("general.changesSavedProperly")
              : t("pages.patients.texts.newPatientOk"),
            {
              position: toast.POSITION.TOP_CENTER,
              type: toast.TYPE.SUCCESS,
            }
          );
        }
      })
      .catch((error) => {
        if (error.response.status == 400) {
          setResponseErrors(error.response);
          handleNotLoading();
        } else {
          setcannotSubmit(false);
          handleReqError(error);
        }
      });
  };

  const clearResponseErrors = () => {
    setFormResponseErrors(defaultFormResponseErrors);
  };

  const setResponseErrors = (response) => {
    let errors = {};
    Object.entries(formResponseErrors).forEach((errorEntry) => {
      let [key, errorText] = errorEntry;
      if (response.data.hasOwnProperty(key)) {
        errors[key] = response.data[key][0];
      }
    });
    setFormResponseErrors({ ...formResponseErrors, ...errors });
  };

  const isValidForm = () => {
    setTimeout(() => {
      if (formEl && formEl.current) {
        setcannotSubmit(!formEl.current.checkValidity());
      }
    }, 200);
  };

  const handleHospitalUpdate = (ev) => {
    const hospitalId = ev.target.value;
    if (patient.hospital !== hospitalId) {
      patient.hospital_floor = null;
    }
    updatePatient({ hospital: hospitalId });
    isValidForm();
    defineHospitalFloors(hospitalId);
  };

  const handleHospitalFloorUpdate = (ev) => {
    updatePatient({ hospital_floor: ev.target.value });
    isValidForm();
  };

  // Update Patient Data before Send
  const updatePatient = (data) => {
    let _patient = { ...patient, ...data };
    const first_name =
      _patient.first_name === undefined ? "" : _patient.first_name;
    const last_name =
      _patient.last_name === undefined ? "" : _patient.last_name;
    _patient["printing_label_text"] = first_name + " " + last_name;
    setPatient({ ..._patient });
    isValidForm();
  };

  // Get Data
  useEffect(() => {
    // Patient Data
    if (isEditPage) {
      handleLoading();
      API.get(`patients/${patient_id}/`)
        .then((response) => {
          if (response) {
            handleNotLoading();
            let _patient = {
              ...response.data,
              hospital: response.data.hospital
                ? response.data.hospital.id
                : null,
              hospital_floor: response.data.hospital_floor
                ? response.data.hospital_floor.id
                : null,
            };
            if (_patient.date_of_birth) {
              _patient.date_of_birth = _patient.date_of_birth.split("T")[0];
            }
            setPatient(_patient);
            if (_patient.hospital) {
              defineHospitalFloors(_patient.hospital);
            }
          }
        })
        .catch((error) => handleReqError(error));
    }

    // Hospitals
    API.get("hospitals/?page_all=1")
      // .then(handleReqError)
      .then((response) => {
        if (response) {
          handleNotLoading();
          defineHospitals(response.data);
        }
      })
      .catch((error) => handleReqError(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ContentWrapper container p-4">
      <h2 className="font-header text-primary display-6 fw-bold dots-side-left fwp mb-4">
        {t(
          isEditPage
            ? "pages.patients.titles.edit"
            : "pages.patients.titles.create"
        )}
      </h2>
      <div className="card p-2">
        <form ref={formEl} className={Classnames(["row g-3 p-3"])}>
          {/* 1 */}
          <div className="row">
            <div className="col-md-8 border-end pe-3">
              <div className="row">
                <h6 className="mt-2">
                  (*) {t("pages.hospitals.fields.mainInfo")}
                </h6>
                <hr />
                <FormInput
                  isSmall
                  containerClasses={"col-md-4 mb-2"}
                  id="first_name"
                  type="text"
                  value={patient.first_name}
                  onChange={(ev) => {
                    updatePatient({ first_name: ev.target.value }, true);
                    isValidForm();
                  }}
                  labelText={t("pages.patients.fields.firstName")}
                  required={true}
                  errorMessage={t("errors.minimum2characters")}
                  pattern={".{2,}"}
                  responseError={formResponseErrors.first_name}
                />

                <FormInput
                  isSmall
                  containerClasses={"col-md-4 mb-2"}
                  id="last_name"
                  type="text"
                  value={patient.last_name}
                  onChange={(ev) => {
                    updatePatient({ last_name: ev.target.value }, true);
                    isValidForm();
                  }}
                  labelText={t("pages.patients.fields.lastName")}
                  required={true}
                  errorMessage={t("errors.minimum2characters")}
                  pattern={".{2,}"}
                  responseError={formResponseErrors.last_name}
                />

                <FormInput
                  isSmall
                  containerClasses={"col-md-4 mb-2"}
                  id="printing_label_text"
                  type="text"
                  value={patient.printing_label_text}
                  onChange={(ev) => {
                    updatePatient({ printing_label_text: ev.target.value });
                    isValidForm();
                  }}
                  labelText={t("pages.patients.fields.printingLabelText")}
                  required={true}
                  errorMessage={t("errors.minimum2characters")}
                  pattern={".{2,}"}
                  responseError={formResponseErrors.printing_label_text}
                />

                <Select
                  hasChooseOption
                  isSmall
                  containerClassNames={"col-md-4 mb-2"}
                  hasPlaceholder
                  id="hospital"
                  value={patient && patient.hospital ? patient.hospital : null}
                  onChange={(ev) => {
                    handleHospitalUpdate(ev);
                  }}
                  labelText={t("pages.patients.fields.hospital")}
                  required={true}
                  errorMessage={t("errors.minimum2characters")}
                  pattern={".{2,}"}
                  options={hospitals}
                />

                <Select
                  isSmall
                  containerClassNames={"col-md-4 mb-2"}
                  hasPlaceholder
                  id="hospital_floor"
                  value={
                    patient && patient.hospital_floor
                      ? patient.hospital_floor
                      : null
                  }
                  onChange={(ev) => {
                    handleHospitalFloorUpdate(ev);
                  }}
                  labelText={t("pages.patients.fields.hospitalFloor")}
                  required={false}
                  errorMessage={t("errors.minimum2characters")}
                  pattern={".{2,}"}
                  options={hospitalFloors}
                />

                <FormCheckbox
                  containerClassName="col-md-4 mt-4 mb-2"
                  id="status"
                  labelText={t("pages.patients.fields.isActive")}
                  value={isEditPage ? patient.is_active : true}
                  onChange={(ev) => {
                    updatePatient({ is_active: ev.target.checked });
                    isValidForm();
                  }}
                />
              </div>
            </div>

            <div
              className="col-md-4 ps-3 pb-3"
              style={{ backgroundColor: "#def4ff" }}
            >
              <div className="row">
                <h6 className="mt-2">
                  {t("pages.patients.fields.socialSecurityInformation")}
                </h6>
                <hr />
                <FormInput
                  isSmall
                  containerClasses={"col-md-12 mb-2"}
                  id="socialSecurityNumber"
                  type="text"
                  value={patient.social_security_number}
                  onChange={(ev) => {
                    updatePatient(
                      { social_security_number: ev.target.value },
                      true
                    );
                    isValidForm();
                  }}
                  labelText={t("pages.patients.fields.socialSecurityNumber")}
                  required={false}
                  errorMessage={t("errors.minimum2characters")}
                  pattern={".{2,}"}
                  responseError={formResponseErrors.socialSecurityNumber}
                />
                <FormInput
                  isSmall
                  containerClasses={"col-md-12 mb-2"}
                  id="socialSecurityBarcode"
                  type="text"
                  value={patient.social_security_barcode}
                  onChange={(ev) => {
                    updatePatient(
                      { social_security_barcode: ev.target.value },
                      true
                    );
                    isValidForm();
                  }}
                  labelText={t("pages.patients.fields.socialSecurityBarcode")}
                  required={false}
                  errorMessage={t("errors.minimum2characters")}
                  pattern={".{2,}"}
                  responseError={formResponseErrors.socialSecurityBarcode}
                />

                <FormInput
                  isSmall
                  containerClasses={"col-md-6 mb-2"}
                  id="socialSecurityRegion"
                  type="text"
                  value={patient.social_security_region}
                  onChange={(ev) => {
                    updatePatient(
                      { social_security_region: ev.target.value },
                      true
                    );
                    isValidForm();
                  }}
                  labelText={t("pages.patients.fields.socialSecurityRegion")}
                  required={false}
                  errorMessage={t("errors.minimum2characters")}
                  pattern={".{2,}"}
                  responseError={formResponseErrors.socialSecurityRegion}
                />
              </div>
            </div>
          </div>{" "}
          {/* row */}
          <h6 className="mt-2">{t("pages.patients.fields.MoreInfo")}</h6>
          <hr className="mt-0" />
          {/* 2 */}
          <div className="row">
            <FormDate
              isSmall
              containerClasses={"col-md-4 mb-2"}
              id="date_of_birth"
              value={patient.date_of_birth}
              onChange={(data) => {
                updatePatient({ date_of_birth: data });
                isValidForm();
              }}
              labelText={t("pages.patients.fields.dateOfBirth")}
              errorMessage={t("errors.minimum2characters")}
            />

            <FormInput
              isSmall
              containerClasses={"col-md-4 mb-2"}
              id="doctorName"
              type="text"
              value={patient.doctor_name}
              onChange={(ev) => {
                updatePatient({ doctor_name: ev.target.value });
                isValidForm();
              }}
              labelText={t("pages.patients.fields.doctorName")}
              errorMessage={t("errors.minimum2characters")}
              pattern={".{2,}"}
              responseError={formResponseErrors.doctor_name}
            />

            <FormInput
              isSmall
              containerClasses={"col-md-4 mb-2"}
              id="roomNumber"
              type="text"
              value={patient.room_number}
              onChange={(ev) => {
                updatePatient({ room_number: ev.target.value });
                isValidForm();
              }}
              labelText={t("pages.patients.fields.roomNumber")}
              errorMessage={t("errors.minimum2characters")}
              pattern={".{2,}"}
            />
          </div>
          {/* 3 */}
          {/* <div className="row"> */}
          {/* <FormInput
              isSmall
              containerClasses="col-md-4 mb-2"
              id="socialSecurityNumber"
              type="text"
              value={patient.social_security_number}
              onChange={(ev) => { updatePatient({ social_security_number: ev.target.value }); isValidForm();}}
              labelText={t('pages.patients.fields.socialSecurityNumber')}
              errorMessage={t('errors.minimum2characters')}
              pattern={".{2,}"}
            /> */}
          {/* <FormInput
              isSmall
              containerClasses="col-md-4 mb-2"
              id="socialSecurityBarcode"
              type="text"
              value={patient.social_security_barcode}
              onChange={(ev) => { updatePatient({ social_security_barcode: ev.target.value }); isValidForm();}}
              labelText={t('pages.patients.fields.socialSecurityBarcode')}
              errorMessage={t('errors.minimum2characters')}
              pattern={".{2,}"}
              responseError={formResponseErrors.room_number}
            /> */}
          {/* <FormInput
              isSmall
              containerClasses="col-md-4 mb-2"
              id="socialSecurityRegion"
              type="text"
              value={patient.social_security_region}
              onChange={(ev) => { updatePatient({ social_security_region: ev.target.value }); isValidForm();}}
              labelText={t('pages.patients.fields.socialSecurityRegion')}
              errorMessage={t('errors.minimum2characters')}
              pattern={".{2,}"}
            /> */}
          {/* </div> */}
          {/* 4 */}
          <div className="row">
            <FormInput
              isSmall
              containerClasses={"col-md-3 mb-2"}
              id="nationalIdentityCardNumber"
              type="text"
              value={patient.national_identity_card_number}
              onChange={(ev) => {
                updatePatient({
                  national_identity_card_number: ev.target.value,
                });
                isValidForm();
              }}
              labelText={t("pages.patients.fields.nationalIdentityCardNumber")}
              errorMessage={t("errors.minimum2characters")}
              pattern={".{2,}"}
              responseError={formResponseErrors.national_identity_card_number}
            />

            <FormInput
              isSmall
              containerClasses={"col-md-3 mb-2"}
              id="contactPerson"
              type="text"
              value={patient.contact_person}
              onChange={(ev) => {
                updatePatient({ contact_person: ev.target.value });
                isValidForm();
              }}
              labelText={t("pages.patients.fields.contactPerson")}
              errorMessage={t("errors.minimum2characters")}
              pattern={".{2,}"}
              responseError={formResponseErrors.contact_person}
            />

            <FormInput
              isSmall
              containerClasses={"col-md-3 mb-2"}
              id="address"
              type="text"
              value={patient.address}
              onChange={(ev) => {
                updatePatient({ address: ev.target.value });
                isValidForm();
              }}
              labelText={t("pages.patients.fields.address")}
              errorMessage={t("errors.minimum2characters")}
              pattern={".{2,}"}
              responseError={formResponseErrors.address}
            />

            <FormInput
              isSmall
              containerClasses={"col-md-3 mb-2"}
              id="town"
              type="text"
              value={patient.town}
              onChange={(ev) => {
                updatePatient({ town: ev.target.value });
                isValidForm();
              }}
              labelText={t("pages.patients.fields.town")}
              errorMessage={t("errors.minimum2characters")}
              pattern={".{2,}"}
              responseError={formResponseErrors.town}
            />
          </div>
          {/* 4*/}
          <div className="row">
            <SelectCountries
              containerClassName="col-md-3 mb-2"
              isSmall
              id="country"
              value={patient.country}
              onChange={(value) => {
                updatePatient({ country: value });
                isValidForm();
              }}
              labelText={t("pages.patients.fields.country")}
            />

            <FormInput
              containerClasses="col-md-3 mb-2"
              isSmall
              id="zip_code"
              type="text"
              value={patient.zip_code}
              onChange={(ev) => {
                updatePatient({ zip_code: ev.target.value });
                isValidForm();
              }}
              labelText={t("pages.patients.fields.zipCode")}
              errorMessage={t("errors.minimum2characters")}
              pattern={".{2,}"}
              responseError={formResponseErrors.zip_code}
            />

            <FormInput
              containerClasses="col-md-3 mb-2"
              isSmall
              id="phonePrefix"
              type="text"
              value={patient.phone_prefix}
              onChange={(ev) => {
                updatePatient({ phone_prefix: ev.target.value });
                isValidForm();
              }}
              labelText={t("pages.patients.fields.phonePrefix")}
              errorMessage={t("errors.minimum2characters")}
              pattern={".{2,}"}
              responseError={formResponseErrors.phone_prefix}
            />

            <FormInput
              containerClasses="col-md-3 mb-2"
              isSmall
              id="phoneNumber"
              type="text"
              value={patient.phone_number}
              onChange={(ev) => {
                updatePatient({ phone_number: ev.target.value });
                isValidForm();
              }}
              labelText={t("pages.patients.fields.phoneNumber")}
              errorMessage={t("errors.minimum2characters")}
              pattern={".{2,}"}
              responseError={formResponseErrors.phone_number}
            />

            <div className="col-12 mt-3">
              <Link to={`${ROUTES.PATIENTS.INDEX}`}>
                <button className="btn btn-sm btn-outline-primary me-2">
                  {t("general.cancel")}
                </button>
              </Link>

              <button
                type="button"
                className="btn btn-sm btn-primary me-2"
                onClick={savePatient}
                disabled={cannotSubmit ? true : false}
              >
                {t("general.save")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withTranslation()(PatientsDetail);
