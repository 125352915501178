import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from './locales/en.json';
import translationES from './locales/es.json';
import translationCA from './locales/fr.json';
import translationFR from './locales/fr.json';
import translationPT from './locales/pt.json';
import translationIT from './locales/it.json';
import translationGR from './locales/gr.json';

// Utils
import { resetLanguageToDefault } from './utils/generalUtils'; 

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  es: {
    translation: translationES
  },
  ca: {
    translation: translationCA
  },
  fr: {
    translation: translationFR
  },
  pt: {
    translation: translationPT
  },
  it: {
    translation: translationIT
  },
  gr: {
    translation: translationGR
  },
};

const language = resetLanguageToDefault(navigator.language);

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: language,
    fallbackLng: "es",

    keySeparator: '.', // defaul was "false" so keys like "messages.welcome" were not valid. 

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;