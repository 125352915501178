import { nanoid } from "nanoid";
import classNames from 'classnames';

const Switch = ({
    id,
    value,
    onChange,
    labelText,
    classnames,
    isDisabled
}) => {

    const _id = id ? id : nanoid();

    return (
        <div
            className={classNames([
                "form-check form-switch",
                classnames
            ])}
        >
            <input
                className="form-check-input cursor-pointer"
                type="checkbox"
                id={_id}
                checked={value}
                onChange={onChange}
                disabled={isDisabled}
            />
            { labelText && 
                <label className="form-check-label" htmlFor={_id}>{labelText}</label>
            }
        </div>
    );
}
 
export default Switch;


