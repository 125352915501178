// Constants
import POSOLOGY_TYPES from '../../../../../constants/POSOLOGY_TYPES';
// Libs
import { withTranslation } from 'react-i18next';

const WeeklyTypeBtnGroup = ({
    t,
    disabled,
    onChange,
    weekTypeSelected,
    setWeekTypeSelected,
}) => {

    const handleOnChange = type => {
        onChange(type);
        setWeekTypeSelected(type);
    };

    const handleKeyPress = e => {
        if(e.code === 'Enter') {
            e.preventDefault();
        }
    };

    return (
        <div>
            <label>{t('pages.planning.texts.intakes')}:</label>
            <br />
            <div className="btn-group btn-group-lg" role="group" aria-label="Basic radio toggle button group">

                <input disabled={disabled} type="radio" className="btn-check" name="btnradiotomas" id="btnradio4" autoComplete="off" checked={weekTypeSelected === POSOLOGY_TYPES[0]}
                    onChange={() => handleOnChange(POSOLOGY_TYPES[0])} onKeyPress={e => handleKeyPress(e)}
                />
                <label className="btn btn-outline-primary" htmlFor="btnradio4">4 {t('pages.planning.texts.intakes')}</label>

                <input disabled={disabled} type="radio" className="btn-check" name="btnradiotomas" id="btnradio5" autoComplete="off" checked={weekTypeSelected === POSOLOGY_TYPES[1]}
                    onChange={() => handleOnChange(POSOLOGY_TYPES[1])} onKeyPress={e => handleKeyPress(e)}
                />
                <label className="btn btn-outline-primary" htmlFor="btnradio5">6 {t('pages.planning.texts.intakes')}</label>

                <input disabled={disabled} type="radio" className="btn-check" name="btnradiotomas" id="btnradio6" autoComplete="off" checked={weekTypeSelected === POSOLOGY_TYPES[2]}
                    onChange={() => setWeekTypeSelected(POSOLOGY_TYPES[2])} onKeyPress={e => handleKeyPress(e)}
                />
                <label className="btn btn-outline-primary" htmlFor="btnradio6">{t('general.custom-posologies')}</label>
            </div>
        </div>
    );
}

export default withTranslation()(WeeklyTypeBtnGroup);