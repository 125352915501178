import { useState, useEffect, useRef } from 'react';
import Classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';


const Select = ({
    t,
    id,
    value,
    options,
    isSmall,
    disabled,
    onChange,
    required,
    labelText,
    classnames,
    errorMessage,
    hasAllOption,
    hasChooseOption,
    needsValidation,
    containerClassNames,
}) => {

    const inputRef = useRef(null);
    const falseRef = useRef(null);
    const [_value, setValue] = useState(null);
    const [wasValidated, setWasValidated] = useState(false);
    const [isValid, setIsValid] = useState(true);

    const handleFocus = ev => {
        setWasValidated(true);
        setIsValid(ev.target.checkValidity());
    };

    const handleChange = (ev) => {
        setValue(ev.target.value);
        handleFocus(ev);
        onChange(ev);
    };

    useEffect( () => {
        setValue(value);
    },[value]);

    return (
        <div className={Classnames([
            (required || needsValidation) && "needs-validation",
            (required || needsValidation) && wasValidated && 'was-validated',
            containerClassNames
        ])}>
            { labelText &&
                <label htmlFor={id} className={Classnames([
                    "form-label", isSmall && 'form-label-sm'
                ])}>
                    {labelText}{' '}{required && <span>(*)</span>}
                </label>
            }
            <div className="input-group has-validation">
                <select
                    disabled={disabled}
                    ref={inputRef}
                    value={_value || ""}
                    className={Classnames([
                        'form-select w-100', classnames, !isValid && 'is-invalid', isSmall && 'form-select-sm'
                    ])}
                    onChange={ev => handleChange(ev)}
                >
                    { hasChooseOption &&
                        <option disabled value=''>{t('general.chooseOneOption')}</option>
                    }
                    { hasAllOption &&
                        <option value={null}>{t('general.all')}</option>
                    }
                    { options ? options.map(option => (
                        <option
                            value={option.value}
                            key={nanoid()}
                        >{option.name}</option>
                    )) : null }
                </select>
                <input
                    id={nanoid()}
                    ref={falseRef}
                    tabIndex={-1}
                    autoComplete="off"
                    style={{ opacity: 0, height: 0, position: 'absolute' }}
                    defaultValue={_value || ""}
                    required={required}
                    pattern={".{2,}"}
                />
                <div className="invalid-feedback">{errorMessage}</div>
            </div>
        </div>
    );
}

export default withTranslation()(Select);