// React & Libs
import { createContext, useState, useContext, useEffect } from "react";

const AppContext = createContext();

const AppProvider = props => {
  const getToken = () => (JSON.parse(sessionStorage['ti-medi-token']));

  const [ loggedIn, setLoggedIn ]                 = useState(sessionStorage['ti-medi-token'] !== undefined);
  const [ _refresh, setRefresh ]                  = useState(null);
  const [ userRole, setUserRole ]                 = useState(null);
  const [ isLoading, setIsLoading ]               = useState(false);
  const [ modalIsOpen, setModalIsOpen ]           = useState(false);
  const [ contentIsLoaded, setContentIsLoaded ]   = useState(true);
  const [ modules, setModules ]                   = useState([]);

  const [ sidebarIsExpanded, setSidebarIsExpanded ] = useState(true);

  const [ thirdParties, set3rdParties ] = useState(null);

  let loadingCounter;

  const [ modalUIVisible, setModalUIVisible ] = useState(false);
  
  const [ messagesVisible, setMessagesVisible ] = useState(false);

  const [ openPatientModal, setOpenPatientModal ] = useState(false);

  const handleModalClose = () => {
    setModalUIVisible(false);
      setTimeout(() => {
          setModalIsOpen(false);
    }, 1000);
  }

  const handleLoading = () => {
    loadingCounter = setTimeout(() => {
      setIsLoading(true);
      setContentIsLoaded(false);
    }, 200);
  };
  
  const handleNotLoading = () => {
    clearTimeout(loadingCounter);
    setTimeout(() => {
      setIsLoading(false);
      setContentIsLoaded(true);
    }, 200);
  };

  const [modalData, setModalData] = useState(
    {
      size: '',
      body: '',
      title: '',
      ctaText: '',
      action: null,
      closeText: '',
      hideModalAction: false
    }
  );

  useEffect(() => {
    setLoggedIn(sessionStorage['ti-medi-token'] !== undefined);
  }, [loggedIn])
  
  const AppContextValue = {
    modules,
    userRole,
    loggedIn,
    getToken,
    _refresh,
    modalData,
    isLoading,
    setRefresh,
    setModules,
    modalIsOpen,
    setLoggedIn,
    setUserRole,
    setIsLoading,
    thirdParties,
    setModalData,
    handleLoading,
    set3rdParties,
    modalUIVisible,
    setModalIsOpen,
    messagesVisible,
    contentIsLoaded,
    openPatientModal,
    handleModalClose,
    handleNotLoading,
    sidebarIsExpanded,
    setModalUIVisible,
    setMessagesVisible,
    setContentIsLoaded,
    setOpenPatientModal,
    setSidebarIsExpanded,
  };

  return (
    <AppContext.Provider
      value={AppContextValue}
      {...props}
    />
  );
};

const useAppProvider = () => useContext(AppContext);

export { AppProvider, useAppProvider}