// Libs & React
import { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import Classnames from "classnames";

// Router
import ROUTES from "../../constants/ROUTES";
import { Link, useParams, useHistory } from "react-router-dom";

// Contexts & Hooks && Services
import { useAppProvider } from "../../context/AppContext";
import useAPI from "../../hooks/useAPI";

// Components
import { toast } from "react-toastify";
import FormInput from "../../components/UI/FormInput";
import FormCheckbox from "../../components/UI/FormCheckbox";
import SelectCountries from "../../components/containers/SelectCountries";
import { Flags } from "react-feature-flags";

// Constants
import PERMISSIONS from '../../constants/PERMISSIONS';
import FloorsComponent from "./FloorsComponent";

const HospitalDetail = ({ t }) => {
  const { handleLoading, handleNotLoading, contentIsLoaded } = useAppProvider();
  const [ hospital, setHospital ] = useState({});

  let history = useHistory();
  const { hospital_id } = useParams();
  const isEditPage = hospital_id !== 'new';

  const { API, handleReqError } = useAPI();

  const canShowProductionSettings = false;
  const [ cannotSubmit, setcannotSubmit ] = useState(true);
  const defaultFormResponseErrors = {
    'name': '',
    'phone_prefix': '',
    'phone_number': '',
    'address': '',
    'zip_code': '',
    'town': '',
    'contact_person': '',
  };
  const [ formResponseErrors, setFormResponseErrors ] = useState({...defaultFormResponseErrors});

  // Is Valid Form // TODO: DRY
  const isValidForm = () => {
    setcannotSubmit(!formEl.current.checkValidity());
  };

  const formEl = useRef(null);

  // Send New Hospital Data
  const saveHospital = () => {
    handleLoading();
    clearResponseErrors();

    const url =
      hospital_id !== `new` ? `hospitals/${hospital_id}/` : "hospitals/";
    const method = isEditPage ? "put" : "post";

    API({
      method: method,
      url: url,
      data: hospital,
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          handleNotLoading();
          history.push(ROUTES.HOSPITALS.INDEX);
          toast(
            response.status === 200
              ? t("general.changesSavedProperly")
              : t("pages.hospitals.texts.newHospitalOk"),
            {
              position: toast.POSITION.TOP_CENTER,
              type: toast.TYPE.SUCCESS,
            }
          );
        }
      })
      .catch((error) => {
        // eslint-disable-next-line eqeqeq
        if(error.response.status == 400){
          setResponseErrors(error.response);
          handleNotLoading();
        }else{
          handleReqError(error)
        }
      });
  };

  // Update Hospital Data before Send
  const updateHospital = (data) => {
    setHospital({ ...hospital, ...data });
    isValidForm();
  };

  const clearResponseErrors = () => {
    setFormResponseErrors(defaultFormResponseErrors);
  };

  const setResponseErrors = (response) => {
    Object.entries(formResponseErrors).forEach(errorEntry => {
      let [key, errorText] = errorEntry;
      if(response.data.hasOwnProperty(key)){
        let error = {[key]: response.data[key][0]};
        setFormResponseErrors({...formResponseErrors, ...error});
      }
    });
  };

  // Get Data when editing
  useEffect(() => {
    if (hospital_id !== 'new') {
      handleLoading();
      API.get(`hospitals/${hospital_id}/`)
        .then((response) => {
          handleNotLoading();
          setHospital(response.data);
        })
        .catch((error) => handleReqError(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hospital_id]);

  return (
    <div className="ContentWrapper container p-4">
      <h2 className="font-header text-primary display-6 fw-bold dots-side-left fwp mb-4">
        {t(
          isEditPage
            ? "pages.hospitals.titles.edit"
            : "pages.hospitals.titles.create"
        )}
      </h2>
      { contentIsLoaded && (
        <div className="card p-2">
        <form ref={formEl} className={Classnames(["row g-3 p-3"])}>
          <h6>{t("pages.hospitals.fields.mainInfo")}</h6>
          <hr />

          {/* row 1 */}
          <FormInput
            id="name"
            type="text"
            value={hospital.name}
            containerClasses={"col-md-6"}
            labelText={t("pages.hospitals.fields.name")}
            required={true}
            errorMessage={t("errors.minimum2characters")}
            pattern={".{2,}"}
            responseError={formResponseErrors.name}
            onChange={(ev) => {
              updateHospital({ name: ev.target.value });
              isValidForm();
            }}
          />

          <FormInput
            id="prefix"
            type="text"
            value={hospital.phone_prefix}
            responseError={formResponseErrors.phone_prefix}
            onChange={(ev) => updateHospital({ phone_prefix: ev.target.value })}
            labelText={t("pages.hospitals.fields.phonePrefix")}
            containerClasses={"col-md-2"}
          />

          <FormInput
            id="phone"
            type="tel"
            value={hospital.phone_number}
            responseError={formResponseErrors.phone_number}
            onChange={(ev) => updateHospital({ phone_number: ev.target.value })}
            labelText={t("pages.hospitals.fields.phoneNumber")}
            containerClasses={"col-md-4"}
          />

          {/* row 2 */}
          <FormInput
            id="address"
            type="text"
            value={hospital.address}
            responseError={formResponseErrors.address}
            onChange={(ev) => updateHospital({ address: ev.target.value })}
            labelText={t("pages.hospitals.fields.address")}
            containerClasses={"col-md-8"}
          />

          <FormInput
            id="postcode"
            type="text"
            value={hospital.zip_code}
            responseError={formResponseErrors.zip_code}
            onChange={(ev) => updateHospital({ zip_code: ev.target.value })}
            labelText={t("pages.hospitals.fields.zipCode")}
            containerClasses={"col-md-4"}
          />

          <FormInput
            id="town"
            type="text"
            value={hospital.town}
            responseError={formResponseErrors.town}
            onChange={(ev) => updateHospital({ town: ev.target.value })}
            labelText={t("pages.hospitals.fields.town")}
            containerClasses={"col-md-6"}
          />

          <SelectCountries
            value={hospital.country}
            onChange={(value) => updateHospital({ country: value })}
            labelText={t("pages.hospitals.fields.country")}
            containerClassName={"col-md-2"}
            id="country"
          />

          {/* row 3 */}

          <FormInput
            id="contact"
            type="text"
            value={hospital.contact_person}
            responseError={formResponseErrors.contact_person}
            onChange={(ev) =>
              updateHospital({ contact_person: ev.target.value })
            }
            labelText={t("pages.hospitals.fields.contactPerson")}
            containerClasses={"col-md-6"}
          />

          <hr />

          { hospital_id !== 'new' &&
          <div className="row mb-4">
            <FloorsComponent
              containerClass="col-md-12"
              hospitalID={hospital_id}
            />
          </div>
          }

          {canShowProductionSettings && (
            <>
              <hr />
              <h6>{t("pages.hospitals.fields.productionSettings")}</h6>
              <hr />
              <FormCheckbox
                containerClassName="col-md-4"
                id="treatmentsNeeded"
                value={hospital.produce_treatments_if_needed}
                onChange={(ev) =>
                  updateHospital({
                    produce_treatments_if_needed: ev.target.value,
                  })
                }
                labelText={t(
                  "pages.hospitals.fields.produceTreatmentsIfNeeded"
                )}
              />
              <FormCheckbox
                containerClassName="col-md-4"
                id="treatmentsSpecial"
                value={hospital.produce_treatments_in_special_dosage}
                onChange={(ev) =>
                  updateHospital({
                    produce_treatments_in_special_dosage: ev.target.value,
                  })
                }
                labelText={t(
                  "pages.hospitals.fields.produceTreatmentSpecialDosage"
                )}
              />
              <FormCheckbox
                containerClassName="col-md-4"
                id="ExternalTreatments"
                value={hospital.produce_external_treatments}
                onChange={(ev) =>
                  updateHospital({
                    produce_external_treatments: ev.target.value,
                  })
                }
                labelText={t(
                  "pages.hospitals.fields.produceExternalTreatments"
                )}
              />
              <hr />
            </>
          )}

          <hr />

          <div className="col-12">
            <Link to={`${ROUTES.HOSPITALS.INDEX}`}>
              <button className="btn btn-outline-primary me-2">
                {t("general.cancel")}
              </button>
            </Link>
            <Flags authorizedFlags={[PERMISSIONS['CAN_EDIT_HOSPITALS']]}>
              <button
                type="button"
                className="btn btn-primary me-2"
                onClick={() => saveHospital()}
                disabled={cannotSubmit ? true : false}
              >
                {t("general.save")}
              </button>
            </Flags>
          </div>
        </form>
      </div>
      )}
    </div>
  );
};

export default withTranslation()(HospitalDetail);
