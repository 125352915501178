// Components
import Modal from "react-bootstrap/Modal";

// Libs
import { withTranslation } from "react-i18next";

const ModalDelete = ({
    t,
    show,
    handleClose,
    handleDelete,
}) => {
    return (
        <Modal show={show} onHide={handleClose} size={'l'} animation={false}>
        <Modal.Header closeButton>
            <Modal.Title>{t('general.delete')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>{t('general.areYouSure')}</p>
        </Modal.Body>
        <Modal.Footer>
            <button
              data-cy="button-cancel-modal-delete"
              onClick={() => handleClose()}
              className="btn btn-outline-primary">
                {t('general.cancel')}
            </button>
            <button
              data-cy="button-confirm-modal-delete"
              onClick={() => handleDelete()}
              className="btn btn-danger">
                {t('general.delete')}
            </button>
        </Modal.Footer>
    </Modal>
    );
}

export default withTranslation()(ModalDelete);