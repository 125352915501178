import PropTypes from 'prop-types'; // ES6
import ClassNames from 'classnames';
import { withTranslation } from 'react-i18next';

const SearchBar = ({placeholder, className, onChange, t}) => {

    //setup before functions
    let typingTimer;                //timer identifier
    const doneTypingInterval = 3000;  //time in ms, 5 second for example

    //on keyup, start the countdown
    const handleKeyUp = (ev) => {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(handleOnChange(ev.target.value), doneTypingInterval);
    };

    //on keydown, clear the countdown 
    const handleKeyDown = () => {
        clearTimeout(typingTimer);
    };

    const handleOnChange = string => onChange(string);

    return (
        <div className={ClassNames([
            "SearchBar", className
        ])}>
            <div className="input-group">
                <input
                    type="text"
                    placeholder={placeholder ? placeholder : `${t('general.search')} ...`}
                    name="search"
                    className="form-control search-input"
                    onKeyUp={handleKeyUp}
                    onKeyDown={handleKeyDown}
                />
                <button
                    type="submit"
                    className="btn search-btn btn-primary"
                    value="Search"
                >
                    <span className="material-icons">search</span>
                </button>
            </div>
        </div>
    );
}

export default withTranslation()(SearchBar);

SearchBar.propTypes = {
    placeholder: PropTypes.string
}

SearchBar.defaultProps = {
    placeholder: null
}


