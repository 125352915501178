const MODULES = {
    HOSPITALS: "hospitals",
    PATIENTS: "patients",
    BOOK: "therapeutic-book",
    TREATMENTS: "treatments",
    PROPOSALS: "treatment-proposals",
    PROPOSALS_VALIDATION: "treatment-proposals-validation",
    PRODUCTIONS: "productions",
    USERS: "users"
};

export default MODULES