import React, { useState, useEffect } from 'react';
import { useTreatmentsProvider } from "../../../../../context/TreatmentsContext";
import {nanoid} from 'nanoid';
import { useAppProvider } from '../../../../../context/AppContext';
import useAPI from "../../../../../hooks/useAPI";
import TreatmentItem from '../TreatmentItem';
import { withTranslation } from 'react-i18next';

const CurrentTreatmentsGrid =  React.memo(({t}) => {
    const {
        activePatientMedicines,
        setActiveMedicine,
        setActiveMedicineIsProposal,
        setMedicinePosologies,
        setDispPosologies,
        setShowPosologiesModal,
        setActiveBookMedicine,
        setIsStoppedMedicine
    } = useTreatmentsProvider();

    const {handleLoading, handleNotLoading
    } = useAppProvider();
    const { API, handleReqError } = useAPI();

    const handleClickMedicine = (medicine) => {
        handleLoading();
        setIsStoppedMedicine(false);
        const medicine_id = medicine.treatment_medicine;
        const bookMedicine = medicine.id;
        setActiveMedicine(medicine_id);
        setActiveBookMedicine(bookMedicine);
        API.get(`posologies/?treatment_medicine=${medicine_id}&active=true`)
        .then((response) => {
            handleNotLoading();
            if (response) {
                setActiveMedicineIsProposal(false);
                setMedicinePosologies(response.data.results);
                setDispPosologies(response.data.results);
                setShowPosologiesModal(true);
            }
        })
        .catch((error) => handleReqError(error));
    };

    const [_activePatientMedicines, setIntActivePatMeds] = useState(null);

    useEffect(() => {
        setIntActivePatMeds(activePatientMedicines ? [...activePatientMedicines] : null);
    },[activePatientMedicines]);

    return (
        <div>
        { _activePatientMedicines &&
        <>
        <div
            className="ProposalsGrid__Header d-flex align-items-center p-2 mb-2"
        >
            <span
                className="material-icons-outlined align-middle me-2"
            >today</span>
            <span>{t('pages.proposals.currentTreatment')}</span>
        </div>
        <div
            className="ProposalsGrid CurrentTreatmentsGrid ps-2 pb-4"
            style={{
                gridColumn: '1/17',
            }}
        >
            { activePatientMedicines && activePatientMedicines.length > 0
                ? activePatientMedicines.map((medicine, index) =>
                    (
                        <div
                            className="ProposalsGrid"
                            key={nanoid()}
                        >
                            <TreatmentItem
                                index={index}
                                key={nanoid()}
                                medicine={medicine}
                                handleClickMedicine={handleClickMedicine}
                                isProposalPage={true}
                            />
                        </div>
                    )
                ) : <p className="text-left" style={{
                    position: 'relative',
                    left: '188px'
                }}>{t('pages.proposals.noElements')}</p>
            }
        </div>
        </>
        }
        </div>
    );
})

export default withTranslation()(CurrentTreatmentsGrid);