import Modal from 'react-modal';
import Classnames from 'classnames';

import './styles.scss';
import { useAppProvider } from '../../../context/AppContext';

export const MODAL_SIZE = [ 'small', 'medium', 'large', 'extra_large'];

const ModalWrapper = () => {
    const {
        modalData,
        modalIsOpen,
        modalUIVisible,
        setModalIsOpen,
        setModalUIVisible,
    } = useAppProvider();

    
    const handleClose = () => {
        setModalUIVisible(false);
        setTimeout(() => {
            setModalIsOpen(false);
        }, 1000);
    }

    const handleAction = () => {
        modalData.action();
        handleClose();
    }

    const modalStyle = {
        zIndex: '9999',
        overlay: {
            backgroundColor: 'rgba(0,0,0,0.8)'
        }
    }

    return (
        <Modal
            isOpen={ modalIsOpen }
            onAfterOpen={ () => setModalUIVisible(true) }
            onRequestClose={ handleClose }
            style={ modalStyle }
            ariaHideApp={ false }
            overlayClassName={Classnames([
                'ModalOverlay',
                modalUIVisible && 'ModalOverlay--Active'
            ])}
        >
            {/* Modal Bootstrap */}
            <div
                className={Classnames([
                    "modal d-block fade",
                    modalUIVisible && 'show'
                ])}
                id="modal"
                tabIndex="-1"
                aria-labelledby="modal"
                aria-hidden={!modalUIVisible}
            >
                <div className={Classnames([
                    "modal-dialog",
                    modalData.size === MODAL_SIZE[0] && 'modal-sm',
                    modalData.size === MODAL_SIZE[2] && 'modal-lg',
                    modalData.size === MODAL_SIZE[3] && 'modal-xl',
                ])}>
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5
                            className="modal-title"
                            id="modal"
                        >{modalData.title}</h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => handleClose()}
                        ></button>
                    </div>
                    <div className="modal-body">
                        {modalData.body}
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={() => handleClose()}
                        >{modalData.closeText}</button>
                        { !modalData.hideModalAction && 
                            <button 
                                type="button" 
                                className="btn btn-primary"
                                onClick={() => handleAction()}
                            >{modalData.ctaText}</button>
                        }
                    </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}


export default ModalWrapper;

