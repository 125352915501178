// React & Libs
import { useEffect, useState } from "react";
import { nanoid } from "nanoid";
import { withTranslation } from "react-i18next";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

// Contexts & Hooks
import { useAppProvider } from "../../context/AppContext";

// Router
import { Link } from "react-router-dom";
import ROUTES from "../../constants/ROUTES";
import { useHistory } from "react-router-dom";

// Components
import Pagination from "../../components/UI/Pagination";
import useAPI from "../../hooks/useAPI";
import useToastCustom from "../../hooks/useToastCustom";
import NoResults from "../../components/UI/NoResults";
import ModalProductionDetail from "./Modals/ModalProductionDetail";
import ModalDelete from '../../components/CommonModals/ModalDelete';

// Utils
import {DDMMYYYY_HHMMSS, DDMMYYYY} from "../../utils/dateUtils";

const ProductionsList = ({ t }) => {
  const {
    modules,
    thirdParties,
    handleLoading,
    contentIsLoaded,
    handleNotLoading,
    set3rdParties,
  } = useAppProvider();

  const { API, handleReqError } = useAPI();
  const { showErrorMessage, showSuccessMessage } = useToastCustom();

  const [totalPages, setTotalPages]   = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [productions, setProductions] = useState([]);
  const [showProductionDetailModal, setShowProductionDetailModal] = useState(false);
  const [currentProduction, setCurrentProduction] = useState(null);

  const [ showModalDelete, setShowModalDelete ] = useState(false);

  const getLoginCredentials = () => {
    API.get('third-party-login-credentials/?type=dualblist')
    .then((response) => {
        if (response) {
            set3rdParties(response.data.results);
        }
    })
    .catch((error) => console.log(error));
};

  const getProductions = (searchText) => {
    handleLoading();
    const url = searchText
      ? `productions/?text=${searchText}`
      : `productions/?page=${currentPage}`;
    API.get(url)
      .then((response) => {
        handleNotLoading();
        if (response) {
          setTotalPages(response.data.num_pages);
          setProductions(response.data.results);
        }
      })
      .catch((error) => handleReqError(error));
  };

  useEffect(() => {
    getProductions();
    if(modules && modules.includes('productions')) {
      getLoginCredentials();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const deleteProduction = () => {
    handleLoading();
    API.delete(`productions/${currentProduction}/`)
      .then((response) => {
        handleNotLoading();
        setShowModalDelete(false);
        if (response.status === 409) {
          showErrorMessage(t("pages.productions.texts.cannotDeleteBecauseUsed"));
        } else if (response.status === 204) {
          getProductions();
          showSuccessMessage(t("pages.productions.texts.deleteOk"));
        } else {
          showErrorMessage(t("pages.productions.texts.errorDeleting"));
        }
        handleNotLoading();
      })
      .catch((error) => {
        const messageError =
          error.request.status === 409
            ? t("pages.productions.texts.cannotDeleteBecauseUsed")
            : error;
        handleReqError(error, messageError);
      });
  };

  const exportProduction = (production_id, login_credentials) => {
    handleLoading();
    API({
      method: 'post',
      url: `productions/${production_id}/export/`,
      data: { "login_credentials" : login_credentials }
    })
    .then((response) => {
      handleNotLoading();
      if(response.status === 201) {
        showSuccessMessage(t('pages.productions.texts.exportProductionSuccessMessage'))
        getProductions()
      }
    })
    .catch((error) => {
      handleReqError(error,t('pages.productions.texts.exportProductionErrorMessage'));
    });
  }

  let history = useHistory();

  const productionsRows = productions.map((production) => (
    <tr key={nanoid()} className="align-middle cursor-pointer onHover">
      <td
       onClick={() => {
         setCurrentProduction(production);
         setShowProductionDetailModal(true);
       }}>
      {production.hospital}</td>
      <td
       onClick={() => {
        setCurrentProduction(production);
        setShowProductionDetailModal(true);
      }}>{production.num_patients}</td>
      <td
       onClick={() => {
        setCurrentProduction(production);
        setShowProductionDetailModal(true);
      }}>{DDMMYYYY_HHMMSS(production.created_at)}</td>
      <td
       onClick={() => {
        setCurrentProduction(production);
        setShowProductionDetailModal(true);
      }}>{DDMMYYYY(production.start_date)}</td>
      <td
       onClick={() => {
        setCurrentProduction(production);
        setShowProductionDetailModal(true);
      }}>{DDMMYYYY(production.end_date)}</td>
      <td
       onClick={() => {
        setCurrentProduction(production);
        setShowProductionDetailModal(true);
      }}>{production.last_exported_at ? DDMMYYYY_HHMMSS(production.last_exported_at):''}</td>
      <td>
        <div className="d-flex">
        { thirdParties && thirdParties.length > 1 &&
        <DropdownButton
          size="sm"
          id={t("general.send")}
          title={<><span className="material-icons">send</span> <span className="text-capitalize">{t("general.send")}</span></>}
          variant={'outline-primary'}
        >
          {thirdParties.map(machine => (
            <Dropdown.Item
              key={nanoid()}
              onClick={() => exportProduction(production.id, machine.id)}
            >
              {machine.name ? machine.name : machine.type}
            </Dropdown.Item>
          ))}
        </DropdownButton>
        }

        { thirdParties && thirdParties.length === 1 &&
          thirdParties.map(machine => (
          <button
            key={nanoid()}
            className="btn btn-outline-primary btn-sm me-2"
            onClick={(e) => {
              e.stopPropagation();
              exportProduction(production.id, machine.id);
            }}
          >
            <span className="material-icons">send</span>{" "}
            <span className="text-capitalize">{t("general.send")}</span>
          </button>
          ))
        }

        <button
          className="btn btn-outline-primary btn-sm ms-2"
          onClick={(e) => {
            e.stopPropagation();
            setCurrentProduction(production.id);
            setShowModalDelete(true);
          }}
        >
          <span className="material-icons">delete</span>{" "}
          <span>{t("general.delete")}</span>
        </button>

        </div>
      </td>
    </tr>
  ));


  return (
    <div className="ContentWrapper container p-4">
      <h2 className="font-header text-primary display-6 fw-bold dots-side-left fwp mb-4">
        {t("pages.productions.titles.list")}
      </h2>
      <div className="card p-2">
        <div className="d-flex py-2 pb-2 pb-3 mb-3 border-bottom">
          <div className="ms-auto">
            <Link to={`${ROUTES.PRODUCTIONS.NEW}`}>
              <button
                onClick={ () => history.push(ROUTES.PRODUCTIONS.NEW) }
                className="btn btn-primary btn-sm px-2 mb-2"
              >
                <span className="material-icons me-2">add_box</span>
                <span>{t("pages.productions.titles.create")}</span>
              </button>
            </Link>
          </div>
        </div>
        { contentIsLoaded && productions.length > 0 ? (
          <>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">{t("pages.productions.fields.hospital")}</th>
                  <th scope="col">{t("pages.productions.fields.numPatients")}</th>
                  <th scope="col">{t("pages.productions.fields.createdAt")}</th>
                  <th scope="col">{t("pages.productions.fields.startDate")}</th>
                  <th scope="col">{t("pages.productions.fields.endDate")}</th>
                  <th scope="col">{t("pages.productions.fields.lastExportedAt")}</th>
                  <th scope="col">{t("general.action")}</th>
                </tr>
              </thead>
              <tbody>{productionsRows}</tbody>
            </table>
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={(pageNumber) => setCurrentPage(pageNumber)}
            />
          </>
        ) : (
          <>
            <NoResults />
          </>
        )}
      </div>
      <ModalProductionDetail
        show={showProductionDetailModal}
        handleClose={() => {
          setShowProductionDetailModal(false);
          setCurrentProduction(null);}
        }
        production={currentProduction}
      />
      <ModalDelete
        show={showModalDelete}
        handleDelete={deleteProduction}
        handleClose={() => {
          setShowModalDelete(false);
          setCurrentProduction(null);
        }}
      />
    </div>
  );
};

export default withTranslation()(ProductionsList);





