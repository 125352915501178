import React from 'react';
import { useTreatmentsProvider } from '../../../../../context/TreatmentsContext';
import DaysHeaderRow from '../DaysHeaderRow';

import ProposalsGrid from '../ProposalsGrid';
import CurrentTreatmentGrid from '../CurrentTreatmentsGrid';

import './styles.scss';
import { Flags } from "react-feature-flags";

import MODULES from '../../../../../constants/MODULES';


// Libs
import { withTranslation } from 'react-i18next';

const TreatmentsGrid = React.memo(({ t }) => {

    const {
        setShowMedicinesModal,
        activePatient,
    } = useTreatmentsProvider();

    return (
        <div
            className="TreatmentsGrid pt-2"
            style={{
                minHeight: 'calc(100vh - 60px)'
            }}
        >
            <div
                className="TreatmentsGrid__SubHeader d-grid"
                style={{
                    paddingTop: '20px',
                    gridColumn: '1 / 17',
                    gridTemplateColumns: '180px repeat(15, 80px)',
                    width: '1380px',
                    backgroundColor: '#F3FBFF',
                    position: 'sticky',
                    top: '70px',
                    zIndex: 9,
                }}
            >
                <div className="AddMedicineButton d-flex align-items-center px-2 border-end">
                    <Flags authorizedFlags={[MODULES["PROPOSALS_VALIDATION"] ]}>
                        <button
                            className="btn btn-primary w-100 mb-2"
                            disabled={activePatient === null}
                            onClick={() => setShowMedicinesModal(true)}
                        >
                            <span className="material-icons">add</span>
                            <span>{t('pages.bookMedicines.texts.addMedicine')}</span>
                        </button>
                    </Flags>
                    <Flags authorizedFlags={[ MODULES["PROPOSALS"] ]}>
                        <button
                                className="btn btn-warning w-100 mb-2"
                                disabled={activePatient === null}
                                onClick={() => setShowMedicinesModal(true)}
                            >
                                { activePatient !== null &&
                                <div className="d-flex align-items-center">
                                    <span className="material-icons-outlined me-3">add</span>
                                    <span className="text-start" style={{ fontSize: '11px'}}>{t('pages.bookMedicines.texts.addProposal')}</span>
                                </div>
                                }

                                { activePatient === null &&
                                <div className="d-flex align-items-center">
                                    <span className="material-icons-outlined me-3">west</span>
                                    <span className="text-start" style={{ fontSize: '11px'}}>
                                        {t('pages.proposals.selectPatient')}
                                    </span>
                                </div>
                                }

                        </button>
                    </Flags>

                </div>
                <DaysHeaderRow />
            </div>
            <ProposalsGrid />
            <CurrentTreatmentGrid />
        </div>
    );
});

export default withTranslation()(TreatmentsGrid);