import { useEffect , useState} from "react";
import { withTranslation } from "react-i18next";
import useAPI from "../../../hooks/useAPI";
import useToastCustom from "../../../hooks/useToastCustom";
import ModalEditFloor from "./ModalEditFloor";
import ModalAlert from "./ModalAlert";
import { nanoid } from "nanoid";
import { useAppProvider } from "../../../context/AppContext";

const FloorsComponent = ({
    t,
    hospitalID,
    containerClass
}) => {

    const [ name, setName ]     = useState('');
    const [ floors, setFloors ] = useState([]);
    const [ showEditFloor, setShowEditFloor ]       = useState(false);
    const [ selectedFloor, setSelectedFloor ]       = useState(null);
    const [ currentFloorID, setCurrentFloorID ]     = useState(null);
    const [ showAlertDelete, setShowAlertDelete ]   = useState(false);

    const { API } = useAPI();
    const { showSuccessMessage, showErrorMessage } = useToastCustom();
    const { handleLoading, handleNotLoading } = useAppProvider();

    const getFloors = () => {
        if(hospitalID !== 'new') {
            handleLoading();
            const url = `hospital-floors/?hospital=${hospitalID}`;
            API.get(url)
            .then(response => {
                setFloors(response.data.results);
                handleNotLoading();
            })
            .catch(error => {
                handleNotLoading();
                showErrorMessage(t('general.errorFetch'));
                console.log(error)
            });
        }
    };

    const refreshWindow = () => {
        setShowEditFloor(false);
        getFloors();
    };

    const addFloor = () => {
        if(name !== '') {
            const url = 'hospital-floors/';
            const data = {
                hospital: hospitalID,
                name: name,
            };
            API.post(url, data)
            .then(response => {
                showSuccessMessage(t('general.changesSavedProperly'));
                setName('');
                refreshWindow();
            })
            .catch(error => {
                console.log(error);
                // eslint-disable-next-line eqeqeq
                if(error.response.status == 400) {
                    showErrorMessage(t('general.nameExists'));
                }
            })
        }
    };

    const editFloor = (floorID, newName) => {
        const url = `hospital-floors/${floorID}/`;
        const data = { name: newName }
        API.put(url, data)
        .then(response => {
            showSuccessMessage(t('general.changesSavedProperly'));
            refreshWindow();
        })
        .catch(error => {
            console.log(error);
            // eslint-disable-next-line eqeqeq
            if(error.response.status == 409) {
                showErrorMessage(t('general.nameExists'));
            } else {
                refreshWindow();
            }
        });
    };

    const deleteFloor = (floorID) => {
        const url = `hospital-floors/${floorID}`;
        API.delete(url)
        .then(response => {
            showSuccessMessage(t('general.changesDeletedProperly'));
            getFloors();
            setShowEditFloor(false);
        })
        .catch(error => {
            showErrorMessage(t('general.errorFetch'));
            console.log(error);
        });
    };

    const handleOnChange = event => {
        event.preventDefault();
        event.stopPropagation();
        setName(event.target.value);
    };

    const handleDelete = () => {
        setShowAlertDelete(false);
        deleteFloor(currentFloorID);
    };

    const handlePrepareDelete = floor_id => {
        setShowEditFloor(false);
        setShowAlertDelete(true);
        setCurrentFloorID(floor_id);
    };

    const handleKeyPress = event => {
        if(event.code === 'Enter' && event.target.value.trim() !== ''){
            addFloor();
        };
    };

    useEffect(() => {
        getFloors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hospitalID])

    return (
        <div
            className={containerClass}
        >
            <div className="row">
                <div className="col-4">
                        <label id="label_add_floor" className="form-label mt-3">{t('pages.hospitals.floors.addLabel')}</label>
                        <div className="input-group mb-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t('pages.hospitals.floors.addPlaceholder')}
                                aria-label={t('pages.hospitals.floors.ariaLabel')}
                                aria-describedby="label_add_floor"
                                value={name}
                                onKeyPress={e => handleKeyPress(e)}
                                onChange={e => handleOnChange(e)}
                            />
                            <button
                                disabled={name === '' ? true : false}
                                type="button"
                                className="btn btn-primary"
                                onClick={() => addFloor()}
                            >{t('general.add')}</button>
                        </div>
                    </div>

                { floors && floors.length > 0 &&
                    <div className="col-4">
                        <label className="form-label">{t('pages.hospitals.floors.title')}</label>
                        <ul
                            className="list-group list-group-flush border pb-0"
                            style={{
                                maxHeight: '488px',
                                overflow: 'auto'
                            }}
                        >
                        { floors.map(floor => (
                            <li
                                key={nanoid()}
                                className="list-group-item onHover showOnHover d-flex justify-content-between align-items-center"
                            >
                                <span className="py-2">{floor.name}</span>
                                <button
                                    onClick={(e) => {e.preventDefault(); setSelectedFloor(floor); setShowEditFloor(true)}}
                                    className="btn btn-sm btn-outline-primary"
                                >
                                    <span className="material-icons-outlined me-2">edit</span>
                                    <span>{t('general.edit')}</span>
                                </button>
                            </li>
                        )) }
                        </ul>
                </div>
                }
            </div>
            <ModalEditFloor
                show={showEditFloor}
                handleClose={() => setShowEditFloor(false)}
                floor={selectedFloor}
                deleteFloor={deleteFloor}
                editFloor={editFloor}
                handlePrepareDelete={handlePrepareDelete}
            />
            <ModalAlert
                show={showAlertDelete}
                handleClose={() => setShowAlertDelete(false)}
                deleteFloor={deleteFloor}
                editFloor={editFloor}
                handleDelete={handleDelete}
            />
        </div>
    );
}

export default withTranslation()(FloorsComponent);
