import './styles.scss';
import Classnames from 'classnames';
import { useRef } from 'react';

const ButtonAdderInput = ({
    id,
    value,
    onClick,
    disabled,
    fontSize,
    className,
    isFocused,
    isDoseSelector,
    handleOnChange
}) => {

    const handleOnInput = e => {
        handleOnChange(e.target.value);
    };

    const inputEl = useRef(null);

    const selectAll = () => {
        if(inputEl) {
            inputEl.current.select();
        }
    };

    const handleKeyPress = e => {
        if(e.code === 'Enter') {
            e.preventDefault();
        }
    }

    return (
        <div
            id={id}
            className={Classnames([
                "ButtonAdder btn d-flex align-items-center",
                !isDoseSelector && 'w-100 h-100 p-0 ',
                isFocused ? "btn-primary " : "btn-outline-primary ",
                disabled && 'disabled',
                className,
            ])}
            style={{
                fontSize: fontSize && `${fontSize}px`
            }}
            onClick={onClick}
        >
            <input
                disabled={disabled}
                className={Classnames([
                    "text-center border-0 inputNoArrows w-100 bg-transparent",
                    isFocused && "text-white",
                    disabled && 'disabled'
                ])}
                type="number"
                ref={inputEl}
                onClick={selectAll}
                onInput={e => handleOnInput(e)}
                step="1"
                value={value ? value.toString() : undefined}
                onKeyPress={e =>  handleKeyPress(e)}
            />
        </div>
    );
}

export default ButtonAdderInput;