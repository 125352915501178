import i18next from 'i18next';

const PROPOSALS_FILTERS = [
    {
        labelText: i18next.t('pages.proposals.proposalsStatus.pending'),
        bgColor: 'bg-orange',
        icon: 'help',
        id: 'unapplied_pending'
    },
    {
        labelText: i18next.t('pages.proposals.proposalsStatus.valid'),
        bgColor: 'bg-success',
        icon: 'done',
        id: 'approved'
    },
    {
        labelText: i18next.t('pages.proposals.proposalsStatus.rejected'),
        bgColor: 'bg-danger',
        icon: 'close',
        id: 'rejected'
    },
    {
        labelText: i18next.t('general.appliedF') + ' ' + i18next.t('pages.proposals.proposalsStatus.appliedPending'),
        bgColor: 'bg-warning',
        icon: 'pending_actions',
        id: 'applied_pending'
    }
];

export default PROPOSALS_FILTERS;