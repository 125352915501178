// components
import Header from '../Header';
// import BreadCrumb from '../BreadCrumb';
import './styles.scss';
import classNames from 'classnames';
// Context
import { useAppProvider } from '../../../context/AppContext';
const MainWrapper = ({children}) => {
    const { sidebarIsExpanded } = useAppProvider();

    return (
       <div className={classNames([
            "MainWrapper bg-lighter-blue",
            !sidebarIsExpanded && "MainWrapper--SidebarCollapsed"

        ])}>
            <Header />
            <div className="Content">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    {/* <BreadCrumb /> */}
                    {children}
                </div>
            </div>
        </div> 
    );
}
 
export default MainWrapper;

