 // React & CSS
import React from 'react';
import { useEffect, useState } from 'react';
import './styles.scss';

// Context & Hooks
import { useTreatmentsProvider } from "../../../../../context/TreatmentsContext";
import useAPI from '../../../../../hooks/useAPI';
import { useAppProvider } from '../../../../../context/AppContext';
import useToastCustom from '../../../../../hooks/useToastCustom';

// Libs
import { Flags } from "react-feature-flags";
import { toast } from 'react-toastify';
import { nanoid } from 'nanoid';
import { withTranslation } from 'react-i18next';

// Components
import AcceptDecline from '../../ui/AcceptDecline';
import ProposalItem from '../ProposalItem';
import FormCheckbox from "../../../../../components/UI/FormCheckbox"
import MessagesLogsCTA from "../../ui/MessagesLogsCTA";

// Constants
import MODULES from '../../../../../constants/MODULES';

const ProposalsGrid = React.memo(({t}) => {
    const {
        evaluateError,
        handleProposalsFilter,
        proposalsPatientMedicines,
    } = useTreatmentsProvider();

    const { API, handleReqError } = useAPI();
    const { handleLoading, handleNotLoading } = useAppProvider();
    const { showSuccessMessage, showErrorMessage } = useToastCustom();

    const [ actionsAreEnabled, setActionsAreEnabled ] = useState(false);
    const [ proposalsSelected, setProposalsSelected ] = useState(null);

    const refreshPatient = () => {
         handleProposalsFilter();
    };

    const handleAPIApplyCall = (treatmentMedicineID) => {
        if(actionsAreEnabled === true) {
            handleLoading();
            const url = `treatment-medicines/${treatmentMedicineID}/apply_proposal/`;

            API.post(url)
                .then( data => {
                    const message = t('general.changesSavedProperly');
                    showSuccessMessage(message);
                    toast.clearWaitingQueue();
                    refreshPatient();
                })
                .catch((error) => evaluateError(error))
            handleNotLoading();
        } else {
            showErrorMessage('general.chooseOneOption');
        }
    };

    const removeNotSelected = (proposals) => {
        for (var propName in proposals) {
          if (proposals[propName] === false || proposals[propName] === null || proposals[propName] === undefined) {
            delete proposals[propName];
          }
        }
        return proposals;
    };

    const handleAPIAcceptDeclineCall = (treatmentMedicineID,isAccept) => {
        if(actionsAreEnabled === true) {
            handleLoading();
            const url = isAccept === true
            ?
            `treatment-medicines/${treatmentMedicineID}/approve_proposal/`
            :
            `treatment-medicines/${treatmentMedicineID}/reject_proposal/`
            ;
            API.post(url)
                .then( data => {
                    const message = t('general.changesSavedProperly');
                    showSuccessMessage(message);
                    toast.clearWaitingQueue();
                    refreshPatient();
                })
                .catch((error) => {handleReqError(error)})
            ;
            handleNotLoading();
        } else {
            showErrorMessage('general.chooseOneOption');
        }
    };

    const handleProposalsPOST = (e,isAccept) => {
        e.preventDefault();
        let _proposals = {...proposalsSelected};
        delete _proposals['all'];
        _proposals = removeNotSelected(_proposals);
        _proposals = Object.keys(_proposals);
        let idsArr = [];
        const _proposalsMedicines = [...proposalsPatientMedicines];
        _proposals.forEach(name => {
            const item = _proposalsMedicines.find(proposal => proposal.name === name);
            if(item) {
                idsArr.push(item.treatment_medicine);
            }
        });
        if(idsArr.length > 0) {
            idsArr.forEach(treatmentMedicineID => {
                if(isAccept !== null) {
                    handleAPIAcceptDeclineCall(treatmentMedicineID,isAccept);
                } else {
                    handleAPIApplyCall(treatmentMedicineID);
                }
            })
        }
    };

    const setAllCheckboxesAreSelected = obj => {
        delete obj['all'];
        const selectedValues = Object.values(obj);
        let result = true;
        for (let index = 0; index < selectedValues.length; index++) {
            const value = selectedValues[index];
            if(value === false) { return false }
        };
        return result;
    };

    const handleActionsAreEnabled = obj => {
        setActionsAreEnabled(canAcceptOrDecline(obj));
    };

    const handleCheckboxClick = (e, medicine_name) => {
        const { checked } = e.target;
        let selectedObj = {...proposalsSelected};

        if(medicine_name) {
            selectedObj[medicine_name] = checked;
            selectedObj['all'] = setAllCheckboxesAreSelected(selectedObj);
        } else {
            Object.keys(selectedObj).forEach(key => {
                selectedObj[key] = checked;
            });
            selectedObj['all'] = checked;
        }

        setProposalsSelected({...selectedObj});
        handleActionsAreEnabled({...selectedObj});
    };

    const canAcceptOrDecline = obj => {
        delete obj['all'];
        const selectedValues = Object.values(obj);
        for (let index = 0; index < selectedValues.length; index++) {
            const value = selectedValues[index];
            if(value === true) {
                return true
            }
        };
        return false;
    };

    const getSelectedItems = () => {
        let result = {
            'all': true
        };
        proposalsPatientMedicines && proposalsPatientMedicines.forEach((proposal) => {
            const data = {
                [proposal.name]: true
            };
            result = {...result, ...data};
        });
        setProposalsSelected({...result});
        setActionsAreEnabled(canAcceptOrDecline({...result}));
    };

    useEffect(() => {
        getSelectedItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ proposalsPatientMedicines ]);

    return (
    <>
        { proposalsPatientMedicines &&
        <div
            className="border-bottom mb-2"
            style={{
                gridColumn: '1/17',
                paddingTop: '80px'
            }}
        >
            <div
                className="ProposalsGrid__Header d-flex align-items-center mb-2 p-2"
            >
                <span
                    className="material-icons-outlined align-middle me-2"
                >change_circle</span>
                <span>{t('pages.proposals.proposalsTitle')}</span>
            </div>
            <div
                className="ProposalsGrid__SelectAll p-2 d-flex align-items-center"
            >
                <div
                    className="ProposalsActionsWrapper d-flex align-items-center"
                    style={{
                        width: '100%',
                        maxWidth: 'calc(100vw - 480px)'
                    }}
                >
                    <Flags authorizedFlags={[MODULES["PROPOSALS_VALIDATION"]]}>
                        { proposalsPatientMedicines && proposalsPatientMedicines.length > 0 &&
                        <FormCheckbox
                            labelText={t('general.selectAll')}
                            value={proposalsSelected ? proposalsSelected['all'] : false}
                            onChange={(e) => handleCheckboxClick(e,null)}
                        />
                        }
                    </Flags>
                    <Flags authorizedFlags={[MODULES["PROPOSALS_VALIDATION"]]}>
                        { proposalsPatientMedicines && proposalsPatientMedicines.length > 0 &&
                        <AcceptDecline
                            disabled={!actionsAreEnabled}
                            handleAcceptProposals={(e) => handleProposalsPOST(e,true)}
                            handleDeclineProposals={(e) => handleProposalsPOST(e,false)}
                            handleApplyProposals={(e) => handleProposalsPOST(e,null)}
                        />
                        }
                    </Flags>
                    <MessagesLogsCTA />
                </div>
            </div>
            { proposalsPatientMedicines && proposalsPatientMedicines.length > 0 ? proposalsPatientMedicines.map((medicine, index) => (
                <div
                    className="ProposalsGrid rounded ps-2"
                    style={{
                        backgroundColor: '#d0dfe6',
                    }}
                    key={nanoid()}
                >
                    <ProposalItem
                        medicine={medicine}
                        index={index}
                        key={nanoid()}
                        proposalsSelected={proposalsSelected}
                        handleCheckboxClick={handleCheckboxClick}
                    />
                </div>
            )) : <p className="ps-3">{t('pages.proposals.noElements')}</p>}
        </div>
        }
    </>
    );
})

export default withTranslation()(ProposalsGrid);