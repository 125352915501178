import { useState, useEffect } from "react";
import { useAppProvider } from "../../../context/AppContext";
import useAPI from "../../../hooks/useAPI";
import FileUpload from '../../../components/containers/FileUploadFn';
import FileList from './FileList';
import useToastCustom from "../../../hooks/useToastCustom";

const FilesTabContent = ({
    t,
    patientId
}) => {

    const { handleLoading, handleNotLoading } = useAppProvider();

    const [ patientFiles, setPatientFiles ] = useState(null);
    const { API, handleReqError } = useAPI();
    const { showSuccessMessage } = useToastCustom();

    const getFiles = () => {
        handleLoading();
        const url = `/patient-files/?patient=${patientId}`;
        API.get(url)
        .then(response => {
            handleNotLoading();
           setPatientFiles(response.data.results);
        })
        .catch(error => handleReqError(error))
    };

    const handleDeleteFile = id => {
        handleLoading();
        API.delete(`patient-files/${id}/`)
          .then((response) => {
            getFiles();
            handleNotLoading();
            showSuccessMessage(t('general.changesDeletedProperly'));
          })
          .catch((error) => {
            const messageError =
              error && error.request && error.request.status === 409
                ? t('general.errorFetch')
                : error;
            handleReqError(error, messageError);
          });
    };

    useEffect(() => {
        getFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ patientId ]);

    return (
        <div>
            <FileList
                files={patientFiles}
                handleDeleteFile={handleDeleteFile}
            />
            <FileUpload
                updateFn={getFiles}
                patientId={patientId}
            />
        </div>
    );
}

export default FilesTabContent;