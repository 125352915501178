// components
import MenuSideBar from '../../components/containers/MenuSideBar';
import MainWrapper from '../../components/containers/MainWrapper';
import IsLoading from '../../components/UI/IsLoading';

import { branchName } from '../../constants/CI_BRANCH';

const BasePage = ({children}) => {
    return (
        <>
            { branchName && <div style={{
                position: 'absolute',
                border: '2px solid darkviolet',
                zIndex: '9999',
                background: 'darkviolet',
                bottom: 0
            }}>{branchName}</div>}

            <IsLoading />
            <MenuSideBar isExpanded={false}/>
            <MainWrapper>
                {children}
            </MainWrapper>
        </>
    );
}
 
export default BasePage;

