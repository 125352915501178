import axios from 'axios';

// Utils

import { cleanCharacters } from '../utils/generalUtils';

// Constants 
import { serverURL } from '../constants/SERVERS';

// Context 
import { useAppProvider } from '../context/AppContext';
import useToastCustom from './useToastCustom';

function useAPI() {

    // Toast
    const { showErrorMessage } = useToastCustom();

    // Login functionality //
    const {
        _refresh,
        setRefresh,
        setLoggedIn,
        setIsLoading,
        handleNotLoading,
    } = useAppProvider();

    // Used to refresh token
    let isRefreshingToken = false;
    let failedRequestsQueue = [];

    const processQueue = (error, token = null) => {
        failedRequestsQueue.forEach(prom => {
          if (error) {
            prom.reject(error);
          } else {
            prom.resolve(token);
          }
        })

        failedRequestsQueue = [];
    };


    const API = axios.create({
        baseURL: `${serverURL}api/v1/`,
        headers: {
            Authorization: sessionStorage['ti-medi-token'] ? `JWT ${sessionStorage['ti-medi-token']}` : null,
        }
    });

    const handleLoggedIn = data => {
        const { access, refresh } = data;
        API.defaults.headers['Authorization'] = "JWT " + access;
        setRefresh(refresh);
        sessionStorage.setItem('ti-medi-token', access);
        setLoggedIn(true);
    };

    const handleReqErrorBasic = (error, messageError) => {
        const errorMessage = 
            error && error.request && error.request.responseText 
            ?
                cleanCharacters(error.request.responseText)
            :
                error.response && error.response.data
                ? 
                    error.response.data
                : 
                    messageError
                    ? 
                        messageError
                    : 
                        error.message 
                        ? 
                            error.message 
                        :   error.length > 0 
                            ?
                            error
                            :
                            ''
        ;
        showErrorMessage(errorMessage);
    };

    const handleReqError = (error, messageError) => {
        const errorMessage = 
            error && error.request && error.request.responseText 
            ?
                cleanCharacters(error.request.responseText)
            :
                error.response && error.response.data
                ? 
                    error.response.data
                : 
                    messageError
                    ? 
                        messageError
                    : 
                        error.message 
                        ? 
                            error.message 
                        :   error.length > 0 
                            ?
                            error
                            :
                            ''
        ;
        setIsLoading(false);
        console.log(error);
        showErrorMessage(errorMessage);
        handleNotLoading();
    };

    API.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            const originalRequest = error.config;

            if (error.response.status === 401 && !originalRequest._retry) {
                if (isRefreshingToken) {
                    return new Promise(function(resolve, reject) {
                        failedRequestsQueue.push({resolve, reject})
                    }).then(token => {
                        originalRequest.headers['Authorization'] = 'JWT ' + token;
                        return axios(originalRequest);
                    }).catch(_error => {
                        return Promise.reject(_error);
                    })
                }

                originalRequest._retry = true;
                isRefreshingToken = true;

                return new Promise(function (resolve, reject) {
                    axios.post(`${serverURL}api/v1/token/refresh/`, { refresh: _refresh })
                    .then(({data}) => {
                        handleLoggedIn(data);
                        originalRequest.headers['Authorization'] = 'JWT ' + data.access;
                        processQueue(null, data.access);
                        resolve(axios(originalRequest));
                    })
                    .catch((_error) => {
                        sessionStorage.removeItem('ti-medi-token');
                        setLoggedIn(false);
                        window.location.href = '/login/';
                        processQueue(_error, null);
                        reject(_error);
                    })
                    .finally(() => { isRefreshingToken = false })
                })
            }

            return Promise.reject(error);
        }
    );

    return { API, handleReqError, handleLoggedIn, handleReqErrorBasic }
}

export default useAPI;
