import { withTranslation } from "react-i18next";

// Constants
import POSOLOGY_TYPES from '../../../../../constants/POSOLOGY_TYPES';
import { useTreatmentsProvider } from "../../../../../context/TreatmentsContext";

const PosologyTypeBtnGroup = ({
    t,
    handlePosologyTypeSelected
}) => {

    const handleKeyPress = e => {
        if(e.code === 'Enter') {
            e.preventDefault();
        }
    };


    const { posologyTypeSelected } = useTreatmentsProvider();

    // Const
    const isWeeklyType = posologyTypeSelected === POSOLOGY_TYPES[0] || posologyTypeSelected === POSOLOGY_TYPES[1] || posologyTypeSelected === POSOLOGY_TYPES[2];

    return (
        <div className="PosologyTypeBtnGroup">
            <label>{t('pages.planning.texts.medicinePosologies')}:</label>
            <br />
            <div className="btn-group btn-group-lg" role="group" aria-label="Basic radio toggle button group">
                <input type="radio" className="btn-check" name="btnradio" id="btnradioweekly" autoComplete="off" checked={isWeeklyType}
                    onChange={() => handlePosologyTypeSelected(POSOLOGY_TYPES[0])} onKeyPress={e => handleKeyPress(e)}
                />
                <label className="btn btn-outline-primary" htmlFor="btnradioweekly">{t('date.weekly')}</label>

                <input type="radio" className="btn-check" name="btnradio" id="btnradiointerval" autoComplete="off" checked={posologyTypeSelected === POSOLOGY_TYPES[3]}
                    onChange={() => handlePosologyTypeSelected(POSOLOGY_TYPES[3])} onKeyPress={e => handleKeyPress(e)}
                />
                <label className="btn btn-outline-primary" htmlFor="btnradiointerval">{t('general.interval')}</label>

                <input type="radio" className="btn-check" name="btnradio" id="btnradiomonthly" autoComplete="off" checked={posologyTypeSelected === POSOLOGY_TYPES[4]}
                    onChange={() => handlePosologyTypeSelected(POSOLOGY_TYPES[4])} onKeyPress={e => handleKeyPress(e)}
                />
                <label className="btn btn-outline-primary" htmlFor="btnradiomonthly">{t('general.monthly')}</label>
            </div>
        </div>
        );
}

export default withTranslation()(PosologyTypeBtnGroup);

